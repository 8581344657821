import React from 'react';
import {FieldError} from 'react-hook-form';
import Typography, {TypographyProps} from '@mui/material/Typography';

export interface FieldErrorProps extends TypographyProps {
  hidden?: boolean;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  error?: FieldError;
}

export default React.memo<FieldErrorProps>(
  ({hidden: hiddenProp = false, error, align = 'right', ...otherProps}) => {
    if (hiddenProp === true || !error?.message) {
      return null;
    }

    return (
      <Typography
        align={align}
        component="p"
        variant="caption"
        lineHeight={1}
        sx={{color: 'error.main', pb: '12px'}}>
        {error.message}
      </Typography>
    );
  },
);
