import React from 'react';

import {styled} from '@mui/material/styles';

import type {SxProps} from '@mui/system';
type TypeColor = 'wood' | 'fire' | 'earth' | 'iron' | 'water';

interface FiveElementTextStyleProps {
  size?: 'small' | 'medium' | 'large';
  //  $elem-map: (
  //    (0, wood),
  //    (1, fire),
  //    (2, earth),
  //    (3, iron),
  //    (4, water)
  //  );
  color?: TypeColor | number;
}

const FiveElementTextRoot = styled('p')(({theme, color}) => ({
  margin: 0,
  padding: 0,

  ...theme.typography.caption,
  lineHeight: 1,

  ...(color === 'wood' && {
    color: theme.palette.mode === 'light' ? '#62B4C2' : '#D9F7FC',
  }),
  ...(color === 'fire' && {
    color: theme.palette.mode === 'light' ? '#E68C95' : '#FFE6E8',
  }),
  ...(color === 'earth' && {
    color: theme.palette.mode === 'light' ? '#E0B751' : '#FFF3B5',
  }),
  ...(color === 'iron' && {
    color: theme.palette.mode === 'light' ? '#B8B2AC' : '#F7F7F7',
  }),
  ...(color === 'water' && {
    color: theme.palette.mode === 'light' ? '#8C8A9B' : '#E6E6EB',
  }),
}));

export interface FiveElementTextProps
  extends Omit<React.ComponentPropsWithRef<'p'>, 'color'>,
    FiveElementTextStyleProps {
  //
  label?: string;
  sx?: SxProps;
}

const mapColorAnimal: Array<TypeColor> = ['wood', 'fire', 'earth', 'iron', 'water'];

export default React.forwardRef<HTMLParagraphElement, FiveElementTextProps>(
  ({label, size = 'medium', color: colorProp, className, children, ...otherProps}, ref) => {
    let color;

    if (typeof colorProp === 'number' && mapColorAnimal[colorProp]) {
      color = mapColorAnimal[colorProp];
    } else if (typeof colorProp === 'string') {
      color = colorProp;
    }

    return (
      <FiveElementTextRoot ref={ref} color={color} {...otherProps}>
        {label ?? children}
      </FiveElementTextRoot>
    );
  },
);
