import React, {createContext, useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import {CenterModalBox, ModalDivider, ModalIcon} from 'components/Test/styles/ModalStyles';
import {
  category,
  centerModalLabel,
  installAction,
  appTouchAction,
  closeModalAction,
  dimTouchAction,
  APP_LINK,
} from 'components/Test/constants';
import Analytics from '~/plugins/tracker/analytics';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {DesktopQrModal} from 'components/Test/QR/desktopQrModal';
import {BrowserView, MobileView} from 'react-device-detect';

export let ModalOpenal = createContext<any>(false);

export const CenterModal: React.FC = () => {
  const [openModal, setOpenModal] = useState(true);
  const [qrOpenModal, setQrOpenModal] = useState(false);
  const pushModalOpenFalse = () => {
    setOpenModal(false);
    sessionStorage.setItem('open_modal', 'false');
  };

  const onClickOpenQr = () => {
    setOpenModal(false);
    setQrOpenModal(true);
  };

  useEffect(() => {
    Analytics.track(installAction, {category: category, label: centerModalLabel}).then(() => {
      console.log(`${centerModalLabel} ${installAction}`);
    });
  }, []);

  return (
    <>
      <Modal
        data-event-action={dimTouchAction}
        data-event-category={category}
        data-event-label={centerModalLabel}
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        open={openModal}
        onBackdropClick={() => setOpenModal(false)}>
        <CenterModalBox>
          <ModalDivider>
            <ModalIcon
              src={'https://static-en.forceteller.com/www/images/ico_app_launcher_squricle.png'}
            />
            <Typography sx={{lineHeight: '26px'}} variant={'h3'} mb={1}>
              포스텔러는
              <br /> 앱에서 더욱 편리해요!
            </Typography>
            <Typography sx={{color: '#A3A3A3', lineHeight: '20px'}} variant={'subtitle1'}>
              더 많은 운세와 새로운 소식이
              <br /> 당신을 기다리고 있어요.
            </Typography>
          </ModalDivider>
          <ModalDivider>
            <MobileView>
              <Button
                href={APP_LINK}
                data-event-action={appTouchAction}
                data-event-category={category}
                data-event-label={centerModalLabel}
                sx={{width: '100%', marginBottom: 0.5}}>
                앱으로 보기
              </Button>
            </MobileView>
            <BrowserView>
              <Button
                onClick={onClickOpenQr}
                data-event-action={appTouchAction}
                data-event-category={category}
                data-event-label={centerModalLabel}
                sx={{width: '100%', marginBottom: 0.5}}>
                앱으로 보기
              </Button>
            </BrowserView>

            <Button
              onClick={pushModalOpenFalse}
              data-event-action={closeModalAction}
              data-event-category={category}
              data-event-label={centerModalLabel}
              sx={{
                width: '100%',
                height: '24px',
                fontWeight: '500',
                fontSize: '10px',
                color: '#A3A3A3',
              }}
              variant={'text'}>
              웹으로 볼래요.
            </Button>
          </ModalDivider>
        </CenterModalBox>
      </Modal>
      <ModalOpenal.Provider value={[qrOpenModal, setQrOpenModal]}>
        {qrOpenModal ? <DesktopQrModal /> : null}
      </ModalOpenal.Provider>
    </>
  );
};
