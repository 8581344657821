import React from 'react';
import {QueryErrorResetBoundary} from 'react-query';
import {ErrorBoundary as ReactErrorBoundary} from 'react-error-boundary';

import ErrorBoundaryFallback from './ErrorBoundaryFallback';

const QueryErrorBoundary: React.FC = (props) => {
  return (
    <QueryErrorResetBoundary>
      {({reset}) => (
        <ReactErrorBoundary onReset={reset} FallbackComponent={ErrorBoundaryFallback} {...props} />
      )}
    </QueryErrorResetBoundary>
  );
};

export default QueryErrorBoundary;
