import React from 'react';
import {useNavigate} from 'react-router-dom';

import useAuthenticated from './hooks/useAuthenticated';
import useWaitForAuthentication from './hooks/useWaitForAuthentication';

interface AuthGuardProps {}

const AuthGuard: React.FC<AuthGuardProps> = ({children}) => {
  const navigate = useNavigate();
  const authenticated = useAuthenticated();
  const waitForAuthentication = useWaitForAuthentication();

  React.useEffect(() => {
    if (!waitForAuthentication && !authenticated) {
      const redirectUri = encodeURIComponent(
        `${window.location.pathname}${window.location.search}`,
      );

      navigate(`/signin?redirect_uri=${redirectUri}`, {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitForAuthentication, authenticated]);

  if (authenticated && React.isValidElement(children)) {
    return children;
  }

  return null;
};

export default AuthGuard;
