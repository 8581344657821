import React from 'react';
import {useRecoilState} from 'recoil';
import Drawer, {DrawerProps} from '@mui/material/Drawer';

import {DrawerMenuOpenState} from 'hooks/states/appearance';

interface SidenavProps extends Omit<DrawerProps, 'anchor'> {
  anchor?: 'left' | 'right';
}

export default React.forwardRef<HTMLDivElement, SidenavProps>(
  ({anchor = 'right', className, ...otherProps}, ref) => {
    const [open, setDrawerMenuOpenState] = useRecoilState(DrawerMenuOpenState);

    return (
      <Drawer
        ref={ref}
        open={open}
        anchor={anchor}
        onClose={() => setDrawerMenuOpenState(false)}
        {...otherProps}
      />
    );
  },
);
