function getUniqueId() {
  return Math.random().toString(16).substring(7);
}

export async function open(url: string) {
  return await call('link', {url: url});
}

export async function close() {
  return await call('close');
}

export async function share(text: string) {
  return await call('share', {t: text});
}

export async function token(force = true, redirect_uri?: string) {
  let params: {[key: string]: string} = force ? {f: '1'} : {};
  if (redirect_uri) {
    params = {...params, ...{redirect_uri}};
  }
  return await call('token', params);
}

export async function signin(force = true, redirect_uri?: string) {
  let params: {[key: string]: string} = force ? {f: '1'} : {};
  if (redirect_uri) {
    params = {...params, ...{redirect_uri}};
  }
  return await call('signin', params);
}

export function isIab() {
  let ua = window.navigator.userAgent;
  return ua.includes('ForcetellerAndroid') || ua.includes('ForcetellerIOS');
}

export function call(path: string, params?: {[key: string]: string}): Promise<any> {
  const id = getUniqueId();
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (!isIab()) {
        reject();
        if (params && params.url) {
          window.location.href = params.url;
        } else if (path === 'token' || path === 'signin') {
          window.location.href = `/signin?redirect_uri=${
            params?.redirect_uri ? params?.redirect_uri : window.location.pathname
          }`;
        } else if (path === 'close') {
          window.location.href = '/';
        }
      }
    }, 500);

    try {
      const url = new URL(`forcetellerinternal:///${path}`);
      const webkitHandler = messageHandler(path);

      if (params && !webkitHandler) {
        Object.keys(params).forEach((k) => {
          // @ts-ignore
          url.searchParams.append(k, params[k]);
        });
      }

      let callbackId = `cb_path_${new Date().getTime()}`;
      (window as any)[callbackId] = (data: any) => {
        resolve(data);
        (window as any)[callbackId] = null;
      };

      if (webkitHandler || (window as any).forcetellerAndroid) {
        if (!params) params = {};
        params.cb = callbackId;
      } else {
        url.searchParams.append('cb', callbackId);
      }

      if (webkitHandler) {
        webkitHandler.postMessage(params || {});
      } else if ((window as any).forcetellerAndroid) {
        (window as any).forcetellerAndroid.jsCall(path, JSON.stringify(params || {}));
      } else {
        const iframe = document.createElement('iframe');
        iframe.id = id;
        iframe.style.width = '0px';
        iframe.style.height = '0px';
        iframe.style.border = '0px';
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.src = url.href;
      }
    } catch (e) {
      console.log(e);
      return reject();
    }
  });
}

function messageHandler(path: string) {
  let webkit = (window as any).webkit;
  if (!webkit) return null;

  if (!webkit.messageHandlers || !webkit.messageHandlers[path]) return null;

  return webkit.messageHandlers[path];
}

export function openStore() {
  const agent = window.navigator.userAgent.toLowerCase();
  console.log(agent);
  if (agent.indexOf('android') > -1) {
    window.open('market://details?id=com.un7qi3.forceteller');
  } else if (agent.indexOf('iphone') > -1 || agent.indexOf('ipad') > -1) {
    window.open('itms-apps://itunes.apple.com/app/apple-store/id1262949138?mt=8');
  } else {
    window.open('https://play.google.com/store/apps/details?id=com.un7qi3.forceteller');
  }
}
