import React from 'react';
import {useLocation} from 'react-router-dom';

import Analytics from './analytics';
import getAnalyticsCategory from './getAnalyticsCategory';

const AnalyticsTracker: React.FC = () => {
  const {pathname} = useLocation();
  const historyRef = React.useRef<string[]>([]);

  React.useEffect(() => {
    historyRef.current.push(pathname);
    historyRef.current = historyRef.current.slice(-2);

    // PageView Tracking
    setTimeout(() => Analytics.page(), 1);
  }, [pathname]);

  React.useEffect(() => {
    // data-analytics-action
    // data-analytics-label
    // data-analytics-category
    document.addEventListener('click', (e) => {
      let element: any = e.target;

      while (element && element !== document) {
        const action = element.getAttribute('data-event-action');
        const actionType = element.getAttribute('data-event-action-type');

        if (action) {
          let properties: any = {
            label: window.location.pathname,
            category: getAnalyticsCategory(
              actionType === 'navigate' ? historyRef.current[0] : window.location.pathname,
            ),
          };

          if (element.getAttribute('data-event-category')) {
            properties.category = element.getAttribute('data-event-category');
          }

          if (element.getAttribute('data-event-label')) {
            properties.label = element.getAttribute('data-event-label');
          }

          console.log(action, properties);

          setTimeout(() => Analytics.track(action, properties), 1);

          break;
        }

        element = element.parentElement;
      }
    });
  }, []);

  return <span className="hidden" id="analytics-tracker" />;
};

export default AnalyticsTracker;
