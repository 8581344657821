import React from 'react';

import getter from 'lodash.get';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {experimentalStyled as styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinkArea from '@un7qi3-ui/react/LinkArea';
import AspectRatio from '@un7qi3-ui/react/AspectRatio';
import FontAwesomeIcon from '@un7qi3-ui/react/icons/FontAwesome/FontAwesome';

import {BannerPassProps} from './Banner';
import clsx from 'clsx';

interface BannerBaseProps extends Partial<BannerPassProps> {}

const BannerBasePlaceholder = () => (
  <AspectRatio aspectRatio={1.9}>
    <Skeleton variant="rectangular" width="100%" height="100%" />
  </AspectRatio>
);

const Text = styled(Typography)(({theme}) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: 12,
  lineHeight: 1,
  marginRight: 8,
  color: theme.palette.grey[500],
}));

const Icon = styled(FontAwesomeIcon)(({theme}) => ({
  color: theme.palette.grey[900],
  opacity: 0.4,
}));

const HomeBanner: React.FC<BannerBaseProps> = ({status, data, ...otherProps}) => {
  if (status === 'success') {
    if (!data) {
      return null;
    }
    const promotionId = getter(data.t, 'analytics.properties.promotion_id');

    return (
      <LinkArea
        data-event-action="아이템 터치"
        data-event-label={promotionId}
        href={data.t}
        {...otherProps}>
        <Box sx={{mb: 15}} className={clsx(data.isAdmin && 'badge-admin')}>
          <AspectRatio image={data.i} aspectRatio={1.9} />
          <Stack sx={{padding: '12px 16px'}}>
            <Typography variant="body1" fontWeight="medium">
              {data.t.title}
            </Typography>
            <Stack direction="row" alignItems="center" sx={{mt: '5px'}}>
              <Text>{data.t.caption}</Text>
              <Icon className="fal fa-angle-right" />
            </Stack>
          </Stack>
        </Box>
      </LinkArea>
    );
  } else {
    return <BannerBasePlaceholder />;
  }
};

export default HomeBanner;
