const __DEV__ = process.env.NODE_ENV === 'development';

export default __DEV__
  ? {
      apiKey: 'AIzaSyDRd9eh5IoEs3BbaA8CCdjy-Zrw0MUgdp8',
      authDomain: 'forceteller-dev.firebaseapp.com',
      projectId: 'forceteller-dev',
      storageBucket: 'forceteller-dev.appspot.com',
      messagingSenderId: '647270051241',
      appId: '1:647270051241:web:b52f2d948610a9874c68b8',
      measurementId: 'G-5KSJB94CSP',
    }
  : {
      apiKey: 'AIzaSyChJgFKk1-HQg7HOHyEeq2dTIhFjbO8fK0',
      authDomain: `${process.env.REACT_APP_SERVICE_HOST || 'forceteller.com'}`,
      databaseURL: 'https://forceteller-151103.firebaseio.com',
      projectId: 'forceteller-151103',
      storageBucket: 'forceteller-151103.appspot.com',
      messagingSenderId: '368878522246',
      appId: '1:368878522246:web:b067f276ef1247907fe607',
      measurementId: 'G-VJLDYFCSHD',
    };
