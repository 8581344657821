import React from 'react';

import Skeleton from '@mui/material/Skeleton';

import LinkArea from '@un7qi3-ui/react/LinkArea';
import AspectRatio from '@un7qi3-ui/react/AspectRatio';

import type {BannerPassProps} from './Banner';
interface BannerBaseProps extends Partial<BannerPassProps> {}

const BannerBase: React.FC<BannerBaseProps> = ({status, data, ...otherProps}) => {
  const width = data?.w ?? 320;
  const height = data?.h ?? 267;

  return (
    <AspectRatio
      maxWidth={width}
      aspectRatio={width / height}
      objectFit="contain"
      sx={{backgroundColor: 'transparent'}}>
      {status === 'success' && data ? (
        <LinkArea href={data.t} {...otherProps}>
          <img src={data.i} alt={data.text} />
        </LinkArea>
      ) : (
        <Skeleton variant="rectangular" />
      )}
    </AspectRatio>
  );
};

export default BannerBase;
