import {useMatch} from 'react-router-dom';
import {FixBarExperiment} from 'components/Test/experiments/FixBarExperiment';
import AppBarPremium from 'components/Test/fixBar/AppBarPremium';
import AppBar from 'containers/AppBar';

export const MatchComponents = () => {
  const itemResultMatch = useMatch('r/:resultKey');
  const itemMatch = useMatch('item/:uid');
  const storyMatch = useMatch('story/:uid');
  const premiumMatch = useMatch('premium/:uid');

  return (
    <>
      {itemResultMatch || itemMatch || storyMatch ? (
        <FixBarExperiment />
      ) : premiumMatch ? (
        <AppBarPremium />
      ) : (
        <AppBar />
      )}
    </>
  );
};
