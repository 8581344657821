import axios from 'axios';

import parseToken from '../utils/parseToken';

import type * as Apis from '@un7qi3/types/apis';
export interface Credential {
  provider?: string;
  access_token: string;
}

export default class AuthTokenService {
  public static getAsync(provider: string, credential: Credential) {
    return axios.post(`/api/auth/${provider}`, credential).then(
      (response) => response.data,
      (error) => {
        if (error.data && error.data.code) {
          return error.data;
        }

        throw error;
      },
    );
  }

  public static refreshAsync(refreshToken: string) {
    const httpConfig = {
      headers: {
        'X-REFRESH-TOKEN': refreshToken,
      },
    };

    return axios
      .post<Apis.AuthProps>('/api/authenticate/refresh_token', undefined, httpConfig)
      .then((response) => response.data);
  }

  public static isValid(token: string) {
    const timestamp = Math.floor(new Date().getTime() / 1000);
    const {exp: expiresAt} = parseToken(token);

    return expiresAt > timestamp;
  }
}
