import React from 'react';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Stack, {StackProps} from '@mui/material/Stack';

import LinkArea from '../LinkArea';

export default React.memo<StackProps>(() => {
  return (
    <LinkArea href="/signin">
      <Stack direction="row" alignItems="center" sx={{padding: '24px 20px 12px'}}>
        <Avatar
          src="https://static.forceteller.com/www/images/ic_portrait_empty.svg"
          sx={{width: 44, height: 44}}
        />
        <Box sx={{pl: '14px'}}>
          <Typography variant="body2" sx={{color: 'grey.500'}}>
            로그인
          </Typography>
        </Box>
      </Stack>
    </LinkArea>
  );
});
