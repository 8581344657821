import {useRecoilCallback} from 'recoil';
import {SnackbarState} from './states/snackbar';
import {SnackbarProps} from '@mui/material';

export default function useSnackbar() {
  const show = useRecoilCallback(
    ({set}) =>
      (message: string, options: SnackbarProps = {}) => {
        set(SnackbarState, {message, options});
      },
    [],
  );
  const close = useRecoilCallback(
    ({set}) =>
      () => {
        set(SnackbarState, {message: undefined});
      },
    [],
  );

  return {
    show,
    close,
  };
}
