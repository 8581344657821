import React from 'react';
import {useSetRecoilState} from 'recoil';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ButtonBase, {ButtonBaseProps} from '@mui/material/ButtonBase';
import {styled} from '@mui/material/styles';

import PriceTag from '@un7qi3-ui/react/PriceTag/PriceTag';
import toNumberWithComma from '@un7qi3/utils/toNumberWithComma';

import useAuthenticated from 'features/auth/hooks/useAuthenticated';
import useWaitForAuthentication from 'features/auth/hooks/useWaitForAuthentication';
import useSetGuestPurchasable from 'features/purchase/hooks/useSetGuestPurchasable';

import {PremiumBuyLinkState} from 'hooks/states';

import * as Apis from '@un7qi3/types/apis';
export interface PremiumPurchaseActionAreaProps extends ButtonBaseProps {
  data: Apis.PremiumProps;
}

const PremiumPurchaseActionAreaRoot = styled(ButtonBase)(({theme}) => ({
  width: '100%',
  height: 56,
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
}));

const PremiumPurchaseActionArea: React.FC<PremiumPurchaseActionAreaProps> = ({
  data,
  onClick,
  ...otherProps
}) => {
  const authenticated = useAuthenticated();
  const waitForAuthentication = useWaitForAuthentication();
  const setGuestPurchasable = useSetGuestPurchasable();
  const setLink = useSetRecoilState(PremiumBuyLinkState);

  const handleClick = React.useCallback(() => {
    if (authenticated) {
      setLink(`/premium/${data.id}/buy`);
    } else {
      setGuestPurchasable({
        ...data.purchasable,
        itemId: data.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  if (waitForAuthentication) {
    return null;
  }

  return (
    <PremiumPurchaseActionAreaRoot onClick={handleClick} {...otherProps}>
      <Stack direction="row" alignItems="center">
        <Typography variant="button" sx={{fontSize: 17, marginRight: '12px'}}>
          구매하기
        </Typography>
        {authenticated ? (
          <PriceTag
            type="price"
            amount={data.price}
            salePrice={data.promo?.salePrice}
            light
            size="md"
            sx={{fontWeight: 'bold'}}
          />
        ) : (
          <Typography variant="subtitle1" fontWeight="bold" sx={{color: '#FFD849'}}>
            {toNumberWithComma(data.purchasable.price)}원
          </Typography>
        )}
      </Stack>
    </PremiumPurchaseActionAreaRoot>
  );
};

export default PremiumPurchaseActionArea;
