import React from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import {experimentalStyled as styled} from '@mui/material/styles';
import Stack from '@mui/material/Stack';

const Container = styled('div')`
  flex: 1;
`;

export default React.memo(() => {
  return (
    <Container>
      <Skeleton variant="rectangular" height={270} />
      <Box sx={{px: '20px'}}>
        <Stack
          sx={{height: 48, borderBottom: '1px solid #ededed'}}
          alignItems="center"
          direction="row">
          <Skeleton variant="text" width="30%" height={28} />
        </Stack>
        <Box sx={{py: '20px'}}>
          <Skeleton variant="text" width="60%" height={35} />
          <Skeleton variant="text" width="100%" height={28} />
          <Skeleton variant="text" width="100%" height={28} />
          <Skeleton variant="text" width="50%" height={28} />
        </Box>
      </Box>
    </Container>
  );
});
