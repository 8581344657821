export const category = '앱 설치 유도';
export const centerModalLabel = '중앙 모달';
export const bottom1ModalLabel = '하단 모달 1';
export const bottom2ModalLabel = '하단 모달 2';
export const installAction = '설치모달_뜸';
export const fixBarAction = '고정바_뜸';
export const closeModalAction = '모달_닫기';
export const dimTouchAction = 'dim_터치';
export const appTouchAction = '앱으로_보기_터치';
export const forcetellerAppAction = '[포스텔러 앱] 터치';
export const appViewAction = '[앱으로 보기] 터치';
export const sideMenuLabel = '사이드 바 메뉴';
export const fixBarTopLabel = '무료 콘텐츠 상단';
export const fixBarBottomLabel = '무료 콘텐츠 하단';
export const fixBarPremiumLogoutLabel = '유료 콘텐츠 비로그인';
export const fixBarPremiumLoginLabel = '유료 콘텐츠 로그인';
export const APP_LINK = ' https://forcetellerkr.onelink.me/Jfkr/wiyumheq';
