import React from 'react';
import Portal from '@mui/material/Portal';
import * as MuiSnackbar from '@mui/material/Snackbar';
import {useRecoilState} from 'recoil';
import {SnackbarState} from 'hooks/states/snackbar';
import {Transition} from 'components/Util/Transition';

const Snackbar: React.VFC = () => {
  const [snackbar, setSnackbar] = useRecoilState(SnackbarState);

  return (
    <Portal>
      <MuiSnackbar.default
        open={!!snackbar.message}
        onClose={() => setSnackbar({message: undefined})}
        message={snackbar.message || ''}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        TransitionComponent={Transition}
        autoHideDuration={1500}
        {...snackbar.options}
      />
    </Portal>
  );
};

export default Snackbar;
