import React from 'react';

import Stack from '@mui/material/Stack';
import {experimentalStyled as styled} from '@mui/material/styles';
import PriceTag from '@un7qi3-ui/react/PriceTag/PriceTag';
import toNumberWithComma from '@un7qi3/utils/toNumberWithComma';
import FontAwesomeIcon from '@un7qi3-ui/react/icons/FontAwesome';
import {IfMatch, IfMatchCase, IfMatchElse} from '@un7qi3-ui/react/IfMatch';
import Spacer from 'components/Spacer';

const Icon = styled(FontAwesomeIcon)(({theme}) => ({
  color: theme.palette.grey[400],
  margin: '2px 4px 0 0',
}));

const Text = styled('span')(({theme}) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: 12,
  lineHeight: 1,

  color: theme.palette.grey[500],
}));

export interface InfoBarProps {
  type: string;
  like?: number;
  hits?: number;
  price?: number;
  promo?: {
    tag: string;
    salePrice: number;
  };
}

const InfoBar = React.memo<InfoBarProps>(({type, ...otherProps}) => {
  const {like, hits, price, promo} = otherProps;

  return (
    <Stack direction="row" alignItems="center" sx={{marginTop: '5px'}}>
      <IfMatch condition={type} passProps={otherProps}>
        <IfMatchCase then={'premium'}>
          <Icon className="fal fa-heart" fontSize={12} />
          <Text>{like && toNumberWithComma(like)}</Text>
          <Spacer flexItem size={14} />
          <Stack direction="row">
            {!!price && (
              <PriceTag type="price" amount={price} salePrice={promo?.salePrice} tag={promo?.tag} />
            )}
          </Stack>
        </IfMatchCase>
        <IfMatchElse>
          <Icon className="fal fa-eye" fontSize={12} />
          <Text>{hits && toNumberWithComma(hits)}</Text>
          <Spacer flexItem size={14} />
          <Icon className="fal fa-heart" fontSize={12} />
          <Text>{like && toNumberWithComma(like)}</Text>
        </IfMatchElse>
      </IfMatch>
    </Stack>
  );
});

export default InfoBar;
