import {Theme, Components} from '@mui/material/styles';

export default function override(theme: Theme): Components {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: 'rgba(0, 0, 0, .125)',

          boxShadow: 'none',
          '&:not(:last-child)': {
            borderBottom: 0,
          },
          '&:before': {
            display: 'none',
          },
          '&$expanded': {
            margin: 'auto',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          // border: 'solid 1px #E5E5E5',

          marginBottom: '-1px',
          minHeight: 52,
          '&$expanded': {
            minHeight: 74,
          },

          '& strong': {
            fontSize: 21,
            fontWeight: 500,
            letterSpacing: -0.42,
          },
        },
        content: {
          '&$expanded': {
            margin: '12px 0',
          },
        },
      },
    },
  };
}
