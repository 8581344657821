import {atom, selector} from 'recoil';

export type PremiumBuyStateProps = string | undefined;
export const PremiumBuyLinkState = atom<PremiumBuyStateProps>({
  key: 'PremiumBuyLinkState',
  default: undefined,
});

export const PremiumBuyModalState = selector({
  key: 'PremiumBuyModalState',
  get: ({get}) => {
    const link = get(PremiumBuyLinkState);
    if (!link) {
      return undefined;
    }
    if (link.indexOf('buy') !== -1) {
      return {buy: true, value: link.replace(/[\D]/g, '')};
    } else {
      return {buy: false, value: link};
    }
  },
});
