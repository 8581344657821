import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import EventHub from 'features/core/EventHub';
import useDialog from 'hooks/useDialog';

import useAuthenticator from './hooks/useAuthenticator';
import useAuthStateChangedListener from './hooks/useAuthStateChangedListener';

import AuthService from './services/AuthService';
import AuthTokenService from './services/AuthTokenService';
import * as iframe from '../core/iframe';
import {isIab} from '../core/iframe';
import {useRecoilState} from 'recoil';
import {termsModalState} from './hooks/states';

/**
 *
 *
 */
export default function AuthStateChangedController() {
  const {
    satellizerToken,
    setAuthToken,
    resetAuthToken,
    persistAuthToken,
    setWaitForAuthentication,
    setCurrentUser,
    signOut,
  } = useAuthenticator();

  const dialog = useDialog();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [, setTermsModal] = useRecoilState(termsModalState);

  const changeStateTerms = () => {
    setTermsModal(true);
  };

  // 프로필이 입력되지 않은 경우
  const handleErrorNeedProfile = React.useCallback(() => {
    changeStateTerms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  const handleErrorDormant = React.useCallback((dormant) => {
    if (dormant) {
      dialog.show({
        onClose: signOut,
        onConfirm: () => {
          dialog.close();

          return AuthService.reactivateAsync()
            .then(() => {
              window.location.reload();
            })
            .catch((e) => {
              // 프로필이 없을 경우
              if (e.status === 404) {
                return handleErrorNeedProfile();
              } else {
                return signOut();
              }
            });
        },
        title: dormant.title,
        content: dormant.message,
        confirm: '활성화',
        cancel: '나중에',
      });
    }
    // eslint-disable-next-line
  }, []);

  // 성공 했을 떄
  const handleAuthSuccess = React.useCallback(
    (e) => {
      setTimeout(() => AuthService.deviceAsync());

      if (e.status !== 'NEED_INITIALIZE') {
        let redirectUri = searchParams.get('redirect_uri') ?? '/';

        if (redirectUri) {
          //
          if (/^https?/.test(redirectUri)) {
            const url = new URL(redirectUri);

            // 포스텔러인 경우만 허용
            if (url.hostname.endsWith('forceteller.com') || url.hostname === 'localhost') {
              url.searchParams.set('token', e.token ?? satellizerToken);

              window.location.replace(url.href);
              return;
            } else {
              redirectUri = '/';
            }
          }
        }

        navigate(redirectUri, {
          replace: true,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams, satellizerToken],
  );

  // 에러 처리
  const handleAuthError = React.useCallback(
    (e) => {
      if (e.code === 1) {
        // 프로필이 입력되지 않은 경우
        return handleErrorNeedProfile();
      } else if (e.code === 2) {
        // 휴면계정
        return handleErrorDormant(e.dormant);
      }
    },
    [navigate],
  );

  //
  useAuthStateChangedListener(async ({status, user, provider}) => {
    //
    EventHub.emit('auth/change', status, user);

    let deferredPromise;
    // 앱에서 열렸을때 앱 토큰 우선순위
    if (isIab()) {
      const token = await iframe.token(false);
      if (token) {
        deferredPromise = Promise.resolve({token});
      }
    } else {
      if (status === 'NEED_INITIALIZE') {
        deferredPromise = persistAuthToken();
      } else if (user && status === 'NEED_ISSUE_TOKEN') {
        //
        deferredPromise = user.getIdToken().then((idToken) => {
          if (!provider) {
            return Promise.reject('알수없는 공급자로 요청했습니다.');
          }

          return AuthTokenService.getAsync('firebase', {
            provider: provider,
            access_token: idToken,
          });
        });
      } else if (status === 'NO_STATUS') {
        resetAuthToken();
      }
    }

    if (deferredPromise) {
      try {
        const tokenResponse = await deferredPromise;
        const userResponse = await AuthService.getCurrentUserAsync(tokenResponse.token);

        // 세션은 남아있지만 프로필 입력을 안하고 이탈한 경우
        if ([401, 403].includes(userResponse.status) && status === 'NEED_INITIALIZE') {
          await AuthService.signOut();
        } else {
          setAuthToken(tokenResponse);
          setCurrentUser(userResponse.data);

          if (tokenResponse.code) {
            EventHub.emit('auth/error', tokenResponse);
          } else {
            EventHub.emit('auth/success', {
              status,
              token: tokenResponse.token,
              user: userResponse.data,
            });
          }
        }
      } catch (e) {
        console.warn(e);
      }
    }

    setWaitForAuthentication(false);
  });

  React.useEffect(() => {
    return EventHub.onAuthSuccess(handleAuthSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleAuthSuccess]);

  React.useEffect(() => {
    return EventHub.onAuthError(handleAuthError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleAuthError]);

  return null;
}
