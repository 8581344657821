import React from 'react';

import Stack from '@mui/material/Stack';
import {SvgIconProps} from '@mui/material/SvgIcon';
import {createSvgIcon} from '@mui/material/utils';
import {Theme} from '@mui/material/styles';

import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        fontSize: 'inherit',
      },
    },
  });

const ForceSvgIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1">
    <path
      d="M9 18c-5 0-9-4-9-9s4-9 9-9 9 4 9 9-4 9-9 9zM9 2C5.1 2 2 5.1 2 9s3.1 7 7 7 7-3.1 7-7-3.1-7-7-7z"
      fill="#d7990b"
    />
    <path d="M9 2C5.1 2 2 5.1 2 9s3.1 7 7 7 7-3.1 7-7-3.1-7-7-7z" fill="#ffd849" />
    <path id="F" d="M6 5h6v1.6H8v1.7h3.7v1.6H8V13H6V5z" fill="#d7990b" />
  </svg>,
  'ico-force',
);

interface ForceSvgIconProps
  extends Omit<SvgIconProps, 'viewBox' | 'classes'>,
    WithStyles<typeof styles> {}

export default withStyles(styles)(
  React.memo<ForceSvgIconProps>(({classes, ...otherProps}) => (
    <Stack justifyContent="center" alignItems="center" className={classes.root}>
      <ForceSvgIcon viewBox="0 0 18 18" {...otherProps} />
    </Stack>
  )),
);
