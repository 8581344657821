import Analytics from 'analytics';

import GoogleAnalytics4 from './plugins/ga4';

import Fbevents from './plugins/fbevents';
import twitterPixelPlugin from './plugins/twitterpixel';
import kakaoPixelPlugin from './plugins/kakaopixel';
import wcslogPlugin from './plugins/wcslog';

const GOOGLE_ANALYTICS_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
const FACEBOOK_PIXEL_TRACKING_ID = process.env.REACT_APP_FACEBOOK_PIXEL_TRACKING_ID;
const TWITTER_PIXEL_TRACKING_ID = process.env.REACT_APP_TWITTER_PIXEL_TRACKING_ID;
const KAKAO_PIXEL_TRACKING_ID = process.env.REACT_APP_KAKAO_PIXEL_TRACKING_ID;
const NAVER_WCS_TRACKING_ID = process.env.REACT_APP_NAVER_WCS_TRACKING_ID;

let plugins = [];

GOOGLE_ANALYTICS_TRACKING_ID &&
  plugins.push(GoogleAnalytics4({measurementIds: [GOOGLE_ANALYTICS_TRACKING_ID]}));

FACEBOOK_PIXEL_TRACKING_ID && plugins.push(Fbevents({trackingId: FACEBOOK_PIXEL_TRACKING_ID}));

TWITTER_PIXEL_TRACKING_ID &&
  plugins.push(twitterPixelPlugin({trackingId: TWITTER_PIXEL_TRACKING_ID}));

KAKAO_PIXEL_TRACKING_ID && plugins.push(kakaoPixelPlugin({trackingId: KAKAO_PIXEL_TRACKING_ID}));

NAVER_WCS_TRACKING_ID && plugins.push(wcslogPlugin({trackingId: NAVER_WCS_TRACKING_ID}));

export default Analytics({
  plugins,
});
