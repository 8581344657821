import React from 'react';

export interface IfMatchElseProps {
  element?: React.ReactNode;
  children?: React.ReactNode;
}

const IfMatchElse: React.FC<IfMatchElseProps> = () => null;

export default IfMatchElse;
