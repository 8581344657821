import React from 'react';
import ReactDOM from 'react-dom';

import './polyfills';

import 'assets/scss/bundle.scss';

import AppBoot from './app/boot';
import initializeSystem from './initializeSystem';
import reportWebVitals from './reportWebVitals';

initializeSystem().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <AppBoot />
    </React.StrictMode>,
    document.getElementById('root'),
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
