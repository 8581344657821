import {experimentalStyled as styled} from '@mui/material/styles';

interface SpacerStyleProps {
  size?: number;
  horizontal?: boolean;
  flexItem?: boolean;
}

export interface SpacerProps extends SpacerStyleProps {}

function shouldForwardProp(prop: PropertyKey) {
  return prop !== 'sx' && prop !== 'size' && prop !== 'flexItem';
}

const SpacerRoot = styled('hr', {shouldForwardProp})<SpacerStyleProps>(
  {
    margin: 0,
    flexShrink: 0,
    borderWidth: 0,
  },
  ({size, horizontal = false, flexItem = false}) => ({
    ...(Number.isInteger(size) && {
      [horizontal || flexItem ? 'width' : 'height']: size,
    }),
    ...(flexItem && {
      alignSelf: 'stretch',
      height: 'auto',
    }),
  }),
);

export default SpacerRoot;
