import React from 'react';
import {SvgIconProps} from '@mui/material/SvgIcon';
import {createSvgIcon} from '@mui/material/utils';
import {Theme} from '@mui/material/styles';

import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: 12,
    },
  });

const ForceDefaultGraySvgIcon = createSvgIcon(
  <path
    fill="#3E3E40"
    d="M9.1 0C4.1 0 0 3.9 0 8.9s3.9 9 8.9 9.1c5 0 9-3.9 9.1-8.9 0-5-3.9-9.1-8.9-9.1zm3.6 6.6H8.2v1.9h4.1v1.8H8.2v3.5H6v-9h6.7v1.8z"
  />,
  'ico-force-default-gray',
);

interface ForceDefaultGraySvgIconProps
  extends Omit<SvgIconProps, 'viewBox' | 'classes'>,
    WithStyles<typeof styles> {}

export default withStyles(styles)(
  React.memo<ForceDefaultGraySvgIconProps>(({classes, ...otherProps}) => (
    <ForceDefaultGraySvgIcon classes={classes} viewBox="0 0 18 18" {...otherProps} />
  )),
);
