import React from 'react';
import Typography, {TypographyProps} from '@mui/material/Typography';
import toNumberWithComma from '@un7qi3/utils/toNumberWithComma';
import Force from '@un7qi3-ui/react/icons/Force';
import ForceDefaultGray from '@un7qi3-ui/react/icons/ForceDefaultGray';
import ForceBonusGray from '@un7qi3-ui/react/icons/ForceBonusGray';
import ForceBonusRed from '@un7qi3-ui/react/icons/ForceBonusRed';
import Stack from '@mui/material/Stack';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  text: {marginLeft: 4, marginBottom: 1, fontWeight: 'inherit'},
  sm: {fontSize: 12},
  md: {fontSize: 14},
  lg: {fontSize: 16},
  xl: {fontSize: 20},
});

interface Props extends TypographyProps {
  disableZero?: boolean;
  currency?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  children?: React.ReactNode;
}

export default function NumericText({
  disableZero = false,
  currency,
  children,
  size = 'sm',
  ...otherProps
}: Props) {
  const classes = useStyles();
  if (disableZero && (children === 0 || !children)) {
    return null;
  }

  const number = toNumberWithComma(children);

  if (currency) {
    let res = symbol[currency] || symbol['KRW'];
    if (!React.isValidElement(res)) {
      res = (
        <Typography className={classes[size]} sx={{marginBottom: '1px'}}>
          {res}
        </Typography>
      );
    }
    return (
      <Stack direction="row" alignItems="center" {...otherProps}>
        {res}
        <Typography className={clsx(classes.text, classes[size])}>{number}</Typography>
      </Stack>
    );
  }

  return <Typography {...otherProps}>{number}</Typography>;
}

const symbol: {[key: string]: string | React.ReactElement} = {
  JPY: '¥',
  KRW: '₩',
  USD: '$',
  price: <Force />,
  force: <ForceDefaultGray />,
  bonusForce: <ForceBonusGray />,
  expires: <ForceBonusRed />,
};
