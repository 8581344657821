import React from 'react';
import {useMatch, useLocation} from 'react-router-dom';

export default React.memo(() => {
  const location = useLocation();
  const viewable = useMatch('/');

  React.useEffect(() => {
    // 페이지를 이동할 때 강제로 스크롤을 올림
    if (!viewable) {
      requestAnimationFrame(() => window.scrollTo(0, 0));
    }
  }, [location, viewable]);

  return <span id="location-observer" className="hidden" />;
});
