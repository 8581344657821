import React from 'react';
import Typography, {TypographyProps} from '@mui/material/Typography';
import {experimentalStyled as styled} from '@mui/material/styles';

export interface SubpageTextStyleProps {
  title: boolean;
  subtitle: boolean;
  gutterBottom: boolean;
}

export interface SubpageTextProps extends Partial<Pick<SubpageTextStyleProps, 'gutterBottom'>> {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  disableTypography?: boolean;
  titleTypographyProps?: TypographyProps;
  subtitleTypographyProps?: TypographyProps;
}

const SubpageTextRoot = styled('div')<{ownerState: SubpageTextStyleProps}>(({ownerState}) => ({
  padding: 0,
  whiteSpace: 'pre-line',

  ...(ownerState.title && {
    padding: '12px 20px 0',
  }),
  ...(ownerState.title &&
    ownerState.gutterBottom && {
      paddingBottom: 20,
    }),
}));

function isTypography(node: any) {
  return React.isValidElement(node) && node.type === Typography;
}

const SubpageText: React.FC<SubpageTextProps> = ({
  title: titleProp,
  subtitle: subtitleProp,
  gutterBottom = true,
  disableTypography = false,
  titleTypographyProps,
  subtitleTypographyProps,
  children,
}) => {
  let title = titleProp ?? children;
  let subtitle = subtitleProp;

  const ownerState = {
    title: !!title,
    subtitle: !!subtitle,
    gutterBottom,
  };

  if (title && !isTypography(title) && !disableTypography) {
    title = (
      <Typography variant="h1" display="block" sx={{margin: '6px 0 4px'}} {...titleTypographyProps}>
        {title}
      </Typography>
    );
  }

  if (subtitle && !isTypography(subtitle) && !disableTypography) {
    subtitle = (
      <Typography
        variant="h6"
        color="grey.500"
        display="block"
        gutterBottom
        {...subtitleTypographyProps}>
        {subtitle}
      </Typography>
    );
  }

  return (
    <SubpageTextRoot ownerState={ownerState}>
      {subtitle}
      {title}
    </SubpageTextRoot>
  );
};

export default SubpageText;
