import omit from 'lodash.omit';
import isNil from 'lodash.isnil';
import isObject from 'lodash.isobject';

import {QueryKey} from 'react-query';
import {compile, Key as TokenKey, parse, Token} from 'path-to-regexp';

type TypeObject = {[key: string]: any};

/**
 *
 * const queryKey: string | any[] = queryKeySerializerFn(['/items/:uid', queryVariables]);
 *
 * queryKeySerializerFn(['/items/:id', 1]); => ['/item/1', {}]
 * queryKeySerializerFn(['/items/:id', 1, {test: 1}]; => ['/item/1', {test: 1}])
 * queryKeySerializerFn(['/items/:uid', 2]); => ['/item/1', {}]
 * queryKeySerializerFn(['/items/:uid', 3, {uid: 7}]); => ['/item/7', {}]
 */
export default function queryKeySerializerFn(queryKey: QueryKey) {
  // 배열형태로 리턴되지 않을 경우
  if (!Array.isArray(queryKey)) {
    return [queryKey, {}];
  }

  // Replacements 값이나 파라미터가 들어오지 않은 경우
  if (queryKey.length === 1) {
    return [queryKey[0], {}];
  }

  let [uriPattern, ...args] = queryKey,
    pathname: string,
    params: TypeObject = args[args.length - 1],
    replacements: TypeObject = {};

  // 마지막 인수가 Object 가 아닌경우
  if (Array.isArray(params) || !isObject(params)) {
    params = {};
  }

  const compiler = compile(uriPattern, {
    encode: encodeURIComponent,
    validate: false, // 유효성 체크를 하지 않음
  });
  //
  const tokens: Token[] = parse(uriPattern).filter((token, index) => {
    if (isObject(token)) {
      const name = (token as TokenKey).name;

      // 파라미터에 있을 경우
      if (params[name]) {
        replacements[name] = params[name];

        if (args.length > 0) {
          args.shift();
        }

        return true;
      }

      // 요청한 데이터에 정규식 토큰값이 누락 된 경우 에러가 발생하는 이슈로 값이 강제로 채워준다.
      // 해당 값은 아래에서 제거.
      replacements[name] = isNil(args[0]) ? '' : args.shift();
    }

    return false;
  });

  //
  pathname = compiler(replacements) as string;

  if (isObject(params) && tokens.length > 0) {
    // 정규식 토큰으로 사용 된 값 제거.
    params = omit(params, [...tokens.map((v: any) => v.name)]);
  }

  return [pathname, params];
}
