import React from 'react';
import MaskedInput, {maskArray, MaskedInputProps} from 'react-text-mask';
import {Controller, useFormContext} from 'react-hook-form';

import Input, {InputProps} from '@mui/material/Input';

export interface MaskedTextProps extends Omit<InputProps, 'name' | 'onChange' | 'onBlur'> {
  name: string;
  mask: maskArray;
  guide?: boolean;
  showMask?: boolean;
}

// TODO
const MaskedText: React.FC<MaskedTextProps> = ({
  name,
  mask,
  showMask = false,
  guide = false,
  inputProps,
  disabled: disabledProp,
  ...otherProps
}) => {
  const {control} = useFormContext();

  const inputRenderer: MaskedInputProps['render'] = (setRef, forwardProps) => {
    return (
      <Input
        fullWidth
        inputRef={setRef}
        inputProps={{
          autoComplete: 'off',
          autoCapitalize: 'off',
          ...inputProps,
        }}
        {...forwardProps}
        {...otherProps}
      />
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({field: fieldProps, formState}) => {
        const hasError = !!formState.errors[name];
        const disabled = disabledProp || formState.isSubmitting;

        return (
          <MaskedInput
            mask={mask}
            guide={guide}
            showMask={showMask}
            render={(ref, props) =>
              inputRenderer(ref, {
                ...props,
                error: hasError,
              })
            }
            disabled={disabled || formState.isSubmitting}
            {...fieldProps}
          />
        );
      }}
    />
  );
};

export default MaskedText;
