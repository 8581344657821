import React from 'react';

import {experimentalStyled as styled} from '@mui/material/styles';

import type {SxProps} from '@mui/system';
interface AspectRatioStyleProps {
  image?: string;
  aspectRatio?: number;
  maxWidth?: number;
  objectFit?: React.CSSProperties['OObjectFit'];
}

export interface AspectRatioProps
  extends AspectRatioStyleProps,
    React.ComponentPropsWithRef<'div'> {
  sx?: SxProps;
}

const AspectRatioRoot = styled('div')<{ownerState: AspectRatioStyleProps}>(
  {
    position: 'relative',

    '&:before': {
      display: 'block',
      content: '""',
    },

    '& > :first-of-type': {
      position: 'absolute',
      display: 'block',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 'inherit',
    },

    '& img, & picture': {
      width: '100%',
      height: '100%',
      // ⚠️ object-fit is not supported by IE 11.
      objectFit: 'cover',
    },
  },
  ({theme, ownerState}) => {
    const baseWidth = ownerState.maxWidth ? `min(100%, ${ownerState.maxWidth}px)` : '100%';

    return {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundColor: theme.palette.background.default,

      ...(ownerState.image && {
        backgroundImage: `url("${ownerState.image}")`,
      }),
      ...(ownerState.aspectRatio && {
        height: 0,
        paddingBottom: `calc(${baseWidth} / (${ownerState.aspectRatio}))`,
      }),
      ...(ownerState.maxWidth && {
        width: `min(100%, ${ownerState.maxWidth}px)`,
        margin: '0 auto',
      }),
      ...(ownerState.objectFit && {
        backgroundSize: ownerState.objectFit,

        '& img, & picture': {
          objectFit: ownerState.objectFit,
        },
      }),
    };
  },
);

const AspectRatio = React.forwardRef<HTMLDivElement, AspectRatioProps>(
  ({image, aspectRatio = 0, objectFit, maxWidth, ...otherProps}, ref) => {
    const ownerState = {
      image,
      aspectRatio,
      maxWidth,
      objectFit,
    };

    return <AspectRatioRoot ref={ref} ownerState={ownerState} {...otherProps} />;
  },
);

export default AspectRatio;
