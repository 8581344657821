import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';

export const SideMenuBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
  margin-top: 30px;
  cursor: pointer;
`;

export const Icon = styled('img')`
  width: 30px;
  height: 30px;
  margin-right: 8px;
`;
