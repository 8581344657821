const grey = {
  50: '#F4F4F4',
  100: '#EDEDED',
  200: '#E8E8E8',
  300: '#D1D1D1',
  400: '#BABABA',
  500: '#A3A3A3',
  600: '#818181',
  700: '#5E5E5E',
  800: '#303030',
  900: '#191919',
};

const palette = {
  primary: {
    main: '#000000',
    contrastText: '#FFFFFF',
    dark: '#000000',
    light: '#000000',
  },
  secondary: {
    main: '#428BFF',
    contrastText: '#FFFFFF',
    dark: '#428BFF',
    light: '#428BFF',
  },
  error: {
    main: '#E85E5E',
    contrastText: '#FFFFF',
    dark: '#E85E5E',
    light: '#E38282',
  },
  warning: {
    main: '#D7990B',
    contrastText: '#FFFFF',
    dark: '#D7990B',
    light: '#FFD849',
  },
  // info: {},
  // success: {},
  background: {
    paper: '#FFFFFF',
    default: '#F4F4F4',
  },
  text: {
    primary: '#191919',
    secondary: '#818181',
    disabled: '#BABABA',
  },
  divider: grey[100],

  action: {
    active: grey[400],
    disabledBackground: '#D7D7D7',
  },
  grey,
};

export default palette;
