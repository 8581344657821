import React from 'react';
import {experimentalStyled as styled} from '@mui/material/styles';

import type {SxProps} from '@mui/system';
interface FieldsetStyleProps {
  row?: boolean;
  disableGutter?: boolean;
}

interface FieldsetProps extends React.ComponentProps<'fieldset'>, FieldsetStyleProps {
  sx?: SxProps;
}

const FieldsetRoot = styled('fieldset')<{ownerState: FieldsetStyleProps}>(
  {
    margin: 0,
    padding: '8px 0',
    border: 'none',
  },
  ({ownerState}) => ({
    ...(ownerState.row && {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }),
    ...(ownerState.disableGutter && {
      paddingLeft: 0,
      paddingRight: 0,
    }),
  }),
);

const Fieldset: React.FC<FieldsetProps> = ({row = true, disableGutter = false, ...otherProps}) => {
  const ownerState = {
    row,
    disableGutter,
  };

  return <FieldsetRoot ownerState={ownerState} {...otherProps} />;
};

export default Fieldset;
