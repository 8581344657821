import React from 'react';
import {useInfiniteQuery, useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Subpage from 'components/Subpage';
import {InfiniteScroll} from 'components/Util/InfiniteScroll';
import {PremiumListItem} from 'components/Premium/PremiumListItem';
import * as Apis from '@un7qi3/types/apis';
import {GroupTags} from '../Tag/GroupTags';
import {KeywordProps} from '@un7qi3/types/apis';

interface GroupStyleProps {
  topImage?: string;
  topBgColor?: string;
  topImageHeight: number;
  backgroundSize: number;
}

const useStyles = makeStyles<Theme, GroupStyleProps>({
  root: {},
  header: (props) => ({
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'flex-end',
    backgroundImage: `url(${props.topImage})`,
    backgroundColor: `${props.topBgColor}`,
    backgroundSize: `auto ${props.backgroundSize}px`,
    height: `${props.topImageHeight}px`,
  }),
  contentBox: {padding: '0 20px 8px'},
  title: {whiteSpace: 'pre-line'},
  subTitle: {marginBottom: 8, opacity: 0.4},
});

interface premiumGroupProp {
  suspense?: boolean;
}

export const PremiumGroup: React.FC<premiumGroupProp> = ({suspense = false}) => {
  const params = useParams();
  const [keyword, setKeyword] = React.useState<KeywordProps>();
  const {data: groupData} = useQuery<Apis.Response<Apis.PremiumGroupProps>>(
    ['/api/premium/theme/:id/v2', params.uid],
    {suspense},
  );

  const {data, fetchNextPage, hasNextPage, refetch} = useInfiniteQuery<
    Apis.Response<Apis.PremiumItemProps[]>
  >(
    'fetchItems',
    async ({pageParam = 0}) => {
      const res = await axios.get('/api/premium/fetch', {
        params: {o: pageParam, l: 10, groupId: params.uid, ...keyword?.link.params?.queryParams},
      });

      return res.data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const offset = allPages.flatMap((x) => x.data).length;
        if (lastPage.data.length === 0 || offset % 10 !== 0) {
          return undefined;
        }
        return offset;
      },
      suspense,
    },
  );

  React.useEffect(() => {
    refetch();
  }, [keyword]);

  const classes = useStyles({
    topImage: groupData?.data?.extra?.topImage,
    topBgColor: groupData?.data?.extra?.topBgColor,
    topImageHeight: groupData?.data?.extra?.topImageHeight || 270,
    backgroundSize: Number(groupData?.data?.extra?.topImageHeight || 270) + 44,
  });

  const tagSelected = (value?: KeywordProps) => {
    setKeyword(value);
  };

  if (groupData?.data && data) {
    return (
      <Subpage
        title={!groupData?.data.extra?.topImage && groupData?.data.title}
        subtitle={!groupData?.data.extra?.topImage && groupData?.data.extra?.subTitle}
        disableGutter>
        {groupData?.data.extra?.topImage && <Box className={classes.header} />}
        {groupData?.data.tags && <GroupTags tags={groupData.data.tags} tagSelected={tagSelected} />}
        <Box className={classes.contentBox}>
          {data?.pages.map((page, i) => (
            <React.Fragment key={i}>
              {page.data.map((item) => (
                <PremiumListItem
                  key={item.id}
                  item={item}
                  hideThumbnail={groupData?.data.extra?.listType === 'text'}
                />
              ))}
            </React.Fragment>
          ))}
        </Box>
        <InfiniteScroll onIntersect={fetchNextPage} enabled={hasNextPage} rootMargin="100px" />
      </Subpage>
    );
  }

  return null;
};
