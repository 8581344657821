import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import Input, {InputProps} from '@mui/material/Input';

interface TextProps extends Omit<InputProps, 'name' | 'onChange' | 'onBlur'> {
  name: string;
}

const Text: React.FC<TextProps> = ({name, inputProps, disabled: disabledProp, ...otherProps}) => {
  const {control, unregister} = useFormContext();

  React.useEffect(() => {
    return () => unregister(name);
  }, [name]);

  return (
    <Controller
      name={name}
      control={control}
      render={({field, formState}) => {
        const {ref: inputRef, ...fieldProps} = field;

        const hasError = !!formState.errors[name];
        const disabled = disabledProp || formState.isSubmitting;

        return (
          <Input
            fullWidth
            error={hasError}
            disabled={disabled}
            inputRef={inputRef}
            inputProps={{
              autoComplete: 'off',
              autoCapitalize: 'off',
              ...inputProps,
            }}
            {...fieldProps}
            {...otherProps}
          />
        );
      }}
    />
  );
};

export default Text;
