import React from 'react';
import {KeywordProps} from '@un7qi3/types/apis';
import {TagChip} from './TagChip';

interface StyleProps {
  tagChip: React.CSSProperties;
  chip: React.CSSProperties;
}

interface TagChipProps {
  keywords: KeywordProps[];
  selectedIndex: number;
  classes: Record<keyof StyleProps, string>;
  setSelectedIndex: (index: number) => void;
}

export const TagChips = React.memo<TagChipProps>(
  ({keywords, selectedIndex, setSelectedIndex, classes}) => {
    const clickTag = (index: number) => () => {
      if (selectedIndex !== index) {
        setSelectedIndex(index);
      }
    };

    return (
      <>
        {keywords.map((keyword, index) => (
          <div key={keyword.text} className={classes.chip}>
            <TagChip
              data-event-action="태그 필터 터치"
              data-event-label={keyword.text}
              word={keyword.text}
              selected={selectedIndex === index}
              onClick={clickTag(index)}
              classes={classes.tagChip}
            />
          </div>
        ))}
      </>
    );
  },
);
