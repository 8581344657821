import React from 'react';
import {experimentalStyled as styled} from '@mui/material/styles';

import Divider, {DividerProps} from '@mui/material/Divider';
import useAuthenticated from 'features/auth/hooks/useAuthenticated';

interface DrawerMenuItemDividerProps extends DividerProps {
  requireAuth?: boolean;
}

const DrawerMenuItemDividerRoot = styled(Divider)`
  margin: 12px 20px;
`;

const MenuItemDivider = React.memo<DrawerMenuItemDividerProps>(
  ({requireAuth = false, ...otherProps}) => {
    const authenticated = useAuthenticated();
    const hidden = requireAuth && !authenticated;

    const passProps = {
      ...(hidden && {
        hidden,
        'aria-hidden': hidden,
      }),
      ...otherProps,
    };

    return <DrawerMenuItemDividerRoot {...passProps} />;
  },
);

export default MenuItemDivider;
