import {Theme, Components} from '@mui/material/styles';

export default function override(theme: Theme): Components {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        square: true,
      },
    },
  };
}
