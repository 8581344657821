import {createTheme} from '@mui/material/styles';

import shape from './shape';
import spacing from './spacing';
import breakpoints from './breakpoints';
import typography from './typography';
import palette from './palette';
import mixins from './mixins';

import components from './components';

export const themeOptions = {
  shape,
  spacing,
  breakpoints,
  typography,
  palette,
  mixins,
};

const theme = createTheme(themeOptions);
theme.components = components(theme);

export default theme;
