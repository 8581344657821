import React from 'react';
import {useNavigate} from 'react-router-dom';

import {experimentalStyled as styled} from '@mui/material/styles';

import type {State} from 'history';
import type {SxProps} from '@mui/system';
import type * as Apis from '@un7qi3/types/apis';

interface LinkAreaStyleProps {
  display?: React.CSSProperties['display'];
  underline?: 'none' | 'hover' | 'always';
}

export interface LinkAreaProps
  extends Omit<React.ComponentPropsWithRef<'a'>, 'href'>,
    LinkAreaStyleProps {
  href?: string | Apis.LinkProp;
  replace?: boolean;
  state?: State;
  sx?: SxProps;
}

const LinkAreaRoot = styled('a')<{ownerState: LinkAreaStyleProps}>(
  {
    border: 0,
    outline: 0,
    margin: 0,
    padding: 0,
    borderRadius: 0,

    cursor: 'pointer',
    userSelect: 'none',
    textDecoration: 'none',
    backgroundColor: 'transparent',

    MozAppearance: 'none',
    WebkitTapHighlightColor: 'transparent',
    WebkitAppearance: 'none',
    '&::-moz-focus-inner': {
      borderStyle: 'none', // Remove Firefox dotted outline.
    },
  },
  ({ownerState}) => ({
    /* Styles applied to the root element if `underline="none"`. */
    ...(ownerState.underline === 'none' && {
      textDecoration: 'none',
    }),
    /* Styles applied to the root element if `underline="hover"`. */
    ...(ownerState.underline === 'hover' && {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    }),
    /* Styles applied to the root element if `underline="always"`. */
    ...(ownerState.underline === 'always' && {
      textDecoration: 'underline',
    }),
    ...(ownerState.display && {
      display: ownerState.display,
    }),
  }),
);

const LinkArea = React.forwardRef<HTMLAnchorElement, LinkAreaProps>(
  ({href, display, underline = 'none', onClick, replace, state, ...otherProps}, ref) => {
    const navigate = useNavigate();

    const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      let navigateTo = typeof href === 'string' ? href : href?.value;

      if (navigateTo) {
        // 외부링크는 새창으로
        if (/^(mailto|https?):/.test(navigateTo)) {
          window.open(navigateTo, '_blank');
        } else {
          navigate(navigateTo, {replace, state});
        }
      }

      // 사용자 정의 이벤트
      if (typeof onClick === 'function') {
        onClick(e);
      }
    };

    const ownerState = {
      display,
      underline,
    };

    return (
      <LinkAreaRoot ref={ref} onClick={handleOnClick} {...otherProps} ownerState={ownerState} />
    );
  },
);

export default LinkArea;
