const NAMESPACE = 'KakaoPixel';

export default function kakaoPixelPlugin(userConfig) {
  // return object for analytics to use
  return {
    name: NAMESPACE,
    /* Everything else below this is optional depending on your plugin requirements */
    config: {
      trackingId: userConfig.trackingId,
    },
    initialize({config}) {
      // eslint-disable-next-line
      !(function (f, b, e, v, t, s) {
        if (f.kkq) return;

        f.kkq = function () {
          if (f['kakaoPixel'] && typeof f['kakaoPixel'] === 'function') {
            const kkq = f['kakaoPixel'](config.trackingId);
            if (kkq && typeof kkq.track === 'function') {
              return kkq.track.apply(kkq, arguments);
            }
          }
        };

        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://t1.daumcdn.net/adfit/static/kp.js');
    },
    page() {
      window.kkq('PageView');
    },
    track({payload}) {
      const event = payload.event === 'purchase' ? 'Purchase' : payload.event;
      window.kkq(event, payload.properties);
    },
    identify() {
      return null;
    },
    loaded() {
      return !!window.kakaoPixel;
    },
  };
}
