import React from 'react';
import clsx from 'clsx';
import Box, {BoxProps} from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import NumericText from '../NumericText';
import Typography from '@mui/material/Typography';
import {SxProps} from '@mui/system';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  price: {
    color: '#D7990B',
    fontWeight: 700,
  },
  force: {
    color: '#191919',
    fontWeight: 500,
  },
  bonusForce: {
    color: '#8C8C8C',
    fontWeight: 500,
  },
  expires: {
    color: '#E85E5E',
  },
  light: {
    color: '#ffd849',
    '& + p': {
      color: '#ffd849',
    },
  },
  overrided: {
    '& p': {
      fontSize: '90%',
      fontWeight: 400,
      textDecoration: 'line-through',
      color: '#8C8C8C',
      marginRight: '4px',
    },
  },
  salePrice: {
    fontWeight: 'bold',
    color: '#dba01a',
    fontSize: 'inherit',
  },
  evTag: {
    backgroundColor: '#e85e5e',
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1.4,
    padding: '0 2px',
    color: '#fff',
    borderRadius: 2,
    marginLeft: 7,
  },
  sm: {fontSize: 12},
  md: {fontSize: 14},
  lg: {fontSize: 16},
  xl: {fontSize: 20},
});

export interface PriceTagProps extends BoxProps {
  type: 'force' | 'bonusForce' | 'expires' | 'price';
  amount: number | string;
  salePrice?: number | string;
  tag?: string;
  light?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

export default React.memo<PriceTagProps>(
  ({type, amount, salePrice, tag, light, size = 'sm', ...otherProps}) => {
    const classes = useStyles();

    return (
      <Box className={clsx(classes.root, classes[size], classes[type])} {...otherProps}>
        <NumericText
          currency={type}
          size={size}
          className={clsx({
            [classes.overrided]: salePrice && salePrice.toString().length > 0,
            [classes.light]: light,
          })}>
          {amount}
        </NumericText>
        {salePrice?.toString().length && (
          <NumericText size={size} className={classes.salePrice}>
            {salePrice}
          </NumericText>
        )}
        {tag && (
          <div className={classes.evTag}>
            <Typography sx={{fontSize: 11}}>{tag}</Typography>
          </div>
        )}
      </Box>
    );
  },
);
