import * as React from 'react';
import AspectRatio from '@un7qi3-ui/react/AspectRatio/AspectRatio';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import InfoBar from 'containers/InfoBar';

interface StyleProps {
  image?: string;
  box?: string;
}

export interface RecommendProps {
  id?: number;
  type: string;
  status?: string;
  title: string;
  like?: number;
  hits?: number;
  price?: number;
  promo?: {
    tag: string;
    salePrice: number;
  };
  full_image?: string;
  thumbnail_image?: string;
  label_text?: string;
  label_color?: string;
  link?: {
    type: string;
    value: string;
  };
  disableGutter?: boolean;
  aspectRatio?: number;
  classes?: Record<keyof StyleProps, string>;
}

export const Recommend = React.memo<RecommendProps>(
  ({
    title,
    full_image,
    thumbnail_image,
    type,
    price,
    promo,
    hits,
    like,
    link,
    disableGutter = false,
    aspectRatio = 360 / 189,
    classes,
  }) => {
    return (
      <Box sx={{mb: '15px'}} className={classes?.box}>
        <Link to={link?.value || '/'}>
          <AspectRatio
            image={full_image || thumbnail_image}
            aspectRatio={aspectRatio}
            className={classes?.image}
          />
          <Stack sx={disableGutter ? {padding: '12px 0'} : {padding: '12px 16px'}}>
            <Typography variant="body1" fontWeight="medium">
              {title}
            </Typography>
            <InfoBar type={type} like={like} hits={hits} price={price} promo={promo} />
          </Stack>
        </Link>
      </Box>
    );
  },
);
