import React from 'react';
import Dialog from '@mui/material/Dialog';
import {DialogStateProps} from 'hooks/states';

const MaintenanceDialog = React.memo<DialogStateProps>((dialogOption) => {
  return (
    <Dialog
      onClose={(_, reason) => {
        if (reason && reason !== 'backdropClick') {
          dialogOption.onClose();
        }
      }}
      open={dialogOption.open}
      maxWidth="xs"
      onBackdropClick={() => {}}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '12px',
          width: 'clamp(calc(100% - 40px), 320px, 90vw)',
          minWidth: '280px',
          maxWidth: '320px',
          background: 'transparent',
        },
        '.MuiBackdrop-root': {
          background: 'rgba(255, 255, 255, 1)',
        },
      }}>
      <img style={{objectFit: 'cover'}} src={dialogOption.content} />
    </Dialog>
  );
});

export default MaintenanceDialog;
