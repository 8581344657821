import {Theme, Components} from '@mui/material/styles';

export default function override(theme: Theme): Components {
  return {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: {},
        sizeSmall: {
          lineHeight: 16 / 14,
          fontSize: theme.typography.pxToRem(14),
          fontWeight: theme.typography.fontWeightMedium,
          padding: theme.spacing(1, 2),
          borderRadius: 6,
        },
        sizeMedium: {
          lineHeight: 24 / 16,
          fontSize: theme.typography.pxToRem(16),
          padding: theme.spacing(2, 3),
          borderRadius: theme.shape.borderRadius,
        },
        sizeLarge: {
          lineHeight: 28 / 16,
          fontSize: theme.typography.pxToRem(16),
          padding: theme.spacing(2, 3),
          borderRadius: theme.shape.borderRadius,
        },
      },
    },
  };
}
