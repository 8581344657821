// 프로파일 업데이트
import axios from 'axios';
import queryClient from './queryClient';
import queryKeySerializerFn from './queryKeySerializerFn';

import type * as Apis from '@un7qi3/types/apis';

// 회원탈퇴
queryClient.setMutationDefaults('user/invalidate', {
  mutationFn() {
    return axios.get(`/api/users/me/invalidate`).then((response) => response.data);
  },
});

queryClient.setMutationDefaults('user/device', {
  mutationFn() {
    return axios.get(`/api/users/me/device`).then((response) => response.data);
  },
});

// 휴면해제
queryClient.setMutationDefaults('user/reactivate', {
  mutationFn() {
    return axios.get(`/api/users/me/reactivate`).then((response) => response.data);
  },
});

// 사용자 OTP 발급
queryClient.setMutationDefaults('user/token/otp', {
  mutationFn() {
    return axios.get('/api/users/otp').then((response) => response.data);
  },
});

// 프로파일 업데이트
queryClient.setMutationDefaults('user/update', {
  mutationFn(mutationVariables) {
    return axios.post(`/api/users/me/profile`, mutationVariables).then((response) => response.data);
  },
});

// 무료아이템 계산
queryClient.setMutationDefaults('item/calc', {
  mutationFn(mutationVariables) {
    const [pathname, data] = queryKeySerializerFn(['/api/items/calc/:uid', mutationVariables]);

    return axios.post(pathname, data).then((response) => response.data);
  },
});

// 궁합계산
queryClient.setMutationDefaults('goonghap/calc', {
  mutationFn(mutationVariables) {
    const [pathname, data] = queryKeySerializerFn([
      '/api/goonghap/calc/:code/friend/:uid',
      mutationVariables,
    ]);

    return axios.post(pathname, data).then((response) => response.data);
  },
});

/**
 return axios
 .post(`/api/item/upload/${uid}`, encodeURIComponent(base64image.split(',')[1]))
 .then((response) => response.data)
 .then(({data}) => setShareImage(data.url));
 */

// 무료아이템 계산
queryClient.setMutationDefaults('item/upload', {
  mutationFn(mutationVariables) {
    const [pathname, data] = queryKeySerializerFn(['/api/item/upload/:uid', mutationVariables]);

    if (!data.image) {
      return Promise.reject('no image');
    }

    return axios
      .post<Apis.Response<{url: string}>>(pathname, encodeURIComponent(data.image.split(',')[1]))
      .then((response) => response.data)
      .then((response) => response.data.url);
  },
});

// 테마아이템 좋아요
queryClient.setMutationDefaults('theme/favorite', {
  mutationFn(mutationVariables) {
    const [pathname, data] = queryKeySerializerFn(['/api/theme/:uid/like', mutationVariables]);

    return axios.post<Apis.ItemAnswerProps>(pathname, data).then((response) => response.data);
  },
});

// 선물 수락,거절,취소
queryClient.setMutationDefaults('present/type/voucher', {
  mutationFn(mutationVariables) {
    const [pathname, data] = queryKeySerializerFn([
      '/api/present/:type/:voucherKey',
      mutationVariables,
    ]);

    return axios.post<Apis.ItemAnswerProps>(pathname, data).then((response) => response.data);
  },
});
