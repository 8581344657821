import palette from './palette';

import type {
  TypographyStyleOptions,
  TypographyOptions,
} from '@mui/material/styles/createTypography';

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    body: TypographyStyleOptions;
    announce: TypographyStyleOptions;
  }

  // allow configuration using `createMuiTheme`
  interface TypographyOptions {
    body?: TypographyStyleOptions;
    announce?: TypographyStyleOptions;
  }
}

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    body: true;
    announce: true;
  }
}

function pxToRem(value: number) {
  return `${value / 16}rem`;
}

const fontFamily = ['Noto Sans KR', '-apple-system', 'sans-serif'].join(',');
const fontWeightLight = 300;
const fontWeightRegular = 400;
const fontWeightMedium = 500;
const fontWeightBold = 700;

const typography: TypographyOptions = {
  fontFamily,
  fontWeightLight,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightBold,
  h1: {
    fontSize: pxToRem(24),
    fontWeight: fontWeightBold,
    lineHeight: 1.4,
    letterSpacing: '-0.02em',
  },
  h2: {
    fontSize: pxToRem(22),
    fontWeight: fontWeightBold,
    lineHeight: 1.25,
    letterSpacing: '-0.02em',
  },
  h3: {
    fontSize: pxToRem(20),
    fontWeight: fontWeightBold,
    lineHeight: 1.25,
    letterSpacing: '-0.02em',
  },
  h4: {
    fontSize: pxToRem(18),
    fontWeight: fontWeightMedium,
    lineHeight: 1.4,
    letterSpacing: '-0.02em',
  },
  h5: {
    fontSize: pxToRem(16),
    fontWeight: fontWeightMedium,
    lineHeight: 1.4,
    letterSpacing: '-0.02em',
  },
  h6: {
    fontSize: pxToRem(14),
    fontWeight: fontWeightRegular,
    lineHeight: 1,
    letterSpacing: '-0.02em',
  },
  subtitle1: {
    fontSize: pxToRem(14),
    fontWeight: fontWeightRegular,
    lineHeight: 1,
    letterSpacing: '-0.02em',
  },
  subtitle2: {
    fontSize: pxToRem(12),
    fontWeight: fontWeightRegular,
    lineHeight: 1,
    letterSpacing: '-0.02em',
  },
  caption: {
    fontSize: pxToRem(12),
    fontWeight: fontWeightRegular,
    lineHeight: 20 / 12,
    letterSpacing: '-0.02em',
  },
  body1: {
    fontSize: pxToRem(14),
    fontWeight: fontWeightRegular,
    lineHeight: 19 / 14,
    letterSpacing: '-0.02em',
  },
  body2: {
    fontSize: pxToRem(16),
    fontWeight: fontWeightRegular,
    lineHeight: 1.4,
    letterSpacing: '-0.02em',
  },
  button: {
    fontSize: pxToRem(16),
    fontWeight: fontWeightBold,
    lineHeight: 28 / 16,
    letterSpacing: -0.32,
    textTransform: 'inherit',
  },
  overline: {
    fontSize: pxToRem(10),
    fontWeight: fontWeightMedium,
    lineHeight: 1,
    letterSpacing: '-0.32px',
  },
};

//
typography.announce = {
  fontFamily,
  fontSize: 12,
  fontWeight: 400,
  lineHeight: 20 / 12,
  letterSpacing: '-0.02em',
  padding: '15px 23px 18px 20px',
  borderRadius: '8px',

  color: palette.grey[600],
  backgroundColor: palette.grey[50],
  display: 'block',

  '& strong': {
    display: 'block',
  },
};

// 질문이나 답변 스토리 등 본문에 사용되는 Typography
typography.body = {
  fontFamily,
  ...typography.body2,
  lineHeight: 1.75,

  '& p': {
    margin: '0 0 20px',
  },

  'ul > li p + ul': {
    margin: '0 0 20px 0',

    // '& li:before': {
    //   content: '"\u2219"',
    //   fontSize: 'inherit',
    //   lineHeight: 'inherit',
    //   paddingRight: '6px',
    // },
    //
    // '& ul': {
    //   marginLeft: 15,
    // },
  },

  '& h1, h2, h3, h4, h5': {
    margin: '48px 0 16px',

    '&:first-of-type': {
      marginTop: 0,
    },
  },

  '& h1': {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.6,
  },
  '& h2': {
    fontSize: 22,
    fontWeight: 500,
    lineHeight: 1.5,
  },
  '& h3': {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.7,
  },
  '& h4': {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.7,
  },
  '& h5': {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.75,
  },
  '& h6': {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.75,
    margin: '-8px 0 16px',
    letterSpacing: 0.4,
  },

  '& img': {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
    lineHeight: 0,
  },

  '& a': {
    color: '#428BFF',
  },

  '.wp-block-image': {
    maxWidth: '100%',
    margin: '0 auto 22px',

    '& img': {
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
      lineHeight: 0,
    },

    '& .aligncenter': {
      display: 'table',
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    '& figcaption': {
      display: 'block',
      marginTop: 'calc(.5*16px)',
      marginBottom: 16,
      textAlign: 'center',
      ...typography.caption,
    },
  },
  '& .wp-block-separator': {
    display: 'block',
    height: 1,
    border: 0,
    borderTop: `1px solid ${palette.divider}`,
    margin: '1em 0',
    padding: 0,
  },
};

export default typography;
