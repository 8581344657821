import React from 'react';

import Portal from '@mui/material/Portal';
import {experimentalStyled as styled} from '@mui/material/styles';
import {useForkRef} from '@mui/material/utils';

import type {SxProps} from '@mui/system';
interface StickyBarStyleProps {
  height?: number;
  position?: 'fixed' | 'sticky';
  direction?: 'top' | 'bottom';
  noBorder?: boolean;
  row?: boolean;
}

export interface StickyBarProps extends React.ComponentProps<'div'>, StickyBarStyleProps {
  sx?: SxProps;
}

const StickyBarRoot = styled('div')<{ownerState: StickyBarStyleProps}>(
  {
    display: 'flex',
    left: 0,
    right: 0,
  },
  ({theme, ownerState}) => ({
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: 'transparent',
    backgroundColor: theme.palette.common.white,
    ...(ownerState.row
      ? {flexDirection: 'row', alignItems: 'center'}
      : {
          flexDirection: 'column',
          justifyContent: 'center',
        }),

    ...(Number.isInteger(ownerState.height) && {
      height: ownerState.height,
    }),

    ...(ownerState.position === 'sticky' && {
      position: 'sticky',
    }),
    ...(ownerState.position === 'fixed' && {
      position: 'fixed',
    }),
    ...(ownerState.direction === 'top' && {
      top: 0,
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.divider,
    }),
    ...(ownerState.direction === 'bottom' && {
      bottom: 0,
      borderTopWidth: 1,
      borderTopColor: theme.palette.divider,
    }),
    ...(ownerState.noBorder && {
      borderTopWidth: 0,
      borderBottomWidth: 0,
    }),
  }),
);

const StickyBarSpacer = styled('div')(({theme}) => ({
  height: 1,
  transition: theme.transitions.create(['height'], {
    duration: theme.transitions.duration.short,
  }),
}));

const StickyBar = React.forwardRef<HTMLDivElement, StickyBarProps>(
  (
    {
      position = 'sticky',
      direction = 'bottom',
      row = true,
      noBorder = false,
      height,
      children,
      ...otherProps
    },
    ref,
  ) => {
    const nodeRef = React.useRef<HTMLDivElement | null>(null);
    const resizeObserverRef = React.useRef<ResizeObserver>();
    const [offsetHeight, setOffsetHeight] = React.useState<number>(height ?? 0);

    const handleRef = useForkRef<HTMLDivElement>(nodeRef, ref);

    React.useEffect(() => {
      if (nodeRef.current) {
        resizeObserverRef.current = new ResizeObserver((entries: ResizeObserverEntry[]) => {
          const entry = entries[0];

          if (entry.contentRect.height > 0) {
            setOffsetHeight(() => {
              resizeObserverRef.current?.disconnect();
              return entry.contentRect.height;
            });
          }
        });

        resizeObserverRef.current.observe(nodeRef.current);
      }

      return () => {
        if (resizeObserverRef.current) {
          resizeObserverRef.current.disconnect();
        }
      };
    }, []);

    const ownerState = {
      row,
      position,
      direction,
      height,
      noBorder,
    };

    return (
      <React.Fragment>
        <StickyBarRoot ref={handleRef} ownerState={ownerState} {...otherProps}>
          {children}
        </StickyBarRoot>
        {position === 'fixed' && offsetHeight > 0 && (
          <Portal>
            <StickyBarSpacer sx={{height: `${offsetHeight}px`}} />
          </Portal>
        )}
      </React.Fragment>
    );
  },
);

export default StickyBar;
