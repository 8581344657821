import Modal from '@mui/material/Modal';
import {QrModalBox, ModalIcon} from 'components/Test/styles/ModalStyles';
import React, {useContext} from 'react';
import {QR_CODE} from './qrSvg';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {ModalOpenal} from 'components/Test/modal/CenterModal';

const QrBox = styled('div')`
  border: 2px solid #e8e8e8;
  border-radius: 16px;
  padding: 5px 5px 0 5px;
  margin-bottom: 10px;
`;

export const DesktopQrModal: React.FC = () => {
  // @ts-ignore
  const [qrOpenModal, setQrOpenModal] = useContext(ModalOpenal);
  const pushModalOpenFalse = () => {
    setQrOpenModal(false);
    sessionStorage.setItem('open_modal', 'false');
  };
  return (
    <Modal
      sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      open={qrOpenModal}
      onBackdropClick={() => setQrOpenModal(false)}>
      <QrModalBox>
        <ModalIcon
          src={'https://static-en.forceteller.com/www/images/ico_app_launcher_squricle.png'}
        />
        <Typography sx={{marginBottom: '15px'}} variant={'h3'}>
          QR코드를 스캔하세요!
        </Typography>
        <QrBox>
          <QR_CODE />
        </QrBox>
        <Button
          onClick={pushModalOpenFalse}
          sx={{
            width: '100%',
            height: '24px',
            fontWeight: '500',
            fontSize: '10px',
            color: '#A3A3A3',
          }}
          variant={'text'}>
          웹으로 볼래요.
        </Button>
      </QrModalBox>
    </Modal>
  );
};
