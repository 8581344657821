const NAMESPACE = 'TwitterPixel';

export default function twitterPixelPlugin(userConfig) {
  console.log('twitterPixelPlugin', userConfig);
  // return object for analytics to use
  return {
    name: NAMESPACE,
    /* Everything else below this is optional depending on your plugin requirements */
    config: {
      trackingId: userConfig.trackingId,
    },
    initialize({config}) {
      console.log(config.trackingId);
      // prettier-ignore
      // eslint-disable-next-line no-unused-expressions
      !function(d,e,f,a,b,c){d.twq||(a=d.twq=function(){a.exe?a.exe.apply(a,arguments):a.queue.push(arguments)},a.version="1.1",a.queue=[],b=e.createElement(f),b.async=!0,b.src="//static.ads-twitter.com/uwt.js",c=e.getElementsByTagName(f)[0],c.parentNode.insertBefore(b,c))}(window,document,"script");

      window.twq('init', config.trackingId);
      // window.twq('track', 'PageView');
    },
    page() {
      window.twq('track', 'PageView');
    },
    track({payload}) {
      let event = payload.event;

      const properties = payload.properties ?? {};

      if (event === 'purchase') {
        event = 'Purchase';

        properties.num_items = 1;
      } else if (
        event === '동의하고 시작하기' &&
        properties.category.replace(/\s/g, '') === '회원가입'
      ) {
        event = 'signup';
      }

      window.twq('track', event, properties);
    },
    identify() {
      return null;
    },
    loaded() {
      return !!window.twq;
    },
  };
}
