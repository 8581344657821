import {TagChip} from 'containers/Tag';
import React from 'react';
import Swiper, {ReactIdSwiperProps} from 'react-id-swiper';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import {KeywordProps} from '@un7qi3/types/apis';

const useStyles = makeStyles({
  root: {},
  wrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: 'flex',
    fontFamily: 'Noto Sans KR',
  },
  swiperBox: {
    position: 'relative',
    overflow: 'hidden',
    padding: '0 20px',
    zIndex: 3,
    visibility: 'visible',
    margin: '20px 0',
  },
  chip: {
    display: 'inline-block',
    margin: '4px 0',
  },
  tagChip: {
    height: 30,
    marginRight: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    borderRadius: 6,
    border: 'solid 1px #ededed',
    background: '#fff',
    color: '#5e5e5e',
    fontSize: '14px',
    lineHeight: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 14px 1px',
    cursor: 'pointer',

    '&.active': {
      color: '#fff',
      fontWeight: 'bold',
      background: '#191919',
      borderColor: '#191919',
    },
  },
});

interface GroupTagsProps {
  tagSelected: (value?: KeywordProps) => void;
  tags: KeywordProps[];
}

export const GroupTags = React.memo<GroupTagsProps>(({tagSelected, tags}) => {
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const classes = useStyles();
  const params: ReactIdSwiperProps = {
    slidesPerView: 'auto',
    resistance: true,
    resistanceRatio: 0,
    wrapperClass: classes.wrapper,
    slideClass: classes.chip,
  };

  const clickTag = (index: number) => () => {
    if (selectedIndex !== index) {
      setSelectedIndex(index);
      tagSelected(tags[index]);
    }
  };

  return (
    <Box className={classes.swiperBox}>
      <Swiper {...params}>
        {tags?.map((tag, index) => (
          <div key={String(index)}>
            <TagChip
              word={tag.text || '전체'}
              selected={selectedIndex === index}
              onClick={clickTag(index)}
              classes={classes.tagChip}
            />
          </div>
        ))}
      </Swiper>
    </Box>
  );
});
