import React from 'react';
import AppBar from 'containers/AppBar';
import AppBarA from 'components/Test/fixBar/AppBarA';
import AppBarB from 'components/Test/fixBar/AppBarB';
import {APP_LINK} from 'components/Test/constants';
import {CatchConfig} from '../hooks/CatchFixbarConfig';

export const FixBarExperiment = () => {
  const fixBarStatus = CatchConfig('test_fixbar');

  if (fixBarStatus === '0') {
    return (
      <>
        <AppBarA link={APP_LINK} />
        <AppBar style={{top: 56, zIndex: 1000}} />
      </>
    );
  } else if (fixBarStatus === '1') {
    return (
      <>
        <AppBarB link={APP_LINK} /> <AppBar />
      </>
    );
  }
  return null;
};
