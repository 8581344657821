import React, {useState} from 'react';

import {AppBar as MuiAppBar, Button, Toolbar, Link} from '@mui/material';
import styled from '@mui/styles/styled';
import {ModalIcon} from 'components/Test/styles/ModalStyles';
import {appTouchAction, fixBarAction, fixBarTopLabel, category} from '../../constants';
import Analytics from '~/plugins/tracker/analytics';
import {BrowserView, MobileView} from 'react-device-detect';
import {DesktopQrModal} from 'components/Test/QR/desktopQrModal';
import {ModalOpenal} from 'components/Test/modal/CenterModal';

type Props = {
  link: string;
};

const FixBar = styled(MuiAppBar)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  boxShadow: 'none',
  boxSizing: 'border-box',
  height: 56,
  alignItems: 'center',
});
const SpacingBar = styled(Toolbar)({
  marginBottom: '2em',
});
const StyledToolBar = styled(Toolbar)({
  width: 600,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 16px',
  '&>div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&>img': {
      marginBottom: 0,
    },
    '&>span': {
      display: 'block',
      fontWeight: 600,
      lineHeight: '1.3em',
      letterSpacing: '-0.02em',
      marginLeft: '12px',
      fontSize: 12,
    },
  },
});

const AppButton = styled(Button)({
  background: '#fff',
  color: '#333',
  fontSize: 14,
  padding: '8px 20px',
  borderRadius: 4,
  translate: '0.5s',
  '&:hover': {
    background: '#fff',
    opacity: 0.7,
  },
});

const AppBarA = React.forwardRef<HTMLDivElement, Props>(({link}, ref) => {
  const [qrOpenModal, setQrOpenModal] = useState(false);
  React.useEffect(() => {
    Analytics.track(fixBarAction, {category: category, label: fixBarTopLabel}).then(() => {
      console.log(`${fixBarTopLabel} ${fixBarAction}`);
    });
  }, []);

  return (
    <React.Fragment>
      <FixBar>
        <StyledToolBar>
          <div>
            <ModalIcon
              src={'https://static-en.forceteller.com/www/images/ico_app_launcher_squricle.png'}
              style={{width: 36, height: 36}}
            />
            <span>
              모바일 앱에서 <br />
              더욱 편리하게!
            </span>
          </div>
          <MobileView>
            <Link href={link} style={{textDecoration: 'none'}}>
              <AppButton
                variant="contained"
                data-event-action={appTouchAction}
                data-event-category={category}
                data-event-label={fixBarTopLabel}>
                앱으로 보기
              </AppButton>
            </Link>
          </MobileView>
          <BrowserView>
            <AppButton
              onClick={() => setQrOpenModal((prev) => !prev)}
              variant="contained"
              data-event-action={appTouchAction}
              data-event-category={category}
              data-event-label={fixBarTopLabel}>
              앱으로 보기
            </AppButton>
          </BrowserView>
        </StyledToolBar>
      </FixBar>
      <SpacingBar />
      <ModalOpenal.Provider value={[qrOpenModal, setQrOpenModal]}>
        {qrOpenModal ? <DesktopQrModal /> : null}
      </ModalOpenal.Provider>
    </React.Fragment>
  );
});
export default AppBarA;
