import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import clsx from 'clsx';
import {nativeSelectClasses} from '@mui/material/NativeSelect';
import MuiSelect, {SelectProps as MuiSelectProps, selectClasses} from '@mui/material/Select';
import {experimentalStyled as styled} from '@mui/material/styles';

import FontAwesomeIcon, {FontAwesomeProps} from '@un7qi3-ui/react/icons/FontAwesome';

interface SelectStyleProps {
  value?: boolean;
}

interface SelectProps extends Omit<MuiSelectProps, 'name' | 'onChange' | 'onBlur'> {
  name: string;
}

const SelectRoot = styled(MuiSelect)<{ownerState: SelectStyleProps}>(({theme, ownerState}) => ({
  ...(!ownerState.value && {
    color: theme.palette.grey[400],
  }),
  [`.${selectClasses.select}, .${nativeSelectClasses.select}`]: {
    paddingRight: '36px !important',

    '&:focus': {
      borderRadius: 'inherit',
      backgroundColor: 'unset',
    },
  },
  [`.${selectClasses.icon}, .${nativeSelectClasses.icon}`]: {
    fontSize: 12,
    margin: '6px 12px',
  },
}));

//
const SelectIcon = React.memo<FontAwesomeProps>(({className, ...otherProps}) => {
  return <FontAwesomeIcon className={clsx('far', 'fa-chevron-down', className)} {...otherProps} />;
});

const Select: React.FC<SelectProps> = ({
  name,
  variant = 'standard',
  native = true,
  displayEmpty = true,
  disabled: disabledProp,
  ...otherProps
}) => {
  const {control, unregister, watch} = useFormContext();

  //
  const ownerState = {
    value: watch(name),
  };

  React.useEffect(() => {
    return () => unregister(name);
  }, [name]);

  return (
    <Controller
      name={name}
      control={control}
      render={({field, formState}) => {
        const {ref: inputRef, ...fieldProps} = field;

        const hasError = !!formState.errors[name];
        const disabled = disabledProp || formState.isSubmitting;

        return (
          <SelectRoot
            native
            displayEmpty
            variant={variant}
            error={hasError}
            disabled={disabled}
            inputRef={inputRef}
            IconComponent={SelectIcon}
            ownerState={ownerState}
            {...fieldProps}
            {...otherProps}
          />
        );
      }}
    />
  );
};

export default Select;
