import React from 'react';
import {experimentalStyled as styled} from '@mui/material/styles';

import type {SxProps} from '@mui/system';
interface LabelProps extends React.ComponentProps<'label'> {
  sx?: SxProps;
}

const LabelRoot = styled('label')(({theme}) => ({
  ...theme.typography.body1,
  fontWeight: theme.typography.fontWeightMedium,
  minWidth: 74,
}));

const Label: React.FC<LabelProps> = (props) => {
  return <LabelRoot {...props} />;
};

export default Label;
