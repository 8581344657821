import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {DialogStateProps} from 'hooks/states';

const AlertDialog = React.memo<DialogStateProps>((dialogOption) => {
  return (
    <Dialog
      onClose={() => dialogOption.onClose()}
      open={dialogOption.open}
      maxWidth="xs"
      sx={{'& .MuiDialog-paper': {minWidth: 300, borderRadius: '12px'}}}>
      <DialogTitle
        sx={{padding: '24px 24px 12px', fontSize: 20, fontWeight: 'bold', lineHeight: 1.25}}>
        {dialogOption.title}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{whiteSpace: 'pre-line', fontSize: 16, color: '#5e5e5e'}}>
          {dialogOption.content}
        </Typography>
      </DialogContent>
      <DialogActions sx={{p: '12px'}}>
        <Button
          onClick={() => dialogOption.onClose()}
          sx={{fontSize: 14, color: '#428bff', fontWeight: 'normal'}}>
          {dialogOption.cancel}
        </Button>
        <Button
          onClick={() => dialogOption.onConfirm()}
          autoFocus
          sx={{fontSize: 14, color: '#428bff', fontWeight: 'bold'}}>
          {dialogOption.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default AlertDialog;
