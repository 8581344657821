import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import AppBarBase, {AppBarProps} from '@mui/material/AppBar';
import FontAwesomeIcon from '@un7qi3-ui/react/icons/FontAwesome/FontAwesome';
import clsx from 'clsx';
import omit from 'lodash.omit';
import * as iframe from 'features/core/iframe';

export interface InAppBarProps extends AppBarProps {
  back?: boolean;
}

const InAppBar = React.memo<InAppBarProps>((props) => {
  const handleClick = () => {
    iframe.close();
  };

  return (
    <React.Fragment>
      <AppBarBase color="inherit" elevation={0} {...omit(props, 'back')}>
        <Toolbar>
          <Container>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={props.back ? 'flex-start' : 'flex-end'}>
              <Stack direction="row" alignItems="center" onClick={handleClick}>
                {props.back ? (
                  <FontAwesomeIcon
                    className={clsx('fal', 'fa-angle-left')}
                    sx={{fontSize: '30px'}}
                  />
                ) : (
                  <FontAwesomeIcon className={clsx('fal', 'fa-times')} sx={{fontSize: '20px'}} />
                )}
              </Stack>
            </Stack>
          </Container>
        </Toolbar>
      </AppBarBase>
    </React.Fragment>
  );
});

export default InAppBar;
