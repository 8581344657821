export default function getAnalyticsCategory(pathname: string) {
  const segments = String(pathname)
    .split(/\//)
    .filter(Boolean)
    .map((v) => (v ? String(v).toUpperCase() : ''));

  switch (segments[0] ?? 'HOME') {
    case 'HOME':
      return '홈';
    case 'ITEM':
      return '무료 운세 질문';
    case 'R':
      return '무료 운세 결과';
    case 'STORY':
      return '스토리 본문';
    case 'HELP':
      return '고객 센터';
    case 'SIGNUP':
      return '회원 가입';
    case 'PROFILE':
      return '프로필 수정';
    case 'FORCE':
      if (segments[1] === 'CHARGE' && !segments[2]) {
        return '포스 충전소';
      }
      break;
    case 'PREMIUM':
      return '프리미엄 상품 상세';
    case 'FATEBOOK':
      return '페이트북';
  }
}
