import React from 'react';

export interface IfMatchCaseProps {
  then: string | number | boolean;
  element?: React.ReactNode;
  children?: React.ReactNode;
}

const IfMatchCase: React.FC<IfMatchCaseProps> = () => null;

export default IfMatchCase;
