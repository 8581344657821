import React from 'react';
import {useNavigate} from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';

import FontAwesomeIcon from '@un7qi3-ui/react/icons/FontAwesome';
import toNumberWithComma from '@un7qi3/utils/toNumberWithComma';

import useSetGuestPurchasable from './hooks/useSetGuestPurchasable';

import type * as Apis from '@un7qi3/types/apis';
interface GuestPurchaseProps {
  title?: string;
  purchasable: Apis.PurchasableItemProps;
}

const GuestPurchaseRoot = styled('div')(() => ({
  position: 'relative',
  backgroundColor: 'white',
  margin: '4px 0 0',

  ':before': {
    position: 'absolute',
    top: -4,
    display: 'block',
    content: '" "',
    backgroundImage: 'url(https://static.forceteller.com/images/gift/border_receipt.png)',
    backgroundSize: '12px auto',
    backgroundPosition: 'top left',
    width: '100%',
    height: '4px',
    // zIndex: -1,
  },
}));

const GuestPurchase: React.FC<GuestPurchaseProps> = ({purchasable, ...otherProps}) => {
  const navigate = useNavigate();
  const setGuestPurchasable = useSetGuestPurchasable();

  const toggleDrawer = () => {
    setGuestPurchasable(null);
  };

  return (
    <GuestPurchaseRoot>
      <Container>
        <Stack direction="row" justifyContent="flex-end" sx={{p: '15px 20px'}}>
          <IconButton color="primary" onClick={toggleDrawer}>
            <FontAwesomeIcon className="fal fa-times" fontSize={24} />
          </IconButton>
        </Stack>
        <Stack direction="row" justifyContent="space-between" sx={{p: '0 20px 28px'}}>
          <Typography variant="h3">결제 금액</Typography>
          {purchasable && (
            <Typography variant="h3" sx={{color: '#DBA01A'}}>
              {toNumberWithComma(purchasable.price)}원
            </Typography>
          )}
        </Stack>
      </Container>
      {purchasable && (
        <ButtonGroup fullWidth onClick={toggleDrawer}>
          <Button
            variant="text"
            color="primary"
            data-event-action="로그인"
            data-event-category="프리미엄 구매 모달"
            data-event-label={
              purchasable ? `[premium ${purchasable.itemId}] ${purchasable.name}` : ''
            }
            onClick={() => navigate(`/signin?redirect_uri=${window.location.pathname}`)}
            sx={{
              fontWeight: 400,
              borderRadius: 0,
              fontSize: 17,
              borderTop: 'solid 1px rgba(111, 111, 112, 0.45)',
            }}>
            로그인
          </Button>
          <Button
            variant="contained"
            data-event-action="비회원 구매 하기"
            data-event-category="프리미엄 구매 모달"
            data-event-label={
              purchasable ? `[premium ${purchasable.itemId}] ${purchasable.name}` : ''
            }
            onClick={() => navigate('/guest/pay', {state: {purchasable}})}
            sx={{borderRadius: 0, fontSize: 17}}>
            비회원 구매하기
          </Button>
        </ButtonGroup>
      )}
    </GuestPurchaseRoot>
  );
};

export default GuestPurchase;
