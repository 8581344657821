const NAMESPACE = 'wcslog';

export default function wcslogPlugin(userConfig) {
  // return object for analytics to use
  return {
    name: NAMESPACE,
    /* Everything else below this is optional depending on your plugin requirements */
    config: {
      trackingId: userConfig.trackingId,
    },
    initialize({config}) {
      // eslint-disable-next-line
      // !(function (f, b, e, v, t, s) {
      //   // console.log(f.wcs_add, f.wcs);
      //   // if (f.wcs) return;
      //   //
      //   // f.wcs = function () {
      //   //   if (f['wcs']) {
      //   //     const kkq = f['kakaoPixel'](config.trackingId);
      //   //     return kkq.track.apply(kkq, arguments);
      //   //   }
      //   // };
      //
      //   t = b.createElement(e);
      //   t.async = !0;
      //   t.src = v;
      //   s = b.getElementsByTagName(e)[0];
      //   s.parentNode.insertBefore(t, s);
      // })(window, document, 'script', 'https://wcs.naver.net/wcslog.js');
    },
    page() {
      return null;
    },
    track({config, payload}) {
      let _nasa = {};

      if (window.wcs) {
        //
        // if (window.wcs_add) {
        //   window.wcs_add = {
        //     wa: config.trackingId,
        //   };
        // }
        //
        // window.wcs.inflow('forceteller.com');

        if (['purchase'].includes(payload.event) && payload.properties?.value) {
          _nasa.cnv = window.wcs.cnv('1', payload.properties?.value);
          window.wcs_do(_nasa);
        } else if (
          payload.event === '동의하고 시작하기' &&
          payload.properties &&
          payload.properties.category === '회원 가입'
        ) {
          _nasa.cnv = window.wcs.cnv('2', '1');
          window.wcs_do(_nasa);
        }
      }
    },
    identify() {
      return null;
    },
    loaded() {
      return !!window.wcs;
    },
  };
}
//
//
// <!-- 전환페이지 설정 -->
// <script type="text/javascript" src="//wcs.naver.net/wcslog.js"></script>
// <script type="text/javascript">
//   var _nasa={};
//   if(window.wcs) _nasa["cnv"] = wcs.cnv("1","10"); // 전환유형, 전환가치 설정해야함. 설치매뉴얼 참고
// </script>
