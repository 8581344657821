import React from 'react';
import axios from 'axios';

import useSatellizerToken from 'features/auth/hooks/useSatellizerToken';
import useBrowserTokenState from 'features/auth/hooks/useBrowserTokenState';

function uuidv4() {
  const pattern = `xxxxxxxx-xxxx-4xxx-yxxx-x${new Date().getTime().toString(16)}`;

  return pattern.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default function AxiosRequestObserver() {
  const tokenRef = React.useRef<string | null>();
  const browserTokenRef = React.useRef<string | null>();

  const satellizerToken = useSatellizerToken();
  const [browserToken, setBrowserToken] = useBrowserTokenState();

  React.useEffect(() => {
    browserTokenRef.current = browserToken;

    if (browserTokenRef.current === null) {
      setBrowserToken(() => {
        browserTokenRef.current = uuidv4();

        return browserTokenRef.current;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // SatellizerToken 변경 감지
  React.useEffect(() => {
    tokenRef.current = satellizerToken;
  }, [satellizerToken]);

  // interceptors 가 여러개 생성되는 것을 방지
  React.useEffect(() => {
    const ejectId = axios.interceptors.request.use((config) => {
      const externReq =
        config.url &&
        process.env.REACT_APP_SERVICE_API_URL &&
        /^https?:/.test(config.url) &&
        !config.url.startsWith(process.env.REACT_APP_SERVICE_API_URL);

      if (!externReq) {
        config.headers['X-BROWSER-UUID'] = browserTokenRef.current;

        if (tokenRef.current && !config.headers['X-AUTH-TOKEN']) {
          config.headers['X-AUTH-TOKEN'] = tokenRef.current;
        }
      }

      return config;
    });

    return () => axios.interceptors.request.eject(ejectId);
  }, []);

  return <span className="hidden" id="axios-request-observer" />;
}
