import React from 'react';

import {useRadioGroup} from '@mui/material/RadioGroup';
import RadioBase, {radioClasses} from '@mui/material/Radio';
import FormControlLabel, {formControlLabelClasses} from '@mui/material/FormControlLabel';
import {experimentalStyled as styled} from '@mui/material/styles';

import type {FormControlLabelProps} from '@mui/material/FormControlLabel';

interface RadioStyleProps {
  button?: boolean;
  checked?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  error?: boolean;
}

interface RadioProps
  extends Omit<FormControlLabelProps, 'name' | 'control' | 'checked' | 'onChange' | 'onBlur'>,
    Omit<RadioStyleProps, 'checked'> {}

const RadioRoot = styled(FormControlLabel)<{ownerState: RadioStyleProps}>(
  ({theme, ownerState}) => ({
    ...(ownerState.button && {
      margin: '0 9px 0 0',
      padding: '10px 1px 12px',
      borderRadius: 6,
      color: theme.palette.grey[400],
      border: `solid 1px ${theme.palette.grey[200]}`,
      boxSizing: 'border-box',

      flexGrow: 1,
      minHeight: 46,

      '&:last-child': {
        marginRight: 0,
      },

      // Icon hidden
      [`.${radioClasses.root}`]: {
        position: 'absolute',
        clip: 'rect(0,0,0,0)',
      },

      [`& .${formControlLabelClasses.label}`]: {
        ...theme.typography.body2,
        color: 'inherit',
        fontWeight: 'inherit',
        textAlign: 'center',
        width: '100%',
      },

      ...(ownerState.disabled && {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.text.disabled,
        cursor: 'default',
      }),

      ...(ownerState.error && {
        borderColor: theme.palette.error.main,
      }),

      ...(ownerState.checked && {
        color: theme.palette.grey[900],
        fontWeight: theme.typography.fontWeightMedium,
        borderColor: theme.palette.grey[900],
        borderWidth: 2,
        padding: '9px 0 11px',
      }),
    }),
    ...(ownerState.fullWidth && {
      margin: 0,
      width: '100%',
    }),
  }),
);

const Radio: React.FC<RadioProps> = ({
  button = false,
  fullWidth = false,
  error = false,
  disabled,
  ...otherProps
}) => {
  const radioGroup = useRadioGroup();

  if (radioGroup) {
    const ownerState = {
      button,
      checked: radioGroup.value === otherProps.value,
      fullWidth,
      disabled,
      error,
    };

    return (
      <RadioRoot
        name={radioGroup.name}
        control={<RadioBase />}
        ownerState={ownerState}
        disabled={disabled}
        {...otherProps}
      />
    );
  }

  return null;
};

export default Radio;
