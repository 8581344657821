import {Icon, SideMenuBox} from 'components/Test/styles/SideMenyStyles';
import Typography from '@mui/material/Typography';
import {forcetellerAppAction, category, sideMenuLabel, APP_LINK} from 'components/Test/constants';
import React, {useState} from 'react';
import {DesktopQrModal} from 'components/Test/QR/desktopQrModal';
import {ModalOpenal} from 'components/Test/modal/CenterModal';
import {BrowserView, MobileView} from 'react-device-detect';

export const SideMenu2: React.FC = () => {
  const [openQrModal, setOpenQrModal] = useState(false);
  return (
    <SideMenuBox
      data-event-action={forcetellerAppAction}
      data-event-category={category}
      data-event-label={sideMenuLabel}>
      <Icon src={'https://static-en.forceteller.com/www/images/ico_app_launcher_squricle.png'} />
      <MobileView>
        <a href={APP_LINK}>
          <Typography variant={'h5'}>포스텔러 앱</Typography>
        </a>
      </MobileView>
      <BrowserView>
        <Typography onClick={() => setOpenQrModal((prev) => !prev)} variant={'h5'}>
          포스텔러 앱
        </Typography>
      </BrowserView>
      <ModalOpenal.Provider value={[openQrModal, setOpenQrModal]}>
        {openQrModal ? <DesktopQrModal /> : null}
      </ModalOpenal.Provider>
    </SideMenuBox>
  );
};
