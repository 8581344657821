import React from 'react';
import {Loadable, useRecoilValue} from 'recoil';

import clsx from 'clsx';
import {Recommend} from 'components/Recommend';
import {InfiniteScroll} from 'components/Util/InfiniteScroll';
import Banner from 'containers/Banner';

import {Queries} from './states';
import {useDispatcher, NewsfeedConfigProps} from './dispatchers';

import type * as Apis from '@un7qi3/types/apis';
interface NewsProps extends NewsfeedConfigProps {
  keyword?: Apis.KeywordProps;
}

const NewsFeedPage = React.memo<{page: Loadable<any>; index: number}>(({page, index}) => {
  if (page.state === 'hasValue') {
    return page.contents.data.map((item: any, pageIndex: number) => {
      const banner = index === 0 && pageIndex === 3 ? <Banner areaKey="web_home_custom" /> : null;

      return (
        <React.Fragment key={item.id}>
          {banner}
          <div
            key={item.id}
            data-event-action="아이템 터치"
            data-event-action-type="navigate"
            className={clsx(item.status !== 'S3' && 'badge-admin')}>
            <Recommend {...item} />
          </div>
        </React.Fragment>
      );
    });
  }

  return null;
});

const NewsFeedPages = React.memo(() => {
  const pages = useRecoilValue(Queries);

  return (
    <React.Fragment>
      {pages.map((page: any, index) => (
        <NewsFeedPage key={`newsfeed-pages-${index}`} page={page} index={index} />
      ))}
    </React.Fragment>
  );
});

const Newsfeed: React.FC<NewsProps> = ({type, per_page = 10, keyword}) => {
  const dispatcher = useDispatcher({
    type,
    per_page,
  });

  React.useEffect(() => {
    dispatcher.setKeyword(keyword ?? null);
  }, [keyword]);

  return (
    <React.Fragment>
      <NewsFeedPages />
      <InfiniteScroll
        enabled={true}
        onIntersect={dispatcher.fetchNextPage as any}
        rootMargin={`${window.innerHeight / 2}px`}
      />
    </React.Fragment>
  );
};

export default Newsfeed;
