import React from 'react';
import {useRecoilState} from 'recoil';

import Drawer from '@mui/material/Drawer';

import GuestPurchase from './GuestPurchase';
import {guestPurchasableState} from './hooks/states';

const GuestPurchaseController = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [guestPurchasable, setGuestPurchasable] = useRecoilState(guestPurchasableState);

  const handleClose = () => setGuestPurchasable(null);

  React.useEffect(() => {
    setOpen(!!guestPurchasable);
  }, [guestPurchasable]);

  return (
    <Drawer
      open={open}
      anchor="bottom"
      onClose={handleClose}
      PaperProps={{sx: {backgroundColor: 'transparent'}}}>
      <GuestPurchase purchasable={guestPurchasable} />
    </Drawer>
  );
};

export default GuestPurchaseController;
