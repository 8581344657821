import axios from 'axios';
import {atom, selector, selectorFamily, waitForNone} from 'recoil';

export type TypeQueryVariableState = {
  [key: string]: any;
};

export type TypeQueryKeyState = string;

export const QueryKeyState = atom<TypeQueryKeyState | null>({
  key: 'Themecast/Newsfeed/QueryKeyState',
  default: null,
});

export const QueryVariableState = atom<TypeQueryVariableState | null>({
  key: 'Themecast/Newsfeed/QueryVariableState',
  default: null,
});

export const CursorState = atom<number>({
  key: 'Themecast/Newsfeed/CursorState',
  default: 0,
});

export const Query = selectorFamily<any, any>({
  key: 'Themecast/Newsfeed/Query',
  get:
    (cursor: number = 0) =>
    ({get}) => {
      const queryKey = get(QueryKeyState);
      const queryVariable = get(QueryVariableState);

      if (queryKey === null) {
        return Promise.reject('no queryKey');
      }

      const params = {
        ...queryVariable,
        o: cursor * (queryVariable?.l ?? 10),
      };

      return axios.get(queryKey, {params}).then((response) => response.data);
    },
});

export const Queries = selector({
  key: 'Themecast/Newsfeed/Queries',
  get: ({get}) => {
    const currentPageCursor = get(CursorState);
    const pageRange = Array.from(Array(currentPageCursor).keys());

    return get(waitForNone(pageRange.map(Query)));
  },
});
