import React, {DependencyList} from 'react';

import IfMatchElse from './IfMatchElse';
import IfMatchCase from './IfMatchCase';

export interface IfMatchProps<P = {}> {
  condition?: any;
  passProps?: P;
  deps?: DependencyList;
  children?: React.ReactNode;
}

/**
 * <IfMatch condition={}>
 *   <IfMatchWhen value="">
 *   <IfMatchElse element={}>
 * </IfMatch>
 */
export default React.memo<IfMatchProps>(({condition, passProps, deps, children: childrenProp}) => {
  return React.useMemo<React.ReactElement | null>(() => {
    let element, fallback;

    const value = typeof condition === 'function' ? condition() : condition;

    React.Children.forEach(childrenProp, (child) => {
      if (React.isValidElement(child)) {
        if (child.type === IfMatchElse) {
          fallback = child.props.element ?? child.props.children;
        } else if (child.type === IfMatchCase && value === child.props.then) {
          element = child.props.element ?? child.props.children;
        }
      }
    });

    const children = element ?? fallback;

    // 엘러먼트가 여러개 들어온 경우 passProps 를 주입하지 않음
    if (children && React.Children.count(children) > 1) {
      return children;
    }

    if (React.isValidElement(children)) {
      return React.cloneElement(children, passProps);
    }

    return null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, passProps, deps]);
});
