import React from 'react';
import {useQuery} from 'react-query';

import Skinnable from './skins/Skinnable';

import type * as Apis from '@un7qi3/types/apis';

type ThemecastSimilarType = 'item' | 'story' | 'premium';

interface ThemecastSimilarProps {
  uid?: string;
  type?: ThemecastSimilarType;
  suspense?: boolean;
}

const ThemecastSimilar: React.FC<ThemecastSimilarProps> = ({uid, type, suspense = false}) => {
  const query = useQuery<Apis.Response<Apis.ThemeProps[]>>(
    ['/api/theme/:type/:uid/similar', type, uid],
    {suspense},
  );

  if (query.status === 'success') {
    return (
      <React.Fragment>
        {query.data.data.map((item) => (
          <Skinnable key={item.uuid} skin={item.code} passProps={item} />
        ))}
      </React.Fragment>
    );
  }

  return null;
};

export default ThemecastSimilar;
