import React from 'react';
import {useSetRecoilState} from 'recoil';

import LinkArea, {LinkAreaProps} from '@un7qi3-ui/react/LinkArea';

import {DrawerMenuOpenState} from 'hooks/states/appearance';

export interface DrawerMenuLinkAreaProps extends LinkAreaProps {}

const DrawerMenuLinkArea = React.memo<DrawerMenuLinkAreaProps>(({onClick, ...otherProps}) => {
  const setDrawerMenuOpenState = useSetRecoilState(DrawerMenuOpenState);

  const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (typeof onClick === 'function') {
      onClick(e);
      return setDrawerMenuOpenState(false);
    }

    setDrawerMenuOpenState(false);
  };

  return <LinkArea onClick={handleOnClick} {...otherProps} />;
});

export default DrawerMenuLinkArea;
