import React from 'react';

export default function useHeight() {
  const clientHeight = document.documentElement.clientHeight;
  const offsetHeight = document.documentElement.offsetHeight;
  const [height, setHeight] = React.useState({clientHeight, offsetHeight});

  React.useEffect(() => {
    window.addEventListener('resize', onWindowResizeListener);
    return () => window.removeEventListener('resize', onWindowResizeListener);
  }, [height]);

  const onWindowResizeListener = () => {
    setHeight({clientHeight, offsetHeight});
  };

  return height;
}
