import React from 'react';
import {useMutation} from 'react-query';
import {FormProvider, useForm} from 'react-hook-form';

import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import * as Field from '../Form/Field';
import useSnackbar from 'hooks/useSnackbar';
import useAuthenticator from 'features/auth/hooks/useAuthenticator';

export interface InvalidateConfirmProps extends DialogProps {}

const InvalidateConfirm = React.memo<InvalidateConfirmProps>(({open, onClose, ...otherProps}) => {
  const formContext = useForm();
  const snackbar = useSnackbar();
  const {signOut} = useAuthenticator();
  const invalidateAsync = useMutation('user/invalidate').mutateAsync;

  const agreement = formContext.watch('agreement');

  const handleOnClose = (e: React.MouseEvent) => {
    if (typeof onClose === 'function') {
      onClose(e, 'backdropClick');
    }
  };

  const handleOnConfirmed = (e: React.MouseEvent) => {
    e.preventDefault();

    handleOnClose(e);

    return invalidateAsync()
      .then(() => signOut())
      .then(() => {
        snackbar.show('계정이 삭제되었습니다.');
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      sx={{'& .MuiDialog-paper': {minWidth: 300, borderRadius: '12px'}}}
      {...otherProps}>
      <DialogTitle sx={{p: '24px 24px 12px'}}>
        <Typography component="h2" variant="h3" fontWeight="bold">
          정말 계정을 삭제하시겠습니까?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="body2">
          고객님의 계정 및 활동 기록, 결제 이력, 구매 이력 등이 즉시 삭제되며 복구가 불가능합니다.
        </DialogContentText>

        <FormProvider {...formContext}>
          <Field.Checkbox name="agreement" label="이해하였으며 삭제에 동의합니다." />
        </FormProvider>
      </DialogContent>
      <DialogActions sx={{p: '12px'}}>
        <Button
          variant="text"
          onClick={handleOnClose}
          sx={{fontSize: 14, color: '#428bff', fontWeight: 'normal'}}>
          취소
        </Button>
        <Button
          variant="text"
          onClick={handleOnConfirmed}
          autoFocus
          disabled={!agreement}
          sx={{fontSize: 14, color: '#428bff', fontWeight: 'bold'}}>
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default InvalidateConfirm;
