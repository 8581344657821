import axios from 'axios';
import Cookie from 'js-cookie';

const NAMESPACE = 'fbevents';

export default function FbeventsPlugin(userConfig) {
  // return object for analytics to use
  return {
    name: NAMESPACE,
    /* Everything else below this is optional depending on your plugin requirements */
    config: {
      trackingId: userConfig.trackingId,
    },
    initialize({config}) {
      console.log('fbevents.initialize', config);

      // eslint-disable-next-line no-unused-expressions
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

      window.fbq('init', config.trackingId);
      window.fbq('track', 'PageView');
    },
    page() {
      console.log('_fbp: ', Cookie.get('_fbp'));
      console.log('_fbc: ', Cookie.get('_fbc'));
      console.log('_fbp.timestamp: ', `${Cookie.get('_fbp')}.${new Date().getTime()}`);

      return setTimeout(() => {
        const fbp = Cookie.get('_fbp'),
          fbc = Cookie.get('_fbc'),
          eventId = `${fbp}.${new Date().getTime()}`;

        if (!fbp) return;

        window.fbq('trackCustom', 'PageView', {}, {eventID: eventId});

        return axios
          .post('/api/log/track', {
            fbp,
            fbc,
            eventId,
            eventName: 'PageView',
            eventSourceUrl: window.location.href,
          })
          .catch(console.warn);
      }, 1);
    },
    track({payload}) {
      return setTimeout(() => {
        const fbp = Cookie.get('_fbp'),
          fbc = Cookie.get('_fbc'),
          eventId = `${fbp}.${new Date().getTime()}`;

        if (!fbp) return;

        const event = payload.event === 'purchase' ? 'Purchase' : payload.event;

        window.fbq('trackCustom', event, {...payload.properties}, {eventID: eventId});

        return axios
          .post('/api/log/track', {
            fbp,
            fbc,
            eventId,
            eventName: event,
            customData: payload.properties,
            eventSourceUrl: window.location.href,
          })
          .catch(console.warn);
      }, 1);
    },
    identify() {
      return null;
    },
    loaded() {
      return !!(window.fbq && Cookie.get('_fbp'));
    },
  };
}
