import React from 'react';
import {fetchAndActivate, getValue} from '@firebase/remote-config';
import {remoteConfig} from '../../../initializeSystem';

export const CatchConfig = (key: string) => {
  const [configValue, setConfigValue] = React.useState('');
  React.useEffect(() => {
    fetchAndActivate(remoteConfig).then(() => {
      setConfigValue(getValue(remoteConfig, key).asString());
    });
  }, []);
  return configValue;
};
