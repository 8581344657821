import dayjs from 'dayjs';
import {atom, AtomEffect, DefaultValue, selector} from 'recoil';

import type * as Apis from '@un7qi3/types/apis';
import {isIab} from 'features/core/iframe';

export type CurrentUserProfileStateValue = {
  name: string;
  gender: string;
  birthday: string;
  birthtime: string;
  calendar: string;
  ampm_unsure: string;
  city?: Apis.CityProps;
  locationId: number;
  locationConfirmed: boolean;
  newbie: boolean;
  editable: boolean;
};

export interface AuthUserProps extends Apis.UserInfoProps {
  profile?: Apis.ProfileProps;
}

export const waitForAuthenticationState = atom<boolean>({
  key: 'auth/waitForAuthenticationState',
  default: true,
});

export const browserTokenState = atom<string | null>({
  key: 'auth/browserTokenState',
  default: null,
  effects_UNSTABLE: [localStorageEffect('browser_token')],
});

export const satellizerTokenState = atom<string | null>({
  key: 'auth/satellizerTokenState',
  default: null,
  effects_UNSTABLE: [localStorageEffect('satellizer_token')],
});

export const refreshTokenState = atom<string | null>({
  key: 'auth/refreshTokenState',
  default: null,
  effects_UNSTABLE: [localStorageEffect('refresh_token')],
});

export const currentUserState = atom<AuthUserProps | null>({
  key: 'auth/currentUser',
  default: null,
});

export const termsModalState = atom({
  key: 'auth/termsModal',
  default: false,
});

// TODO
export const currentUserProfileState = selector<CurrentUserProfileStateValue>({
  key: 'auth/currentUserProfile',
  get({get}) {
    const authUser = get(currentUserState);

    let outcome: CurrentUserProfileStateValue = {
      name: '',
      gender: '',
      birthday: '',
      birthtime: '',
      calendar: '',
      ampm_unsure: '',
      city: undefined,
      locationId: 1847966,
      locationConfirmed: false,
      newbie: false,
      editable: false,
    };

    // 결과값 존재여부
    if (authUser && authUser.profile) {
      const {
        name,
        gender,
        calendar,
        editable,
        locationId,
        locationConfirmed,
        city,
        year,
        month,
        day,
        hour,
        min,
        hmUnsure,
      } = authUser.profile;

      outcome.name = name;
      outcome.gender = gender;
      outcome.calendar = calendar;
      outcome.editable = editable;

      if (locationId) {
        outcome.locationId = locationId;
      }

      outcome.locationConfirmed = locationConfirmed === true;

      if (city) {
        outcome.city = city;
      }

      const birthday = dayjs(`${year}-${month}-${day}`);

      if (birthday.isValid()) {
        outcome.birthday = birthday.format('YYYYMMDD');
      }

      if (hmUnsure) {
        outcome.ampm_unsure = 'unsure';
      } else {
        const birthtime = dayjs(`1970-01-01 ${hour}:${min}`, 'YYYY-MM-DD HH:mm');

        if (birthtime.isValid()) {
          outcome.ampm_unsure = birthtime.locale('en').format('a');
          outcome.birthtime = birthtime.format('hh:mm');
        }
      }
    } else if (authUser && !authUser.profile) {
      outcome.newbie = true;
      outcome.editable = true;
      // 신규사용자
    }

    return outcome;
  },
});

function localStorageEffect(key: string): AtomEffect<any> {
  return ({setSelf, onSet}) => {
    const current = window.localStorage.getItem(key);
    if (current !== null) setSelf(current);

    if (!isIab()) {
      onSet((newValue) => {
        if (newValue === null || newValue === undefined || newValue instanceof DefaultValue) {
          window.localStorage.removeItem(key);
        } else {
          window.localStorage.setItem(key, String(newValue));
        }
      });
    }
  };
}
