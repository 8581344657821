import React from 'react';
import {Outlet, Route, Routes, Navigate} from 'react-router-dom';

import {DrawerLayout} from 'containers/Layout';
import {PremiumPurchase} from 'containers/Premium';

import LazyLoad from 'features/core/components/LazyLoad';
import AuthGuard from 'features/auth/AuthGuard';

import PageIndex from './pages/index';
import AuthStateChangedController from 'features/auth/AuthStateChangedController';
import GuestPurchaseController from 'features/purchase/GuestPurchaseController';
import {ModalExperiment} from '../components/Test/experiments/ModalExperiment';

export default React.memo(() => {
  return (
    <React.Fragment>
      <DrawerLayout>
        <PageIndex />

        <Routes>
          <Route path="/" element={null} />
          <Route
            path="item/:uid"
            element={<LazyLoad factory={() => import('./pages/item/[uid]')} />}
          />
          <Route
            path="calc/:uid"
            element={<LazyLoad factory={() => import('./pages/calc/[uid]')} />}
          />
          <Route
            path="r/:resultKey"
            element={<LazyLoad factory={() => import('./pages/r/[resultKey]')} />}
          />
          <Route
            path="story/:uid"
            element={<LazyLoad factory={() => import('./pages/story/[uid]')} />}
          />

          {/* 프리미엄 */}
          <Route path="premium" element={<Outlet />}>
            <Route
              path=":uid"
              element={<LazyLoad factory={() => import('./pages/premium/[uid]')} />}
            />
            <Route
              path="theme/:uid"
              element={<LazyLoad factory={() => import('./pages/premium/theme/[uid]')} />}
            />
          </Route>

          {/* 연예인 궁합 */}
          <Route path="celeb" element={<Outlet />}>
            <Route index element={<LazyLoad factory={() => import('./pages/celeb')} />} />
            <Route
              path="theme/:uid"
              element={<LazyLoad factory={() => import('./pages/celeb/theme/[uid]')} />}
            />
          </Route>

          {/* 친구 */}
          <Route
            path="invitation/:key"
            element={<LazyLoad factory={() => import('./pages/invitation')} />}
          />
          <Route path="/friend" element={<Outlet />}>
            <Route
              path="calc/:uid"
              element={<LazyLoad factory={() => import('./pages/friend/calc/[uid]')} />}
            />
            <Route
              path="result/:key"
              element={<LazyLoad factory={() => import('./pages/friend/result/[key]')} />}
            />
          </Route>

          {/*  */}
          <Route path="signin" element={<LazyLoad factory={() => import('./pages/signin')} />} />
          <Route path="signup" element={<LazyLoad factory={() => import('./pages/signup')} />} />
          <Route
            path="profile"
            element={
              <AuthGuard>
                <LazyLoad factory={() => import('./pages/profile')} />
              </AuthGuard>
            }
          />
          <Route
            path="fatebook"
            element={
              <AuthGuard>
                <LazyLoad factory={() => import('./pages/fatebook')} />
              </AuthGuard>
            }
          />

          <Route path="force" element={<Outlet />}>
            <Route
              path="history/*"
              element={<LazyLoad factory={() => import('./pages/force/history')} />}
            />
            <Route
              path="coupon"
              element={
                <AuthGuard>
                  <LazyLoad factory={() => import('./pages/force/coupon')} />
                </AuthGuard>
              }
            />
            <Route
              path="charge"
              element={
                <AuthGuard>
                  <LazyLoad factory={() => import('./pages/force/charge')} />
                </AuthGuard>
              }
            />
            <Route
              path="charge/payment/*"
              element={
                <AuthGuard>
                  <LazyLoad factory={() => import('./pages/force/charge/payment')} />
                </AuthGuard>
              }
            />
          </Route>

          <Route
            path="theme/:uid"
            element={<LazyLoad factory={() => import('./pages/theme/[uid]')} />}
          />

          <Route path="help" element={<LazyLoad factory={() => import('./pages/help')} />} />
          <Route
            path="policies/:uid"
            element={<LazyLoad factory={() => import('./pages/policies')} />}
          />
          <Route path="notice" element={<Outlet />}>
            <Route index element={<LazyLoad factory={() => import('./pages/notice')} />} />
            <Route
              path=":uid"
              element={<LazyLoad factory={() => import('./pages/notice/[uid]')} />}
            />
          </Route>
          <Route
            path="leaflets"
            element={<LazyLoad factory={() => import('./pages/leaflets')} />}
          />
          <Route
            path="page/:uid"
            element={<LazyLoad factory={() => import('./pages/page/[uid]')} />}
          />

          <Route path="signIn/:provider" />

          <Route path="guest/pay">
            <Route index element={<LazyLoad factory={() => import('./pages/guest/pay')} />} />
          </Route>

          {/* 결제관련 */}
          <Route path="payments" element={<Outlet />}>
            <Route
              path="request"
              element={<LazyLoad factory={() => import('./pages/payments/request')} />}
            />
            <Route
              path="callback"
              element={<LazyLoad factory={() => import('./pages/payments/callback')} />}
            />
          </Route>

          <Route
            path="vouchers/:uid"
            element={<LazyLoad factory={() => import('./pages/vouchers/fatebook')} />}
          />

          <Route
            path="present/:uid"
            element={<LazyLoad factory={() => import('./pages/present')} />}
          />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </DrawerLayout>

      <PremiumPurchase />
      <AuthStateChangedController />
      <GuestPurchaseController />
    </React.Fragment>
  );
});
