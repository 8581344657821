import React from 'react';
import {Link} from 'react-router-dom';

import clsx from 'clsx';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import toNumberWithComma from '@un7qi3/utils/toNumberWithComma';
import FontAwesomeIcon from '@un7qi3-ui/react/icons/FontAwesome';

const useStyles = makeStyles({
  reaction: {
    height: 48,
    borderBottom: '1px solid #ededed',
    marginBottom: 20,
  },
  icon: {
    marginRight: 8,
  },
  left: {
    fontSize: 12,
    '& p': {
      color: '#5e5e5e',
    },
    '& span': {
      color: '#ee8a8f',
    },
  },
  right: {
    fontSize: 14,
    color: '#428bff',
  },
  arrowIcon: {
    marginLeft: 7,
    marginRight: 0,
  },
});

export interface PremiumInfoBarProps {
  count: number;
  purchased: boolean;
}

export const PremiumInfoBar: React.VFC<PremiumInfoBarProps> = ({count, purchased}) => {
  const classes = useStyles();
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.reaction}>
      <Stack direction="row" alignItems="center" className={classes.left}>
        <FontAwesomeIcon
          className={clsx('fas', 'fa-heart', classes.icon)}
          sx={{color: '#ee8a8f'}}
        />
        <Typography component="p" sx={{fontSize: 12, marginBottom: 1}}>
          <Typography variant="caption">{toNumberWithComma(count || 0)}명</Typography>이 복채를
          냈습니다
        </Typography>
      </Stack>
      {purchased && (
        <Link data-event-action="보유 중 터치" data-event-action-type="navigate" to="/fatebook">
          <Stack direction="row" alignItems="center" className={classes.right}>
            <FontAwesomeIcon className={clsx('fal', 'fa-book', classes.icon)} />
            <Typography component="p" sx={{fontSize: 14, marginBottom: 1}}>
              보유 중
            </Typography>
            <FontAwesomeIcon
              className={clsx('fal', 'fa-arrow-right', classes.icon, classes.arrowIcon)}
            />
          </Stack>
        </Link>
      )}
    </Stack>
  );
};
