import React from 'react';
import {useLocation} from 'react-router-dom';

import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import AppBarBase, {AppBarProps} from '@mui/material/AppBar';
import {experimentalStyled as styled} from '@mui/material/styles';
import {useSetRecoilState} from 'recoil';
import DrawerMenuOpener from '../DrawerMenu/Opener';
import AppIcon from '@un7qi3-ui/react/icons/App';
import {MainTags} from 'containers/Tag';
import {KeywordProps} from '@un7qi3/types/apis';
import {KeywordState} from 'hooks/states/keyword';

export interface StyledProps {
  multiple: boolean;
}

const AppBarFixedSpacer = styled('div')<StyledProps>(({theme, multiple}) => ({
  width: '100%',
  height: multiple ? Number(theme.mixins.toolbar.minHeight) * 2 : theme.mixins.toolbar.minHeight,
}));

const AppBar = React.memo<AppBarProps>((props) => {
  const setKeyword = useSetRecoilState(KeywordState);
  const pathname = useLocation().pathname;
  const [visible, setVisible] = React.useState(false);

  const handleTagSelected = (value?: KeywordProps) => {
    setKeyword(value);
  };

  React.useEffect(() => {
    setVisible(() => pathname === '/');
  }, [pathname]);

  return (
    <React.Fragment>
      <AppBarBase color="inherit" elevation={0} {...props}>
        <Toolbar disableGutters>
          <Container sx={{padding: '0 12px 0 20px'}}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Link href="/" sx={{display: 'block', height: 18}} data-analytics-action="BI 터치">
                <img
                  src="https://static.forceteller.com/www/images/forceteller_word_mark.png"
                  alt="포스텔러 로고 이미지"
                  style={{width: 'auto', height: 22}}
                />
              </Link>
              <Stack direction="row" alignItems="center">
                <DrawerMenuOpener />
              </Stack>
            </Stack>
          </Container>
        </Toolbar>
        <Container>
          <MainTags hidden={!visible} tagSelected={handleTagSelected} />
        </Container>
      </AppBarBase>
      <AppBarFixedSpacer multiple={!!visible} />
    </React.Fragment>
  );
});

export default AppBar;
