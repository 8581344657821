import React from 'react';
import {useSetRecoilState} from 'recoil';

import IconButton from '@mui/material/IconButton';
import FontAwesomeIcon from '@un7qi3-ui/react/icons/FontAwesome';

import {DrawerMenuOpenState} from 'hooks/states/appearance';

const DrawerMenuOpener: React.FC = () => {
  const setDrawerMenuOpenState = useSetRecoilState(DrawerMenuOpenState);

  const handleOnClick = () => {
    requestAnimationFrame(() => setDrawerMenuOpenState(true));
  };

  return (
    <IconButton
      onClick={handleOnClick}
      sx={{color: 'text.primary'}}
      data-analytics-action="사이드 네비게이션 열기"
      size="large">
      <FontAwesomeIcon className="fal fa-bars" fontSize={18} />
    </IconButton>
  );
};

export default DrawerMenuOpener;
