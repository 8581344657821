export const QR_CODE = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width="150"
    height="150"
    viewBox="0 0 984 984">
    <defs>
      <mask id="qrmask">
        <g fill="#ffffff">
          <g>
            <g transform="translate(240,48) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,48) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(432,48) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,48) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,48) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,48) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,48) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,48) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,48) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,72) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,72) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,72) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(384,72) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,72) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(432,72) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(528,72) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,72) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(600,72) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,72) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,72) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,72) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(696,72) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,72) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,96) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,96) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(312,96) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,96) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(384,96) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,96) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,96) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(528,96) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,96) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,96) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(600,96) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,96) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,96) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(696,96) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,96) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,120) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(312,120) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,120) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,120) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(384,120) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(432,120) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,120) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,120) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(528,120) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,120) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,120) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(696,120) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,144) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,144) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,144) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(384,144) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,144) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,144) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,144) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,144) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,144) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,168) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,168) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,168) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(384,168) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,168) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,168) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,168) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,168) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,168) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,168) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,168) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(696,168) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,168) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,192) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,192) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,192) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(384,192) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(432,192) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,192) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(528,192) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,192) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,192) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,192) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,192) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,216) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,216) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(384,216) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,216) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,216) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,216) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,216) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(600,216) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,216) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,216) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(96,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(120,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(144,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(192,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(744,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(768,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(792,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(864,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(888,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(912,240) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(48,264) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(72,264) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(96,264) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(144,264) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(168,264) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,264) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,264) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,264) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(600,264) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,264) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,264) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(696,264) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(792,264) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(864,264) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(888,264) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(48,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(72,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(120,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(168,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(192,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(216,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(528,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(696,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(768,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(792,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(816,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(864,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(888,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(912,288) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(48,312) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(72,312) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(96,312) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(144,312) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(168,312) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(216,312) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,312) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,312) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,312) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(432,312) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,312) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,312) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,312) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,312) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(768,312) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(912,312) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(48,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(96,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(120,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(168,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(192,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(216,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(312,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(384,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(528,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(600,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(696,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(744,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(768,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(816,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(864,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(912,336) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(72,360) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(96,360) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(120,360) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(144,360) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(216,360) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,360) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,360) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(312,360) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,360) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,360) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,360) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,360) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,360) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(744,360) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(792,360) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(840,360) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(864,360) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(48,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(72,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(96,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(144,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(168,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(192,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(216,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(312,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(600,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(696,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(744,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(768,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(840,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(888,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(912,384) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(48,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(96,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(144,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(216,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(384,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(432,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(528,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(600,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(744,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(768,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(792,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(816,408) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(48,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(72,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(120,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(192,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(312,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(384,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(744,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(768,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(816,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(864,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(912,432) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(72,456) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(216,456) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,456) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(384,456) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(528,456) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,456) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,456) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,456) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(792,456) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(840,456) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(72,480) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(96,480) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(144,480) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(192,480) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,480) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,480) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,480) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,480) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,480) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,480) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,480) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(696,480) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(864,480) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(888,480) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(912,480) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(48,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(72,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(96,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(312,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(432,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(600,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(696,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(744,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(768,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(792,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(912,504) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(48,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(72,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(96,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(168,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(192,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(216,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(432,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(600,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(744,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(768,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(792,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(816,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(840,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(864,528) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(48,552) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(72,552) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(96,552) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(168,552) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,552) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,552) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(312,552) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,552) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,552) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,552) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(528,552) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,552) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(600,552) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,552) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,552) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(792,552) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(840,552) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(72,576) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(120,576) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(144,576) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(192,576) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(312,576) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,576) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(432,576) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,576) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,576) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,576) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(600,576) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,576) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(696,576) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(768,576) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(840,576) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(888,576) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(912,576) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(48,600) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(216,600) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,600) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,600) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,600) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,600) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,600) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(528,600) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,600) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,600) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,600) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,600) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(744,600) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(792,600) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(840,600) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(72,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(96,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(120,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(144,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(192,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(384,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(432,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(528,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(600,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(744,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(768,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(792,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(816,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(840,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(864,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(912,624) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(48,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(72,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(216,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(432,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(744,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(840,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(864,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(888,648) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(48,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(144,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(192,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(312,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(432,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(696,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(768,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(792,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(816,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(840,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(864,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(888,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(912,672) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(48,696) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(120,696) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(168,696) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(216,696) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,696) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,696) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(312,696) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,696) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,696) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,696) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(528,696) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,696) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,696) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(744,696) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(888,696) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(912,696) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(48,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(120,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(192,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(216,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(312,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(432,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(528,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(696,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(744,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(768,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(792,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(816,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(912,720) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,744) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,744) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,744) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(384,744) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,744) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(432,744) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,744) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,744) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,744) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(696,744) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,744) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(816,744) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(888,744) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,768) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,768) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,768) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(432,768) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,768) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,768) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,768) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,768) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,768) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,768) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,768) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(768,768) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(816,768) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(840,768) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(888,768) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(912,768) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(312,792) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,792) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(384,792) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,792) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(432,792) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,792) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,792) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,792) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,792) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,792) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(816,792) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(888,792) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(312,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(600,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(696,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(744,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(768,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(792,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(816,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(864,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(888,816) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,840) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,840) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(360,840) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,840) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,840) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,840) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,840) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(576,840) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(696,840) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,840) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(768,840) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(816,840) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(240,864) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,864) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,864) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,864) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,864) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(432,864) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,864) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(528,864) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,864) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(600,864) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(624,864) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,864) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,864) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,864) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(744,864) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(792,864) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(912,864) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,888) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,888) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(312,888) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,888) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(384,888) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,888) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,888) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(480,888) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,888) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(600,888) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(696,888) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(720,888) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(744,888) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(816,888) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(912,888) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(264,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(288,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(312,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(336,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(408,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(456,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(504,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(528,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(552,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(600,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(648,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(672,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(768,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(792,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(816,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(864,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(888,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
            <g transform="translate(912,912) scale(4.12)">
              <rect width="6" height="6" />
            </g>
          </g>
        </g>
      </mask>
    </defs>
    <rect width="984" height="984" fill="#ffffff" x="0" y="0" />
    <rect x="0" y="0" width="984" height="984" mask="url(#qrmask)" fill="#000000" />
    <g>
      <g transform="translate(48,48)" fill="#000000">
        <g transform="scale(12)">
          <path d="M0,0v14h14V0H0z M12,12H2V2h10V12z" />
        </g>
      </g>
      <g transform="translate(96,96)" fill="#000000">
        <g transform="scale(12)">
          <rect width="6" height="6" />
        </g>
      </g>
      <g transform="translate(768,48)" fill="#000000">
        <g transform="scale(12)">
          <path d="M0,0v14h14V0H0z M12,12H2V2h10V12z" />
        </g>
      </g>
      <g transform="translate(816,96)" fill="#000000">
        <g transform="scale(12)">
          <rect width="6" height="6" />
        </g>
      </g>
      <g transform="translate(48,768)" fill="#000000">
        <g transform="scale(12)">
          <path d="M0,0v14h14V0H0z M12,12H2V2h10V12z" />
        </g>
      </g>
      <g transform="translate(96,816)" fill="#000000">
        <g transform="scale(12)">
          <rect width="6" height="6" />
        </g>
      </g>
    </g>
  </svg>
);
