import {atom} from 'recoil';
import {SnackbarProps} from '@mui/material';

export interface SnackbarStateProps {
  message: string | undefined;
  options?: SnackbarProps;
}
export const SnackbarState = atom<SnackbarStateProps>({
  key: 'SnackbarState',
  default: {message: undefined},
});
