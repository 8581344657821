import React from 'react';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import clsx from 'clsx';
import {useMutation, useQuery} from 'react-query';
import * as Apis from '@un7qi3/types/apis';
import {Variables} from '@un7qi3/types/apis';
import {Transition} from 'components/Util/Transition';
import {useLocation, useNavigate} from 'react-router-dom';
import PriceTag from '@un7qi3-ui/react/PriceTag';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {PremiumBuyLinkState, PremiumBuyModalState} from 'hooks/states/buy';
import useSnackbar from 'hooks/useSnackbar';
import useDialog from 'hooks/useDialog';
import FontAwesomeIcon from '@un7qi3-ui/react/icons/FontAwesome';
import {AxiosResponse} from 'axios';

import Analytics from '~/plugins/tracker/analytics';

const useStyles = makeStyles({
  root: {position: 'fixed', bottom: '0', right: '0', left: '0'},
  body: {
    position: 'relative',
    minHeight: 130,
    backgroundColor: 'white',
    '&::before': {
      position: 'absolute',
      top: '-4px',
      display: 'block',
      content: '""',
      backgroundImage: 'url(https://static.forceteller.com/images/gift/border_receipt.png)',
      backgroundSize: '12px auto',
      backgroundPosition: 'top left',
      width: '100%',
      height: '4px',
      zIndex: '-1',
    },
  },
  inner: {padding: '28px 20px', maxWidth: '600px', margin: 'auto'},
  purchased: {
    marginBottom: '24px',
    animationDelay: '.5s',
    animation: 'blink 1s ease-in-out',
    animationIterationCount: '3',
  },
  price: {fontWeight: 'bold', color: '#d7990b'},
  btns: {
    display: 'flex',
    borderTop: '1px solid #ededed',
    width: '100%',
    overflow: 'hidden',
    borderRadius: 0,
    boxShadow: 'none',
  },
  button: {
    margin: '0',
    flex: '1',
    height: '52px',
    color: '#191919',
    background: '#fff',
    borderRadius: 0,
    fontWeight: 'normal',
    '&:hover': {
      background: '#fff',
    },
    '& span': {
      fontSize: '16px',
    },
    '& + button': {
      background: '#191919',
      color: '#fff',
      fontWeight: 'bold',
      '&:hover': {
        background: '#191919',
      },
    },
  },
  detail: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
  },
  pTitle: {fontSize: 16, fontWeight: 'bold'},
  pSubtitle: {fontSize: 14, fontWeight: 'normal'},
  pTag: {
    position: 'relative',
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    background: '#E85E5E',
    fontSize: '11px',
    fontWeight: 'bold',
    color: 'white',
    height: 16,
    marginLeft: 16,
    paddingRight: 4,
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      left: -16,
      border: '8px solid',
      borderColor: 'transparent #E85E5E transparent transparent',
    },
  },
  exclamation: {
    marginRight: 7,
    fontSize: 14,
    color: '#E85E5E',
  },
});

export const PremiumPurchase = React.memo(() => {
  const state = useRecoilValue(PremiumBuyModalState);
  const setLink = useSetRecoilState(PremiumBuyLinkState);
  const snackbar = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const dialog = useDialog();
  const location = useLocation();
  React.useEffect(() => {
    state && !state.buy && navigate(state.value);
  }, [state]);

  React.useEffect(() => {
    setLink(undefined);
  }, [location]);

  const {data: premium} = useQuery<Apis.Response<Apis.PremiumProps>>(
    ['/api/premium/:uid', state?.value],
    {
      enabled: !!(state && state.buy),
      useErrorBoundary: false,
    },
  );
  const {data: price} = useQuery<Apis.Response<Apis.PriceProps>, AxiosResponse>(
    ['/api/premium/:uid/price', state?.value],
    {
      enabled: !!(state && state.buy),
      useErrorBoundary: false,
      onError: (err) => {
        if (err.status === 401) {
          setLink(undefined);
          navigate(`/signin?redirect_uri=${window.location.pathname}`, {
            replace: true,
          });
        }
      },
    },
  );

  // 트래킹
  const trackEventLabel = `[premium ${premium?.data.id}] ${premium?.data.title}`;

  const mutation = useMutation<Apis.Response<Apis.PremiumItemBill>, any, Variables>({
    onSuccess: (data) => {
      setLink(undefined);
      if (data.status === 200) {
        setTimeout(
          () =>
            Analytics.track('프리미엄 구매 성공', {
              category: '포스 구매',
              label: trackEventLabel,
            }),
          1,
        );

        snackbar.show('구매해주셔서 감사합니다.');
        navigate('/fatebook');
      }
    },
    onError: (error) => {
      if (error.data.data.reason === 'insufficient') {
        dialog.show({
          onConfirm: () => {
            dialog.close();
            setLink(undefined);
            navigate('/force/charge');
          },
          title: '포스가 부족합니다',
          content: `${error.data.data.amount}포스가 더 필요합니다.\n지금 포스를 충전하시겠어요?`,
          confirm: '네',
          cancel: '아니요',
        });
      } else {
        snackbar.show('구매 과정에서 오류가 발생했습니다. 잠시 후 다시 이용해주세요.');
      }
    },
  });

  if (!(state && state.buy)) {
    return null;
  }

  const tryBill = () => {
    if (premium?.data.purchased) {
      dialog.show({
        onConfirm: () => {
          setTimeout(
            () =>
              Analytics.track('새로 구매하기', {
                category: '프리미엄 구매 모달',
                label: trackEventLabel,
              }),
            1,
          );
          dialog.close();
          buy();
        },
        title: premium?.data.title,
        content: '보유 중인 운세 입니다.',
        confirm: '새로 구매하기',
        cancel: '취소',
      });
    } else {
      buy();
    }
  };

  const buy = () => {
    mutation.mutate({url: `/api/premium/${state?.value}/bill`});
  };

  if (price && price.data) {
    return (
      <>
        <Dialog open={!!state?.value} TransitionComponent={Transition}>
          <Paper elevation={0} className={classes.root}>
            <Box className={classes.body}>
              <Box className={classes.inner}>
                {premium?.data.purchased && (
                  <Stack direction="row" alignItems="center" className={classes.purchased}>
                    <FontAwesomeIcon
                      className={clsx('fal', 'fa-exclamation-circle', classes.exclamation)}
                    />
                    <Typography sx={{fontSize: 12, color: '#E85E5E', marginBottom: 1}}>
                      보유 중인 운세입니다.
                    </Typography>
                  </Stack>
                )}
                {price.data.priceDetails && (
                  <>
                    <Typography variant="h3" color="191919">
                      결제 금액
                    </Typography>
                    <List component="ul" sx={{marginTop: '4px'}}>
                      {price.data.priceDetails.map((detail, index) => (
                        <ListItem className={classes.detail} key={index}>
                          <Stack direction="row" alignItems="center">
                            <Typography
                              variant="inherit"
                              className={clsx({
                                [classes.pTitle]: index === price.data.priceDetails.length - 1,
                                [classes.pSubtitle]: index !== price.data.priceDetails.length - 1,
                              })}>
                              {detail.title}
                            </Typography>
                            {detail.tag && (
                              <Typography variant="inherit" className={classes.pTag}>
                                {detail.tag}
                              </Typography>
                            )}
                          </Stack>
                          {index !== price.data.priceDetails.length - 1 && (
                            <Typography
                              variant="inherit"
                              className={classes.pSubtitle}
                              color={detail.priceColor}>
                              {detail.price}
                            </Typography>
                          )}
                          {index === price.data.priceDetails.length - 1 && (
                            <PriceTag type="price" amount={detail.price} size="lg" />
                          )}
                        </ListItem>
                      ))}
                    </List>
                  </>
                )}
                {!price.data.priceDetails && (
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h3" color="191919">
                      결제 금액
                    </Typography>
                    <PriceTag
                      type="price"
                      amount={price.data.price}
                      className={classes.price}
                      size="xl"
                    />
                  </Stack>
                )}
              </Box>
              <ButtonGroup variant="contained" fullWidth className={classes.btns}>
                <Button
                  className={classes.button}
                  sx={{border: 'none'}}
                  onClick={() => setLink(undefined)}>
                  취소
                </Button>
                <Button
                  className={classes.button}
                  data-event-action="구매하기"
                  data-event-category="프리미엄 구매 모달"
                  data-event-label={trackEventLabel}
                  onClick={tryBill}>
                  구매하기
                </Button>
              </ButtonGroup>
            </Box>
          </Paper>
        </Dialog>
      </>
    );
  }
  return null;
});
