import React, {ComponentType, SuspenseProps} from 'react';

export interface LazyLoadProps extends Partial<Pick<SuspenseProps, 'fallback'>> {
  factory?: () => Promise<{default: ComponentType<any>}>;
  [key: string]: any;
}

const LazyLoad: React.FC<LazyLoadProps> = ({factory, fallback = null, ...otherProps}) => {
  const Component = React.useMemo(() => {
    if (factory) {
      return React.lazy(factory);
    }
    return () => null;
  }, [factory]);

  return (
    <React.Suspense fallback={fallback}>
      <Component {...otherProps} />
    </React.Suspense>
  );
};

export default LazyLoad;
