import React from 'react';
import {SxProps} from '@mui/system';
import {experimentalStyled as styled} from '@mui/material/styles';

import './fontawesome.scss';

interface FontAwesomeStyleProps {
  fontSize?: number;
}

export interface FontAwesomeProps extends React.ComponentPropsWithRef<'i'>, FontAwesomeStyleProps {
  sx?: SxProps;
}

const FontAwesomeRoot = styled('i')<{ownerState: FontAwesomeStyleProps}>(({ownerState}) => ({
  userSelect: 'none',
  display: 'inline-block',
  textAlign: 'center',
  // overflow: 'hidden',

  ...(ownerState.fontSize && {
    width: ownerState.fontSize,
    height: ownerState.fontSize,
    fontSize: ownerState.fontSize,
  }),
}));

const FontAwesome = React.forwardRef<HTMLElement, FontAwesomeProps>(
  ({fontSize, ...otherProps}, ref) => {
    const ownerState = {
      fontSize,
    };

    return <FontAwesomeRoot ref={ref} ownerState={ownerState} {...otherProps} />;
  },
);

export default FontAwesome;
