import React from 'react';
import {useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import DrawerMenu from '../DrawerMenu';
import DrawerMenuItem from '../DrawerMenu/Item';
import DrawerMenuItemDivider from '../DrawerMenu/ItemDivider';
import DrawerMenuProfileCover from '../DrawerMenu/ProfileCover';
import DrawerMenuLinkArea from '../DrawerMenu/LinkArea';

import InvalidateConfirm from '../Confirm/InvalidateConfirm';
import useHeight from 'hooks/useHeight';
import useSnackbar from 'hooks/useSnackbar';
import useAuthenticator from 'features/auth/hooks/useAuthenticator';
import {SideMenuExperiment} from 'components/Test/experiments/SideMenuExperiment';
import {MatchComponents} from 'components/Test/hooks/MatchComponents';

export default React.memo(({children}) => {
  const snackbar = useSnackbar();
  const {clientHeight} = useHeight();
  const {signOut} = useAuthenticator();
  const [searchParams] = useSearchParams();
  const [openInvalidateConfirm, setOpenInvalidateConfirm] = React.useState<boolean>(false);
  return (
    <main id="main">
      {searchParams.get('headless') !== '1' && searchParams.get('iab') !== '1' && (
        <MatchComponents />
      )}
      <DrawerMenu
        ModalProps={{
          keepMounted: true,
        }}>
        <DrawerMenuProfileCover />
        <SideMenuExperiment />
        <DrawerMenuItemDivider />
        <DrawerMenuItem href="/">홈</DrawerMenuItem>
        <DrawerMenuItem requireAuth href="/profile">
          내 정보 수정
        </DrawerMenuItem>
        <DrawerMenuItem requireAuth href="/fatebook">
          마이 페이트북
        </DrawerMenuItem>
        <DrawerMenuItemDivider />
        <DrawerMenuItem href="/force/coupon">쿠폰 등록</DrawerMenuItem>
        <DrawerMenuItem href="/force/charge">포스 충전소</DrawerMenuItem>
        <DrawerMenuItem requireAuth href="/force/history">
          내 포스 내역
        </DrawerMenuItem>
        <DrawerMenuItemDivider />
        <DrawerMenuItem href="/leaflets">이벤트</DrawerMenuItem>
        <DrawerMenuItem href="/notice">공지사항</DrawerMenuItem>
        <DrawerMenuItem href="/help">고객센터</DrawerMenuItem>
        <DrawerMenuItemDivider requireAuth />
        <DrawerMenuItem
          requireAuth
          onClick={(e) => {
            return signOut().then(() => {
              snackbar.show('로그아웃 되었습니다.');
            });
          }}>
          로그아웃
        </DrawerMenuItem>
        <DrawerMenuItem requireAuth onClick={(e) => setOpenInvalidateConfirm(true)}>
          계정 삭제
        </DrawerMenuItem>

        <Box
          sx={{
            position: clientHeight >= 800 ? 'absolute' : 'relative',
            bottom: 0,
            padding: '20px',
          }}>
          <Stack gap="4px" sx={{maxWidth: '180px', marginBottom: '20px', color: '#aaa'}}>
            <Typography sx={{fontSize: 10}}>
              <strong>(주)운칠기삼</strong> 대표 김상현, 심경진
            </Typography>
            <Typography sx={{fontSize: 10}}>
              <strong>사업자등록번호</strong> 249-86-00725
            </Typography>
            <Typography sx={{fontSize: 10}}>
              <strong>주소</strong> 경기도 성남시 분당구 판교역로 180 601호, 602호, 603호 (삼평동,
              알파타워)
            </Typography>
            <Typography sx={{fontSize: 10}}>
              <strong>전화번호</strong> 031-706-3830
            </Typography>
          </Stack>
          <Stack
            direction="row"
            sx={{fontSize: 10, fontWeight: 500, color: '#5e5e5e', marginBottom: '14px'}}>
            <DrawerMenuLinkArea
              data-event-action="약관 터치"
              data-event-category="사이드 네비게이션"
              data-event-label="이용약관"
              href="/policies/terms">
              이용약관
            </DrawerMenuLinkArea>
            <Divider
              orientation="vertical"
              flexItem
              sx={{margin: '0 12px', borderColor: '#d1d1d1'}}
            />
            <DrawerMenuLinkArea
              data-event-action="약관 터치"
              data-event-category="사이드 네비게이션"
              data-event-label="개인정보처리방침"
              sx={{fontWeight: 700}}
              href="/policies/privacy">
              개인정보처리방침
            </DrawerMenuLinkArea>
          </Stack>
          <Typography sx={{fontSize: 10, color: '#a3a3a3', lineHeight: 1.5}}>
            © 2017 forceteller
          </Typography>
        </Box>
      </DrawerMenu>
      {children}
      <InvalidateConfirm
        open={openInvalidateConfirm}
        onClose={() => setOpenInvalidateConfirm(false)}
      />
    </main>
  );
});
