import {useRecoilCallback} from 'recoil';
import {DialogState, DialogStateProps} from './states';

export default function useDialog() {
  const show = useRecoilCallback(
    ({set}) =>
      ({...options}: Omit<DialogStateProps, 'open'>) => {
        const onClose = typeof options.onClose === 'function' ? options.onClose : () => close();
        set(DialogState, {open: true, onClose, ...options});
      },
    [],
  );
  const close = useRecoilCallback(
    ({set}) =>
      () => {
        set(DialogState, {
          open: false,
          onClose: () => {},
          onConfirm: () => {},
        });
      },
    [],
  );

  return {
    show,
    close,
  };
}
