import React from 'react';
import {SideMenu1} from 'components/Test/side-menu/SideMenu1';
import {SideMenu2} from 'components/Test/side-menu/SideMenu2';
import {CatchConfig} from '../hooks/CatchFixbarConfig';

export const SideMenuExperiment = () => {
  const sideMenuStatus = CatchConfig('test_sidemenu');
  if (sideMenuStatus === '0') {
    return <SideMenu1 />;
  } else if (sideMenuStatus === '1') {
    return <SideMenu2 />;
  }

  return null;
};
