import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import CheckboxBase from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import type {FormControlLabelProps} from '@mui/material/FormControlLabel';
interface FieldTextProps
  extends Omit<FormControlLabelProps, 'name' | 'control' | 'onChange' | 'onBlur'> {
  name: string;
}

const Checkbox: React.FC<FieldTextProps> = ({name, ...otherProps}) => {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field}) => (
        <FormControlLabel control={<CheckboxBase />} {...field} {...otherProps} />
      )}
    />
  );
};

export default Checkbox;
