import React from 'react';

import {IfMatch, IfMatchCase} from '@un7qi3-ui/react/IfMatch';
import LazyLoad from 'features/core/components/LazyLoad';

import type * as Apis from '@un7qi3/types/apis';

type SkinMappingType = Record<'personal_ranking' | 'personal_similar', React.ElementType | null>;

export interface SkinnableProps {
  skin?: keyof SkinMappingType | string;
  skinMapping?: SkinMappingType;
  passProps?: Apis.ThemeProps;
}

const Skinnable: React.FC<SkinnableProps> = ({skin, passProps}) => {
  return (
    <IfMatch condition={skin} passProps={passProps}>
      <IfMatchCase then="personal_ranking">
        <LazyLoad factory={() => import('./personal_ranking')} />
      </IfMatchCase>
      <IfMatchCase then="personal_similar">
        <LazyLoad factory={() => import('./personal_similar')} />
      </IfMatchCase>
    </IfMatch>
  );
};

export default Skinnable;
