import {
  getAuth,
  signOut,
  signInWithCustomToken,
  signInWithRedirect,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from '@firebase/auth';
import qs from 'querystring';
import axios from 'axios';

import AuthTokenService from './AuthTokenService';
import openWindow from '../utils/openWindow';

export default class AuthService {
  /**
   * AuthProviderService.signInWithGoogle();
   */
  public static signInWithGoogle() {
    const provider = new GoogleAuthProvider();

    provider.setCustomParameters({
      prompt: 'select_account',
    });

    return signInWithRedirect(getAuth(), provider);
  }

  public static signInWithFacebook() {
    const provider = new FacebookAuthProvider();
    provider.addScope('email,public_profile');

    return signInWithRedirect(getAuth(), provider);
  }

  public static signInWithApple() {
    const provider = new OAuthProvider('apple.com');

    return signInWithRedirect(getAuth(), provider);
  }

  public static signInWithKakao() {
    const options = {
      response_type: 'code',
      client_id: process.env.REACT_APP_KAKAO_REST_CLIENT_ID,
      redirect_uri: window.location.origin + '/signIn/kakao',
      scope: 'profile',
    };

    return openWindow(
      `https://kauth.kakao.com/oauth/authorize?${qs.stringify(options)}`,
      'width=495,height=645',
    ).then((response: any) => {
      if (response.status === 'matched' && response.search) {
        const query: any = qs.parse(response.search);

        const params = {
          client_id: options.client_id,
          redirect_uri: options.redirect_uri,
          grant_type: 'authorization_code',
          code: query.code,
        };

        return axios
          .get('https://kauth.kakao.com/oauth/token', {params})
          .then((response) => response.data)
          .then(({access_token}: any) => {
            return axios
              .post('/api/token/kakao', {
                access_token,
              })
              .then((response) => response.data)
              .then(({customToken}: any) => signInWithCustomToken(getAuth(), customToken));
          });
      } else {
        return Promise.reject(response);
      }
    });
  }

  public static signInWithNaver() {
    const options = {
      response_type: 'token',
      client_id: process.env.REACT_APP_NAVER_CLIENT_ID,
      redirect_uri: window.location.origin + '/signIn/naver',
      state: 'NAVER_STATE',
    };

    return openWindow(
      `https://nid.naver.com/oauth2.0/authorize?${qs.stringify(options)}`,
      'width=520,height=720',
    ).then((response: any) => {
      if (response.status === 'matched' && response.hash) {
        const authResponse: any = qs.parse(response.hash);

        return axios
          .post('/api/token/naver', {
            access_token: authResponse.access_token,
          })
          .then((response) => response.data)
          .then(({customToken}: any) => {
            console.log(customToken);
            return signInWithCustomToken(getAuth(), customToken);
          });
      } else {
        return Promise.reject(response);
      }
    });
  }

  public static signOut() {
    return signOut(getAuth());
  }

  /**
   * AuthService.getCurrentUserAsync();
   *
   */
  public static getCurrentUserAsync(token: string) {
    const httpConfig = {
      headers: {
        'X-AUTH-TOKEN': token,
      },
    };

    //
    const promises = ['/api/users/me', '/api/users/me/profile'].map((endpoint) => {
      const deferredPromise = axios.get(endpoint, httpConfig);

      return deferredPromise.then(
        (response) => response.data,
        (error) => {
          // 회원가입이 필요한 경우?
          if (error && (error.status === 401 || error.data)) {
            return error.data;
          }

          throw error;
        },
      );
    });

    return Promise.all(promises).then((responses) => {
      const hasErrorResponse = responses.find((r) => r && r.status !== 200);
      // return hasErrorResponse ? hasErrorResponse.status : 200;

      if (hasErrorResponse) {
        return {
          status: hasErrorResponse.status,
          data: {
            ...responses[0].data,
          },
        };
      }

      return {
        status: 200,
        data: {
          ...responses[0].data,
          profile: responses[1].data,
        },
      };
    });
  }

  public static deviceAsync() {
    return axios.get('/api/users/me/device').then((response) => response.data);
  }

  // 회원탈퇴
  public static invalidateAsync() {
    return axios.get('/api/users/me/invalidate').then((response) => response.data);
  }

  // 휴면 해제
  public static reactivateAsync() {
    return axios.get('/api/users/me/reactivate').then((response) => response.data);
  }
}
