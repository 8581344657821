import {toggleButtonClasses} from '@mui/material/ToggleButton';
import {Theme, Components} from '@mui/material/styles';

export default function override(theme: Theme): Components {
  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontWeight: theme.typography.fontWeightMedium,

          [`&.${toggleButtonClasses.sizeSmall}`]: {
            // 사이즈가 작은 경우도 버튼을 그대로 사용.
            ...theme.typography.button,
            padding: '8px 0 11px',
            lineHeight: 18 / 16,
          },

          [`&.${toggleButtonClasses.selected}.${toggleButtonClasses.primary}`]: {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.divider,

            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              // Reset on touch devices, it doesn't add specificity
              '@media (hover: none)': {
                backgroundColor: theme.palette.primary.main,
              },
            },
          },
        },
      },
    },
  };
}
