import React from 'react';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';

interface TagChipProps {
  word: string;
  selected: boolean;
  onClick: React.MouseEventHandler;
  classes?: string;
}

export const TagChip = React.memo<TagChipProps>(
  ({word, selected, onClick, classes, ...otherProps}) => {
    return (
      <div className={clsx(classes, {active: selected})} onClick={onClick} {...otherProps}>
        <Typography sx={{fontWeight: 'inherit'}}>{word}</Typography>
      </div>
    );
  },
);
