// @ts-ignore
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';

export const CenterModalBox = styled(Box)`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 304px;
  top: calc(50% - 304px / 2);
  width: 100%;
  background-color: #ffffff;
  outline: none;
  border-radius: 16px;
  padding: 20px 16px 10px 16px;
  @media screen and (min-width: 360px) {
    width: calc(100% - 80px);
    max-width: 400px;
  }
  @media screen and (min-width: 600px) {
    width: 400px;
  }
`;

export const QrModalBox = styled(Box)`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  top: calc(50% - 304px / 2);
  width: 100%;
  background-color: #ffffff;
  outline: none;
  border-radius: 16px;
  padding: 20px 16px 10px 16px;
  @media screen and (min-width: 360px) {
    width: calc(100% - 80px);
    max-width: 400px;
  }
  @media screen and (min-width: 600px) {
    width: 400px;
  }
`;

export const Bottom1ModalBox = styled(Box)`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 344px;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  outline: none;
  border-radius: 16px 16px 0 0;
  padding: 20px 16px 10px 16px;
  @media screen and (min-width: 600px) {
    max-width: 600px;
  }
`;

export const Bottom2ModalBox = styled(Box)`
  position: absolute;
  line-height: 26px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  height: 240px;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  outline: none;
  border-radius: 16px 16px 0 0;
  padding: 20px 16px 10px 16px;
  @media screen and (min-width: 600px) {
    max-width: 600px;
    align-items: flex-start;
  }
`;

export const ModalIcon = styled('img')`
  width: 48px;
  height: 48px;
  margin-bottom: 10px;
`;

export const BigModalIcon = styled('img')`
  width: 72px;
  height: 72px;
`;

export const ModalDivider = styled('div')(({direction}: any) => ({
  width: '100%',
  flexDirection: direction,
}));

export const DirectionRow = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-align: left;
`;
