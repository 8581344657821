import {checkboxClasses} from '@mui/material/Checkbox';
import {Theme, Components} from '@mui/material/styles';

export default function override(theme: Theme): Components {
  return {
    MuiList: {},
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 42,
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          variant: 'h6',
        },
        secondaryTypographyProps: {
          variant: 'body1',
        },
      },
      styleOverrides: {
        inset: {
          paddingLeft: 42,
        },
      },
    },
  };
}
