import {Theme, Components} from '@mui/material/styles';

export default function override(theme: Theme): Components {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: 0,
          borderRadius: 16,
        },
        paperWidthSm: {
          maxWidth: 375,
        },
        paperFullWidth: {
          maxWidth: 'calc(100vw - 28px)',
        },
        // paperFullWidth: {
        //   maxWidth: 'calc(100vw - 28px)',
        // },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '26px 22px 0',
        },
      },
    },
  };
}
