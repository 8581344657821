import React from 'react';

import Slide, {SlideProps} from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DialogTitleBase from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogBase, {DialogProps} from '@mui/material/Dialog';
import {experimentalStyled as styled} from '@mui/material/styles';

import FontAwesomeIcon from '@un7qi3-ui/react/icons/FontAwesome';

const Transition = React.forwardRef<HTMLDivElement, SlideProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

//
const Dialog = styled(DialogBase)(({theme}) => ({
  top: theme.mixins.toolbar.minHeight,
}));

//
const DialogTitle = styled(DialogTitleBase)(({theme}) => ({
  // top: 0,
  // position: 'sticky',

  padding: 0,
  display: 'flex',
  alignItems: 'center',
  height: theme.mixins.toolbar.minHeight,
  backgroundColor: theme.palette.common.white,
}));

export interface FullScreenModalProps extends DialogProps {
  edgeTopRadiusSize?: number | string;
  edgeBottomRadiusSize?: number | string;
}

const FullScreenModal: React.FC<FullScreenModalProps> = ({
  edgeTopRadiusSize = '12px',
  edgeBottomRadiusSize = 0,
  children,
  onClose,
  ...otherProps
}) => {
  const handleOnClose = (e: React.MouseEvent) =>
    typeof onClose === 'function' && onClose(e, 'backdropClick');

  return (
    <Dialog
      fullWidth
      fullScreen
      scroll="paper"
      onClose={handleOnClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          borderTopLeftRadius: edgeTopRadiusSize,
          borderTopRightRadius: edgeTopRadiusSize,
          borderBottomLeftRadius: edgeBottomRadiusSize,
          borderBottomRightRadius: edgeBottomRadiusSize,
        },
      }}
      {...otherProps}>
      <DialogTitle>
        <Stack flex={1} direction="row" justifyContent="flex-end" sx={{padding: '0 14px'}}>
          <IconButton onClick={handleOnClose} size="large">
            <FontAwesomeIcon className="fal fa-times" fontSize={18} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{p: 0, m: 0}}>{children}</DialogContent>
    </Dialog>
  );
};

export default FullScreenModal;
