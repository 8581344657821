import {Theme, Components} from '@mui/material/styles';

export default function override(theme: Theme): Components {
  return {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'sm',
        disableGutters: true,
      },
    },
  };
}
