import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Stack from '@mui/material/Stack';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import toNumberWithComma from '@un7qi3/utils/toNumberWithComma';
import PriceTag from '@un7qi3-ui/react/PriceTag';
import {useSetRecoilState} from 'recoil';
import {PremiumBuyLinkState} from 'hooks/states/buy';

const useStyles = makeStyles({
  root: {margin: '12px 0'},
  thumbnail: {
    width: 64,
    height: 90,
    marginRight: 20,
    position: 'relative',
    '& img': {width: 64, height: 90, borderRadius: 2},
  },
  noThumb: {
    borderBottom: '1px solid rgba( 25, 25, 25, 0.075 )',
    margin: '0',
    padding: '15px 0',
    '& h5': {
      marginBottom: 6,
    },
  },
  rank: {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 500,
    fontStyle: 'italic',
    marginRight: 12,
    minWidth: 16,
    opacity: 0.4,
  },
  subTitle: {
    lineHeight: 1.3,
    marginBottom: 4,
    display: 'inline-block',
    fontSize: 12,
    color: '#a3a3a3',
  },
  title: {marginBottom: 4, fontSize: 14},
  priceTag: {
    margin: '4px 12px 4px 0',
  },
  saleTag: {
    background: '#E85E5E',
    fontSize: 10,
    padding: '0 3px',
    lineHeight: '14px',
    color: '#fff',
    fontWeight: 500,
    borderRadius: 3,
    marginLeft: 6,
  },
  likeCount: {color: '#ee8a8f', fontSize: 11, margin: '8px 0'},
  count: {marginBottom: 0, marginLeft: 4},
  textPrice: {margin: '0 12px 0 0'},
  textCount: {margin: 0},
  hide: {
    display: 'none',
  },
});

export interface PremiumItemProps {
  item: {
    id: number;
    title: string;
    subtitle?: string;
    price: number;
    type: string;
    status: string;
    thumbnailImage?: string;
    count?: number;
    link: {
      type: string;
      value: string;
    };
    promo?: {
      tag: string;
      salePrice: number;
    };
  };
  rank?: number;
  hideThumbnail?: boolean;
  hidePrice?: boolean;
}

export const PremiumListItem = React.forwardRef<HTMLDivElement, PremiumItemProps>(
  ({item, hideThumbnail, rank, hidePrice}, ref) => {
    const setLink = useSetRecoilState(PremiumBuyLinkState);
    const classes = useStyles();
    return (
      <Stack
        ref={ref}
        className={clsx(classes.root, {[classes.noThumb]: hideThumbnail})}
        alignItems="center"
        direction="row"
        onClick={() => setLink(item.link.value)}>
        <Box className={clsx(classes.thumbnail, {[classes.hide]: hideThumbnail})}>
          <img
            src={item?.thumbnailImage}
            alt={item.title}
            className={clsx({[classes.hide]: hideThumbnail})}></img>
        </Box>
        {rank && rank > 0 && <Typography className={classes.rank}>{rank}</Typography>}
        <Box>
          {item.subtitle && (
            <Typography className={clsx(classes.subTitle, {[classes.hide]: hideThumbnail})}>
              {item.subtitle}
            </Typography>
          )}
          <Typography className={classes.title} variant="h5">
            {item.title}
          </Typography>
          <Stack direction="row" alignItems="center">
            {!hidePrice && item?.price > 0 && (
              <Stack
                direction="row"
                alignItems="center"
                className={clsx(classes.priceTag, {[classes.textPrice]: hideThumbnail})}>
                <PriceTag type="price" amount={item.price} salePrice={item.promo?.salePrice} />
                <div className={classes.saleTag}>{item.promo?.tag}</div>
              </Stack>
            )}
            {hideThumbnail && item?.count && (
              <Stack
                direction="row"
                alignItems="center"
                className={clsx(classes.likeCount, {[classes.textCount]: hideThumbnail})}>
                <img
                  src="https://static.forceteller.com/www/images/ic_heart_on.png"
                  width="10px"
                  height="9px"
                  alt="count"
                />
                <Typography className={classes.count}>{toNumberWithComma(item.count)}</Typography>
              </Stack>
            )}
          </Stack>
        </Box>
      </Stack>
    );
  },
);
