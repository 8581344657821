import React from 'react';
import {useMatch} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import Subpage from 'components/Subpage';
import * as Themecast from 'containers/Themecast';
import {KeywordState} from 'hooks/states/keyword';

export default React.memo(() => {
  const viewable = useMatch('/');
  const keyword = useRecoilValue(KeywordState);

  return (
    <section hidden={!viewable} aria-hidden={!viewable}>
      <Subpage disableGutter>
        <Themecast.Newsfeed type="web" keyword={keyword} />
      </Subpage>
    </section>
  );
});
