import React from 'react';
import MaskedInput from 'react-text-mask';
import {useController, ControllerProps} from 'react-hook-form';

import InputBase, {InputBaseProps} from '@mui/material/InputBase';

export interface CouponCodeProps
  extends InputBaseProps,
    Omit<ControllerProps<any>, 'name' | 'render' | 'defaultValue'> {}

const COUPON_CODE_REGEX = /[a-zA-Z]/;
const COUPON_CODE_REGEX_MASK_ARRAY = [
  COUPON_CODE_REGEX,
  COUPON_CODE_REGEX,
  COUPON_CODE_REGEX,
  COUPON_CODE_REGEX,
  '-',
  COUPON_CODE_REGEX,
  COUPON_CODE_REGEX,
  COUPON_CODE_REGEX,
  COUPON_CODE_REGEX,
  '-',
  COUPON_CODE_REGEX,
  COUPON_CODE_REGEX,
  COUPON_CODE_REGEX,
  COUPON_CODE_REGEX,
  '-',
  COUPON_CODE_REGEX,
  COUPON_CODE_REGEX,
  COUPON_CODE_REGEX,
  COUPON_CODE_REGEX,
];

export default React.forwardRef<HTMLInputElement, CouponCodeProps>(
  ({name = 'coupon_code', control, rules, defaultValue, ...otherProps}, ref) => {
    const {formState, field} = useController({
      name,
      control,
      rules,
      defaultValue,
    });

    return (
      <MaskedInput
        guide={false}
        mask={COUPON_CODE_REGEX_MASK_ARRAY}
        render={(ref, props) => {
          const {onChange, ...fieldProps} = props;

          const passProps = {
            inputRef: ref,
            error: formState.submitCount > 0 && !!formState.errors[name],
            fullWidth: true,
            placeholder: '16자리',
            inputProps: {
              autoComplete: 'off',
              autoCapitalize: 'off',
            },
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.value = (e.target.value ?? '').toUpperCase();
              return onChange(e);
            },
            ...fieldProps,
            ...otherProps,
          };

          return <InputBase {...passProps} />;
        }}
        {...field}
      />
    );
  },
);
