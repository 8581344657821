import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import FontAwesomeIcon from '@un7qi3-ui/react/icons/FontAwesome/FontAwesome';
import clsx from 'clsx';
import {DialogStateProps} from 'hooks/states';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      minWidth: 300,
      borderRadius: '8px',
      textAlign: 'center',
    },
  },
  icon: {
    color: '#E85E5E',
    marginBottom: '15px',
    marginTop: '50px',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.25,
    marginBottom: '32px',
    whiteSpace: 'pre-line',
    letterSpacing: '-0.36px',
  },
  contentWrapper: {
    '& + div': {
      marginTop: '60px',
    },
  },
  content: {
    whiteSpace: 'pre-line',
    fontSize: 14,
    color: '#5e5e5e',
    letterSpacing: '-0.36px',
  },
  actions: {
    fontSize: 16,
    fontWeight: 'normal',
    padding: 0,
  },
  button: {
    height: '50px',
    fontSize: 16,
    flex: '1 1 auto',
    width: '50%',
    borderTop: '0.55px solid rgba(0, 0, 0, 0.2)',
    borderRight: '0.55px solid rgba(0, 0, 0, 0.2)',
    background: '#fff',
    borderRadius: 0,
    fontWeight: 700,
    color: '#000',
    '&:last-child': {
      marginLeft: 0,
      color: '#428BFF',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const WarningDialog: React.FC<DialogStateProps> = (dialogOption) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={() => dialogOption.onClose()}
      open={dialogOption.open}
      maxWidth="xs"
      className={classes.root}>
      <DialogContent className={classes.contentWrapper}>
        {dialogOption.icon && (
          <FontAwesomeIcon className={clsx(dialogOption.icon, classes.icon)} fontSize={30} />
        )}
        <Typography variant="h5" className={classes.title}>
          {dialogOption.title}
        </Typography>
        <Typography className={classes.content}>{dialogOption.content}</Typography>
      </DialogContent>
      {dialogOption.confirm && dialogOption.cancel && (
        <DialogActions className={classes.actions}>
          <Button onClick={() => dialogOption.onClose()} className={classes.button}>
            {dialogOption.cancel}
          </Button>
          <Button onClick={() => dialogOption.onConfirm()} autoFocus className={classes.button}>
            {dialogOption.confirm}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default WarningDialog;
