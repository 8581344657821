import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {DialogStateProps} from 'hooks/states';
import ButtonGroup from '@mui/material/ButtonGroup';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: '8px',
    },
  },
  title: {
    fontSize: 18,
    lineHeight: '23px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '50px 20px 38px',
    whiteSpace: 'pre-line',
    letterSpacing: '-0.36px',
  },
  contentWrapper: {padding: '16px 0 40px', minWidth: 287},
  img: {display: 'block', width: 95, margin: '0 auto'},
  content: {
    color: '#5E5E5E',
    padding: '20px',
    whiteSpace: 'pre-line',
    fontSize: '14px',
    letterSpacing: '-0.36px',
  },
  btns: {
    '& .MuiButtonGroup-grouped:hover': {
      borderColor: '#E3E3E5',
    },
  },
  cancel: {
    borderColor: '#E3E3E5',
    borderRadius: 0,
    lineHeight: 1,
    fontWeight: 700,
    color: '#3E3E40',
    fontSize: '16px',
    padding: '16px',
  },
  confirm: {
    borderColor: '#E3E3E5',
    borderRadius: 0,
    lineHeight: 1,
    fontWeight: 700,
    padding: '16px',
    fontSize: '16px',
    color: '#3E3E40',
    'button + &': {
      color: '#428BFF',
    },
  },
});

const FatebookDialog: React.FC<DialogStateProps> = (dialogOption) => {
  const classes = useStyles();
  return (
    <Dialog open={dialogOption.open} className={classes.root}>
      <DialogTitle className={classes.title}>{dialogOption.title}</DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        <img
          src="https://static.forceteller.com/images/fatebook/keep_icon.png"
          className={classes.img}
        />
        <Typography component="div" align="center" variant="body1" className={classes.content}>
          {dialogOption.content}
        </Typography>
      </DialogContent>
      <ButtonGroup variant="outlined" fullWidth className={classes.btns}>
        {dialogOption.cancel && (
          <Button variant="outlined" onClick={dialogOption.onClose} className={classes.cancel}>
            {dialogOption.cancel}
          </Button>
        )}
        <Button
          variant="outlined"
          color="secondary"
          onClick={dialogOption.onConfirm}
          className={classes.confirm}>
          {dialogOption.confirm}
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

export default FatebookDialog;
