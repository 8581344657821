import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Dialog, DialogContent, useMediaQuery} from '@mui/material';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles({
  root: {},
  closeButton: {
    position: 'absolute',
    top: 'calc(1rem + env(safe-area-inset-top))',
    right: '1rem',
    zIndex: 99,
    display: 'inline-block',
    width: '28px',
    height: '28px',
    background: 'rgba(0,0,0, 0.4)',
    borderRadius: '50%',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      height: '2px',
      width: '50%',
      top: '50%',
      left: '50%',
      marginTop: '-1px',
      background: '#fff',
    },
    '&:after': {transform: 'translateX(-50%) rotate(-45deg)'},
    '&:before': {transform: 'translateX(-50%) rotate(45deg)'},
  },
  preview: {
    width: '100%',
    '@media screen and (max-width: 479px)': {
      maxWidth: '100vw',
      maxHeight: '100vh',
    },
    '@media screen and (min-width: 480px)': {
      maxWidth: '480px',
      maxHeight: '960px',
    },
  },
});

export interface DialogProps {
  src?: string;
  open: boolean;
  onClose: () => void;
}

export const SimpleDialog = ({src, open, onClose}: DialogProps) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery('(max-width: 479px)');

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullScreen={fullScreen}>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
        size="large"
      />
      <DialogContent sx={{padding: '0 !important'}}>
        <img className={classes.preview} src={src} alt={src} />
      </DialogContent>
    </Dialog>
  );
};
