import {SideMenuBox, Icon} from 'components/Test/styles/SideMenyStyles';
import Typography from '@mui/material/Typography';
import {APP_LINK, appViewAction, category, sideMenuLabel} from 'components/Test/constants';
import React, {useState} from 'react';
import {DesktopQrModal} from 'components/Test/QR/desktopQrModal';
import {ModalOpenal} from 'components/Test/modal/CenterModal';
import {BrowserView, MobileView} from 'react-device-detect';

export const SideMenu1: React.FC = () => {
  const [openQrModal, setOpenQrModal] = useState(false);
  return (
    <SideMenuBox
      data-event-action={appViewAction}
      data-event-category={category}
      data-event-label={sideMenuLabel}>
      <Icon src={'https://static-en.forceteller.com/www/images/ico_app_launcher_squricle.png'} />
      <MobileView>
        <a href={APP_LINK}>
          <Typography variant={'h5'}>앱으로 보기</Typography>
        </a>
      </MobileView>
      <BrowserView>
        <Typography onClick={() => setOpenQrModal((prev) => !prev)} variant={'h5'}>
          앱으로 보기
        </Typography>
      </BrowserView>
      <ModalOpenal.Provider value={[openQrModal, setOpenQrModal]}>
        {openQrModal ? <DesktopQrModal /> : null}
      </ModalOpenal.Provider>
    </SideMenuBox>
  );
};
