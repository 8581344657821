import {Theme, Components} from '@mui/material/styles';

export default function override(theme: Theme): Components {
  return {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'p',
          subtitle2: 'p',
          body: 'div',
          body1: 'p',
          body2: 'p',
          inherit: 'p',
          announce: 'div',
        },
      },
      styleOverrides: {
        gutterBottom: {
          marginBottom: '0.28em', // 15 / 4
        },
      },
    },
  };
}
