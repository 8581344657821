import axios from 'axios';

// dayjs
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// firebase
import {initializeApp} from '@firebase/app';
import firebaseConfig from 'assets/firebase.config';
import {initializeRemoteConfig} from '~/plugins/remote-config/initializeRemoteConfig';

dayjs.extend(customParseFormat);
const app = initializeApp(firebaseConfig);
export const remoteConfig = initializeRemoteConfig(app);

axios.interceptors.response.use(
  function (response) {
    // if (response.data) {
    //   const status = response.data.status;
    //
    //   if (Number.isInteger(status) && status !== 200) {
    //     throw response.data;
    //   }
    // }
    // Do something before request is sent
    return response;
  },
  function (error) {
    if (error.response) {
      return Promise.reject(error.response);
    }
    // Do something with request error
    return Promise.reject(error);
  },
);

export default function initializeSystem() {
  if (process.env.REACT_APP_SERVICE_API_URL) {
    axios.defaults.baseURL = process.env.REACT_APP_SERVICE_API_URL;
  }

  return Promise.all([import(`dayjs/locale/${process.env.REACT_APP_LANG}`)]).then(() => {
    //
    dayjs.locale(process.env.REACT_APP_LANG);
  });
}
