import {inputBaseClasses} from '@mui/material/InputBase';
import {Theme, Components} from '@mui/material/styles';

export default function override(theme: Theme): Components {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...theme.typography.body2,
          borderRadius: theme.shape.borderRadius,
          color: theme.palette.grey[900],
          border: `solid 1px transparent`,
          borderColor: theme.palette.grey[200],

          '& input': {
            padding: '10.5px 14px',
          },

          '& select': {
            height: 44,
            padding: '0 0 0 14px',
          },

          '& input::placeholder': {
            opacity: 1,
            color: theme.palette.grey[400],
          },

          [`&.${inputBaseClasses.disabled}`]: {
            backgroundColor: theme.palette.grey[50],
            // color: theme.palette.text.disabled,
            // cursor: 'default',
          },

          [`&.${inputBaseClasses.error}`]: {
            borderColor: theme.palette.error.main,
          },
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiInputLabel: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          ...theme.typography.h6,
        },
      },
    },
  };
}
