import React from 'react';

import Skeleton from '@mui/material/Skeleton';
import {styled} from '@mui/material/styles';

import useAuthenticated from 'features/auth/hooks/useAuthenticated';
import useWaitForAuthentication from 'features/auth/hooks/useWaitForAuthentication';

import LinkArea, {DrawerMenuLinkAreaProps} from './LinkArea';

interface DrawerMenuItemProps extends DrawerMenuLinkAreaProps {
  requireAuth?: boolean;
}

// @ts-ignore
const DrawerMenuItemRoot = styled(LinkArea)(({theme}) => ({
  ...(theme.typography.h5 as React.CSSProperties),
  fontWeight: theme.typography.fontWeightRegular,
  letterSpacing: -0.32,
  lineHeight: 1,
  padding: '12px 20px',
}));

const DrawerMenuItem = React.memo<DrawerMenuItemProps>(
  ({requireAuth, children: childrenProp, ...otherProps}) => {
    const authenticated = useAuthenticated();
    const waitForAuthentication = useWaitForAuthentication();

    const hidden = requireAuth && !authenticated;
    const passProps = {
      ...(hidden && {
        hidden,
        'aria-hidden': hidden,
      }),
      ...otherProps,
    };

    const children = waitForAuthentication ? <Skeleton variant="text" width="70%" /> : childrenProp;

    return (
      <DrawerMenuItemRoot
        data-event-action="사이드 네비게이션 메뉴 터치"
        data-event-category="사이드 네비게이션"
        data-event-label={typeof childrenProp === 'string' ? childrenProp : ''}
        {...passProps}>
        {children}
      </DrawerMenuItemRoot>
    );
  },
);

export default DrawerMenuItem;
