import React from 'react';
import {useQuery} from 'react-query';
import Skinnable from './skins/Skinnable';

import type * as Apis from '@un7qi3/types/apis';

interface ThemecastPersonalProps {
  suspense?: boolean;
}

const ThemecastPersonal: React.FC<ThemecastPersonalProps> = ({suspense = false}) => {
  const query = useQuery<Apis.Response<Apis.ThemeProps[]>>('/api/theme/personal', {suspense});

  if (query.status === 'success') {
    return (
      <React.Fragment>
        {query.data.data.map((item) => (
          <Skinnable key={item.uuid} skin={item.code} passProps={item} />
        ))}
      </React.Fragment>
    );
  }

  return null;
};

export default ThemecastPersonal;
