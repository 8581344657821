import React from 'react';
import Swiper, {ReactIdSwiperProps} from 'react-id-swiper';
import makeStyles from '@mui/styles/makeStyles';
import Paper, {PaperProps} from '@mui/material/Paper';
import Box from '@mui/material/Box';
import * as Apis from '@un7qi3/types/apis';
import {useQuery} from 'react-query';
import {KeywordProps} from '@un7qi3/types/apis';

import {TagChips} from './TagChips';

import FontAwesomeIcon from '@un7qi3-ui/react/icons/FontAwesome';

const useStyles = makeStyles({
  root: {},
  wrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: 'flex',
    fontFamily: 'Noto Sans KR',
  },
  swiperBox: (spread) => ({
    position: 'relative',
    overflow: 'hidden',
    padding: '0 20px',
    zIndex: 3,
    visibility: spread ? 'hidden' : 'visible',
  }),
  spreadBox: (spread) => ({
    maxHeight: spread ? '100vh' : 0,
    visibility: spread ? 'visible' : 'hidden',
    zIndex: 3,
    overflow: 'hidden',
    position: 'absolute',
    transition: 'max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderBottomLeftRadius: '24px',
    borderBottomRightRadius: '24px',
    padding: '0 20px 20px',
    boxShadow: '0 8px 20px -8px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    top: 0,
  }),
  chip: {
    display: 'inline-block',
    margin: '4px 0',
    '&:last-child': {
      marginRight: 24,
    },
  },
  tagMore: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 2,
    width: 54,
    height: 42,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage:
      'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 32%, #ffffff 100%)',
  },
  tagChip: {
    height: '32px',
    marginRight: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    borderRadius: '17.5px',
    border: 'solid 1px #ededed',
    background: '#fff',
    color: '#a3a3a3',
    fontSize: '14px',
    lineHeight: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 14px 1px',
    cursor: 'pointer',

    '&.active': {
      border: 'solid 1px #5e5e5e',
      color: '#191919',
      fontWeight: 'bold',
      background: '#fff',
    },
  },
  icon: {
    fontSize: '1.5rem',
  },
});
export interface MainTagProps extends PaperProps {
  tagSelected: (value?: KeywordProps) => void;
  suspense?: boolean;
}

export const MainTags = React.memo<MainTagProps>(
  ({suspense = false, tagSelected, ...otherProps}) => {
    const {data: tags} = useQuery<Apis.Response<Apis.ThemeProps[]>>('/api/theme/list/web_top', {
      suspense,
    });
    const swiperRef = React.useRef<any>(null);
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
    const [spread, setSpread] = React.useState<boolean>(false);
    const classes = useStyles(spread);
    const params: ReactIdSwiperProps = {
      slidesPerView: 'auto',
      freeMode: true,
      freeModeMomentumBounce: false,
      resistanceRatio: 0,
      wrapperClass: classes.wrapper,
      slideClass: classes.chip,
    };

    React.useEffect(() => {
      tagSelected(tags?.data[0]?.themeViews[0].keywords[selectedIndex]);
      if (spread && swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.slideTo(selectedIndex, 0);
      }
      setSpread(false);
    }, [selectedIndex]);

    if (tags && tags.data[0]) {
      return (
        <Paper sx={{position: 'relative', mb: '16px'}} elevation={0} {...otherProps}>
          <Box className={classes.swiperBox}>
            <Swiper {...params} ref={swiperRef}>
              <TagChips
                keywords={tags.data[0]?.themeViews[0].keywords}
                classes={classes}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
              />
            </Swiper>
            <div
              className={classes.tagMore}
              data-event-action="태그 필터 확장/축소"
              onClick={() => setSpread(!spread)}>
              <FontAwesomeIcon className="fal fa-angle-down" fontSize={24} />
            </div>
          </Box>
          <Box className={classes.spreadBox}>
            <TagChips
              keywords={tags.data[0]?.themeViews[0].keywords}
              classes={classes}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
            />
            <div
              className={classes.tagMore}
              data-event-action="태그 필터 확장/축소"
              onClick={() => setSpread(!spread)}>
              <FontAwesomeIcon className="fal fa-angle-up" fontSize={24} />
            </div>
          </Box>
        </Paper>
      );
    }

    return null;
  },
);
