import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import RadioGroupBase, {RadioGroupProps as RadioGroupBaseProps} from '@mui/material/RadioGroup';
import Radio from './Radio';

export interface RadioGroupProps extends Omit<RadioGroupBaseProps, 'name' | 'onChange' | 'onBlur'> {
  name: string;
  disabled?: boolean;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  disabled,
  children: childrenProp,
  ...otherProps
}) => {
  const formContext = useFormContext();

  React.useEffect(() => {
    return () => formContext?.unregister(name);
  }, [name]);

  return (
    <Controller
      name={name}
      control={formContext?.control}
      render={({field, formState}) => {
        const children = React.Children.map(childrenProp, (child) => {
          if (React.isValidElement(child) && child.type === Radio) {
            return React.cloneElement(child, {
              error: !!formState.errors[name],
              disabled: disabled || formState.isSubmitting,
            });
          }

          return child;
        });

        const passProps = {
          children,
          ...field,
          ...otherProps,
        };

        return <RadioGroupBase {...passProps} />;
      }}
    />
  );
};

export default RadioGroup;
