import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

export default React.memo(() => {
  return (
    <Stack direction="row" alignItems="center" sx={{padding: '24px 20px 12px'}}>
      <Skeleton variant="circular" width={44} height={44} />
      <Box sx={{flex: 1, pl: '14px'}}>
        <Skeleton variant="text" width="30%" height={12} />
        <Skeleton variant="text" width="70%" height={16} />
      </Box>
    </Stack>
  );
});
