import {useRecoilCallback} from 'recoil';

import {
  QueryKeyState,
  QueryVariableState,
  CursorState,
  TypeQueryKeyState,
  TypeQueryVariableState,
} from './states';

import type * as Apis from '@un7qi3/types/apis';

export interface NewsfeedConfigProps {
  type: 'web' | 'item' | 'story';
  per_page?: number;
}

function queryKeySerializer(
  keyword: Apis.KeywordProps | null,
  config: NewsfeedConfigProps,
): [TypeQueryKeyState, TypeQueryVariableState] {
  let queryKey = `/api/theme/items/${config.type}/fetch`,
    queryVariables: TypeQueryVariableState = {
      tag: 'all',
      l: config.per_page ?? 10,
    };

  if (keyword && keyword.link) {
    const {type, value, params} = keyword.link;

    if (type === 'api') {
      queryKey = value;
      queryVariables = {
        ...queryVariables,
        ...params?.queryParams,
      };
    } else if (type === 'tag_filter') {
      queryVariables.tag = value;
    }
  }

  return [queryKey, queryVariables];
}

export function useDispatcher(config: NewsfeedConfigProps) {
  /**
   *
   */
  const setKeyword = useRecoilCallback<ReadonlyArray<Apis.KeywordProps | null>, void>(
    ({set}) =>
      (keyword) => {
        const [queryKey, queryVariable] = queryKeySerializer(keyword, config);

        set(QueryKeyState, queryKey);
        set(QueryVariableState, queryVariable);
        set(CursorState, 1);
      },
    [],
  );

  /**
   *
   */
  const fetchNextPage = useRecoilCallback(
    ({set}) =>
      () => {
        set(CursorState, (cursor) => cursor + 1);
      },
    [],
  );

  return {
    setKeyword,
    fetchNextPage,
  };
}
