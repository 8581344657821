import React from 'react';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Stack, {StackProps} from '@mui/material/Stack';

import FiveElementText from '@un7qi3-ui/react/FiveElementText';

import useCurrentUser from 'features/auth/hooks/useCurrentUser';
import useWaitForAuthentication from 'features/auth/hooks/useWaitForAuthentication';

import ProfileCoverSkeleton from './ProfileCoverSkeleton';
import ProfileCoverUnauthorized from './ProfileCoverUnauthorized';

export interface DrawerMenuProfileCoverProps extends StackProps {}

// profile.result.i -> 'https://static.forceteller.com/images/animal/v4/circle_colored_' + profile.result.i + '.png'

const DrawerMenuProfileCover = React.memo<DrawerMenuProfileCoverProps>(() => {
  const currentUser = useCurrentUser();
  const waitForAuthentication = useWaitForAuthentication();

  if (waitForAuthentication) {
    return <ProfileCoverSkeleton />;
  } else if (currentUser && currentUser.profile) {
    const profile = currentUser.profile;

    const imgSrc =
      profile.avatarURL ?? 'https://static.forceteller.com/www/images/ic_portrait_empty.svg';

    return (
      <Stack direction="row" alignItems="center" sx={{padding: '24px 20px 12px'}}>
        <Avatar src={imgSrc} sx={{width: 44, height: 44}} />
        <Box sx={{pl: '14px'}}>
          <FiveElementText color={profile.e} label={profile.a} sx={{mb: '4px'}} />
          <Typography variant="h5" sx={{fontWeight: 'bold', lineHeight: 1}}>
            {profile.name}
          </Typography>
        </Box>
      </Stack>
    );
  } else {
    return <ProfileCoverUnauthorized />;
  }
});

export default DrawerMenuProfileCover;
