import axios, {AxiosResponse} from 'axios';
import {QueryClient, QueryFunctionContext} from 'react-query';

import queryKeySerializerFn from './queryKeySerializerFn';

function defaultQueryFn({queryKey, pageParam}: QueryFunctionContext) {
  const [pathname, params] = queryKeySerializerFn(queryKey);

  if (!pathname) {
    return Promise.resolve({
      status: 204,
      data: {},
    });
  }

  return new Promise((resolve, reject) => {
    let promise: Promise<AxiosResponse> = params.axios
      ? axios(pathname, params.axios)
      : axios.get(pathname, {
          params: {
            ...params,
            ...pageParam,
          },
        });

    setTimeout(() => {
      return promise.then((response) => resolve(response.data)).catch(reject);
    }, 1);
  });
}

//
function defaultMutationFn(variables: any) {
  const {url, body, opts} = variables;
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      return axios
        .post(url, body, opts)
        .then((response) => resolve(response.data))
        .catch(reject);
    }, 1);
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      cacheTime: 1, // 캐시를 하지 않음
      // staleTime: 1, // 캐시를 하지 않음
      suspense: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      queryFn: defaultQueryFn,
      notifyOnChangeProps: ['status', 'data'],
      //
      useErrorBoundary: true,
    },
    mutations: {
      mutationFn: defaultMutationFn,
    },
  },
});

export default queryClient;
