import React, {useState} from 'react';

import {AppBar as MuiAppBar, Button, Toolbar, Link} from '@mui/material';
import styled from '@mui/styles/styled';
import {ModalIcon} from 'components/Test/styles/ModalStyles';
import AppBar from 'containers/AppBar';
import {
  APP_LINK,
  appTouchAction,
  category,
  fixBarAction,
  fixBarPremiumLoginLabel,
  fixBarPremiumLogoutLabel,
} from '../../constants';
import Analytics from '~/plugins/tracker/analytics';
import useCurrentUser from '../../../../features/auth/hooks/useCurrentUser';
import useWaitForAuthentication from '../../../../features/auth/hooks/useWaitForAuthentication';
import {DesktopQrModal} from '../../QR/desktopQrModal';
import {ModalOpenal} from '../../modal/CenterModal';
import {BrowserView, MobileView} from 'react-device-detect';

type Props = {
  className?: string;
};

const FixBar = styled(MuiAppBar)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  boxShadow: 'none',
  boxSizing: 'border-box',
  height: 56,
  alignItems: 'center',
});

const SpacingBar = styled(Toolbar)({
  height: 56,
  marginBottom: '2em',
});
const StyledToolBar = styled(Toolbar)({
  padding: '10px 16px',
  width: 600,
  display: 'flex',
  justifyContent: 'space-between',
  '&>div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&>img': {
      marginBottom: 0,
    },
    '&>span': {
      display: 'block',
      fontWeight: 600,
      lineHeight: '1.3em',
      letterSpacing: '-0.02em',
      marginLeft: '12px',
      whiteSpace: 'pre-wrap',
      fontSize: 12,
    },
  },
});

const AppButton = styled(Button)({
  background: '#fff',
  color: '#333',
  fontSize: 14,
  padding: '8px 20px',
  borderRadius: 4,
  translate: '0.5s',
  '&:hover': {
    background: '#fff',
    opacity: 0.7,
  },
});

const AppBarPremium = React.forwardRef<HTMLDivElement, Props>(({className}, ref) => {
  const currentUser = useCurrentUser();
  const waitForAuthentication = useWaitForAuthentication();
  const [qrOpenModal, setQrOpenModal] = useState(false);
  let PremiumLabel = fixBarPremiumLogoutLabel;
  if (currentUser) {
    PremiumLabel = fixBarPremiumLoginLabel;
  }
  React.useEffect(() => {
    Analytics.track(fixBarAction, {category: category, label: PremiumLabel}).then(() => {
      console.log(`${PremiumLabel} ${fixBarAction}`);
    });
  }, []);
  return (
    <>
      <React.Fragment>
        <FixBar>
          <StyledToolBar>
            <div>
              <ModalIcon
                src={'https://static-en.forceteller.com/www/images/ico_app_launcher_squricle.png'}
                style={{width: 36, height: 36}}
              />
              <span>
                {waitForAuthentication
                  ? ''
                  : currentUser
                  ? '모바일 앱에서\n할인 혜택 제공'
                  : `모바일 앱에서\n더욱 편리하게!`}
              </span>
            </div>
            <MobileView>
              <Link style={{textDecoration: 'none'}} href={APP_LINK}>
                <AppButton
                  variant="contained"
                  data-event-action={appTouchAction}
                  data-event-category={category}
                  data-event-label={PremiumLabel}>
                  앱으로 보기
                </AppButton>
              </Link>
            </MobileView>
            <BrowserView>
              <AppButton
                onClick={() => setQrOpenModal((prev) => !prev)}
                variant="contained"
                data-event-action={appTouchAction}
                data-event-category={category}
                data-event-label={PremiumLabel}>
                앱으로 보기
              </AppButton>
            </BrowserView>
          </StyledToolBar>
        </FixBar>
        <SpacingBar />
      </React.Fragment>
      <ModalOpenal.Provider value={[qrOpenModal, setQrOpenModal]}>
        {qrOpenModal ? <DesktopQrModal /> : null}
      </ModalOpenal.Provider>
      <AppBar position="fixed" style={{top: 56, zIndex: 1000}} />
    </>
  );
});
export default AppBarPremium;
