import * as React from 'react';

export interface InfiniteScrollProps {
  onIntersect: (options?: any) => Promise<any>;
  enabled?: boolean;
  root?: any;
  threshold?: number;
  rootMargin?: string;
}

export const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  onIntersect,
  enabled,
  rootMargin,
  threshold = 0,
  root = '0px',
}) => {
  const targetRef = React.useRef<any>();
  React.useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      {
        root: root && root.current,
        rootMargin,
        threshold,
      },
    );

    const el = targetRef && targetRef.current;

    if (!targetRef) {
      return;
    }
    observer.observe(el);

    return () => observer && observer.unobserve(el);
  }, [enabled]);
  return <div ref={targetRef}></div>;
};
