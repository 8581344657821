import {Theme, Components} from '@mui/material/styles';

export default function override(theme: Theme): Components {
  return {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          minWidth: 220,
        },
      },
    },
  };
}
