import React from 'react';
import Swiper, {ReactIdSwiperProps} from 'react-id-swiper';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import clsx from 'clsx';
import {SimpleDialog} from './SimpleDialog';

const useStyles = makeStyles({
  root: {
    marginBottom: 20,
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
  },
});

export interface PremiumSampleListProps {
  samples: string[];
  className?: string;
  initialSlide?: number;
  width?: number;
}

export const PremiumSampleList = React.memo<PremiumSampleListProps>(
  ({samples, className, width, initialSlide = 0}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<string>('');
    const params: ReactIdSwiperProps = {
      slidesPerView: 'auto',
      spaceBetween: 12,
      initialSlide: initialSlide,
      wrapperClass: classes.wrapper,
    };

    const zoomIn = (img: string) => () => {
      setSelected(img);
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <Paper className={clsx(classes.root, className)} elevation={0}>
        {samples.length > 0 && (
          <Swiper {...params}>
            {samples.map((sample, index) => (
              <img
                style={{width: width, height: '100%', borderRadius: 8}}
                src={sample}
                key={sample}
                alt={`sample${index}`}
                onClick={zoomIn(sample)}
              />
            ))}
          </Swiper>
        )}
        <SimpleDialog open={open} onClose={handleClose} src={selected} />
      </Paper>
    );
  },
);
