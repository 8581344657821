import React from 'react';
import {useQuery, QueryStatus} from 'react-query';

import {IfMatch, IfMatchCase, IfMatchElse} from '@un7qi3-ui/react/IfMatch';

import BannerBase from './BannerBase';
import HomeBanner from './HomeBanner';

import type {SxProps} from '@mui/system';
import type * as Apis from '@un7qi3/types/apis';

export interface BannerProps {
  areaKey?: string;
  suspense?: boolean;
  sx?: SxProps;
}

export interface BannerPassProps {
  status: QueryStatus;
  data?: Apis.BannerItemProps;
}

const Banner: React.FC<BannerProps> = ({areaKey, suspense, ...otherProps}) => {
  const query = useQuery<Apis.Response<Apis.BannerItemProps>>(['/api/ba/k/:areaKey', areaKey], {
    suspense,
  });

  const passProps = {
    status: query.status,
    data: query.status === 'success' ? query.data.data : undefined,
    ...otherProps,
  };

  return (
    <IfMatch condition={areaKey} passProps={passProps} deps={[query.status]}>
      <IfMatchCase then="web_home_custom" element={<HomeBanner />} />
      <IfMatchElse element={<BannerBase />} />
    </IfMatch>
  );
};

export default Banner;
