import {Theme} from '@mui/material/styles';

import Paper from './Paper';
import Drawer from './Drawer';
import Container from './Container';
import Button from './Button';
import Typography from './Typography';
import Input from './Input';
import ToggleButton from './ToggleButton';
import List from './List';

import Dialog from './Dialog';
import Accordion from './Accordion';

function overrides(theme: Theme) {
  return {
    ...Paper(theme),
    ...Drawer(theme),
    ...Container(theme),
    ...Button(theme),
    ...Typography(theme),
    ...Input(theme),
    ...ToggleButton(theme),
    ...List(theme),
    ...Dialog(theme),
    ...Accordion(theme),
  };
}

export default overrides;
