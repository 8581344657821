import {atom} from 'recoil';

export interface DialogStateProps {
  title?: string;
  icon?: string;
  content?: string;
  open: boolean;
  confirm?: string;
  cancel?: string;
  onClose?: any;
  onConfirm: any;
  type?: string;
}
export const DialogState = atom<DialogStateProps>({
  key: 'DialogState',
  default: {open: false, type: 'default', onClose: () => {}, onConfirm: () => {}},
});
