import React from 'react';

import {getAuth, getRedirectResult, onAuthStateChanged, Unsubscribe, User} from '@firebase/auth';
import {isIab} from 'features/core/iframe';

type Status = 'NO_STATUS' | 'NEED_INITIALIZE' | 'NEED_ISSUE_TOKEN';
type Params = {
  status: Status;
  user: User | null;
  provider?: string;
};

type AuthStateChangeEffect = (params: Params) => void;

/**
 *
 * @param user
 * @protected
 */
function getAuthProvider(user: User) {
  if (user.providerId === 'firebase') {
    switch (user.providerData[0]?.providerId) {
      case 'facebook.com':
        return 'facebook';
      case 'google.com':
        return 'google';
      case 'apple.com':
        return 'apple';
      default:
        if (user.uid.lastIndexOf('kakao')) {
          return 'kakao';
        } else if (user.uid.lastIndexOf('naver')) {
          return 'naver';
        }
        break;
    }
  }

  return undefined;
}

export default function useAuthStateChangedListener(effect: AuthStateChangeEffect): void {
  const firstTimeRef = React.useRef<boolean>(true);
  const unsubscribeRef = React.useRef<Unsubscribe | null>(null);

  React.useEffect(() => {
    if (isIab()) {
      effect({
        status: 'NEED_INITIALIZE',
        user: null,
        provider: undefined,
      });
    } else {
      (async () => {
        const auth = getAuth();
        const redirectResult = await getRedirectResult(auth);
        const hasRedirectResult =
          redirectResult && redirectResult.user && redirectResult.operationType === 'signIn';

        unsubscribeRef.current = onAuthStateChanged(auth, (user) => {
          let status: Status = 'NO_STATUS';

          // 처음 로딩 시 사용자 정보가 있을 경우
          if (firstTimeRef.current) {
            firstTimeRef.current = false;

            // 사용자가 있을 경우
            if (user) {
              status = hasRedirectResult ? 'NEED_ISSUE_TOKEN' : 'NEED_INITIALIZE';
            }
          } else if (user) {
            status = 'NEED_ISSUE_TOKEN';
          }

          effect({
            status,
            user,
            provider: user ? getAuthProvider(user) : undefined,
          });
        });
      })();
    }

    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
