import EventEmitter from 'events';

type EventName = 'auth/change' | 'auth/success' | 'auth/error';

type Listener = (...args: any[]) => void;
type Unsubscribe = () => void;

const EE = new EventEmitter();

export class EventHubClass {
  public emit(event: EventName, ...args: any[]): boolean {
    return EE.emit(event, ...args);
  }

  public on(event: EventName, listener: Listener): Unsubscribe {
    EE.addListener(event, listener);
    return () => EE.removeListener(event, listener);
  }

  //
  public onAuthSuccess(listener: Listener): Unsubscribe {
    return this.on('auth/success', listener);
  }

  //
  public onAuthError(listener: Listener): Unsubscribe {
    return this.on('auth/error', listener);
  }
}

export default new EventHubClass();
